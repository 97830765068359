import React, { useState } from 'react';
import { Button, Container, Center } from "@chakra-ui/react";

import { MdAdd, MdRemove } from 'react-icons/md';
import { CreateCity } from './components/CreateCity';
import { CityList } from './components/ListCity';

const City = () => {
const [showDefaultForm, setShowDefaultForm] = useState(false);

  const toggleDefaultsForm = () => {
    setShowDefaultForm(!showDefaultForm);
  };

  return (
    <Container maxW="xxl" mt={100}>
      <Center>
        <Button 
          colorScheme={showDefaultForm ? "red" : "blue"} 
          leftIcon={showDefaultForm ? <MdRemove/> : <MdAdd />}
          onClick={toggleDefaultsForm}
          marginBottom="20px"
        >
          {showDefaultForm ? "Hide City Form" : "Add  City"}
        </Button>
      </Center>

      {showDefaultForm && (
        <div style={{ marginBottom: '20px' }}>
          <CreateCity /> 
        </div>
      )}

      <CityList/>
    </Container>
  );
};

export default City;
