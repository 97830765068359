// import React, { useState, useEffect } from "react";
// import {
//   Flex,
//   Text,
//   Table,
//   Thead,
//   Tbody,
//   Tr,
//   Th,
//   Td,
//   Button,
//   Box,
//   Input,
//   Select,
// } from "@chakra-ui/react";
// import { MdDelete, MdEdit, MdDone, MdCancel } from "react-icons/md";
// import Card from "components/card/Card";
// import fetchWithToken from "views/auth/signIn/axiosInstance"; // Adjust the path
// const ListProviderWorkingHours = () => {
//   const [workingHours, setWorkingHours] = useState([]);
//   const [editableHour, setEditableHour] = useState(null);
//   const [error, setError] = useState("");
//   const [providers, setProviders] = useState([]);
//   const [UpdateUser, setUpdateUser] = useState("");

//   useEffect(() => {
//     const accessToken = localStorage.getItem("accessToken");
//     if (accessToken) {
//       // Split the token into header, payload, and signature
//       // eslint-disable-next-line no-unused-vars
//       const [header, payload, signature] = accessToken.split(".");
//       // Decode the payload (Base64 decoded)
//       const decodedPayload = JSON.parse(atob(payload));
//       // Extract the username
//       const entryUser = decodedPayload.clientId;
//       // Set the entryUser in the state
//       setUpdateUser(entryUser);
//     } else {
//       console.error("Access token not found.");
//     }
//   }, []);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const [hoursResponse, providersResponse] = await Promise.all([
//           fetchWithToken(
//             `${process.env.REACT_APP_API_URL}/api/providerWorkingHr`
//           ),
//           fetchWithToken(`${process.env.REACT_APP_API_URL}/api/provider`),
//         ]);
//         // if (!hoursResponse.ok || !providersResponse.ok) {
//         //   throw new Error("Failed to fetch data");
//         // }
//         // const [hoursData, providersData] = await Promise.all([
//         //   hoursResponse.json(),
//         //   providersResponse.json(),
//         // ]);
//         setWorkingHours(hoursResponse);
//         setProviders(providersResponse);
//       } catch (error) {
//         setError(error.message);
//       }
//     };

//     fetchData();
//   }, []);

//   const handleEditClick = (hourId) => {
//     const hourToEdit = workingHours.find((hour) => hour.PWID === hourId);
//     setEditableHour({ ...hourToEdit });
//   };

//   const handleCancel = () => {
//     setEditableHour(null);
//   };

//   const handleSave = async () => {
//     // Ensure editableHour is not null and has the required properties
//     if (!editableHour || !editableHour.PWID) {
//       console.error("Editable hour or PWID is missing");
//       return;
//     }

//     try {
//       // Construct the PUT request body
//       const updatedParams = {
//         PWID: editableHour.PWID,
//         provID: editableHour.provID,
//         start: editableHour.start,
//         end: editableHour.end,
//         UpdateUser: UpdateUser, // You might want to set this dynamically
//         Day: editableHour.Day,
//       };

//       // Send the PUT request
//       const response = await fetchWithToken(
//         `${process.env.REACT_APP_API_URL}/api/providerWorkingHr`,

//         "PUT",
//         updatedParams
//         // headers: {
//         //   "Content-Type": "application/json",
//         // },
//         // body: JSON.stringify(updatedParams),
//       );

//       // if (!response) {
//       //   const errorData = await response.json();
//       //   throw new Error(
//       //     `Failed to update provider working hour: ${JSON.stringify(errorData)}`
//       //   );
//       // }

//       // Assuming update was successful, refresh or update your local data
//       const updatedHour = response; //await response.json();
//       setWorkingHours(
//         workingHours.map((hour) =>
//           hour.PWID === editableHour.PWID ? { ...hour, ...updatedHour } : hour
//         )
//       );
//       setEditableHour(null); // Exit edit mode
//       window.location.reload();
//     } catch (error) {
//       console.error("Error updating provider working hour:", error);
//       setError(error.message);
//     }
//   };

//   const handleChange = (e, field) => {
//     setEditableHour({ ...editableHour, [field]: e.target.value });
//   };

//   const handleDelete = async (PWID) => {
//     if (!window.confirm("Are you sure you want to delete this working hour?")) {
//       return;
//     }

//     try {
//       const response = await fetchWithToken(
//         `${process.env.REACT_APP_API_URL}/api/providerWorkingHr`,
//         "DELETE",
//         { PWID }
//         // headers: {
//         //   "Content-Type": "application/json",
//         // },
//         //body: JSON.stringify({ PWID }),
//       );

//       // if (!response) {
//       //   throw new Error("Failed to delete working hour");
//       // }

//       // If the deletion was successful, remove the hour from the state
//       setWorkingHours(workingHours.filter((hour) => hour.PWID !== PWID));
//       // Also update filteredWorkingHours if you're using it to display the list
//       // setFilteredWorkingHours(filteredWorkingHours.filter(hour => hour.PWID !== PWID));

//       console.log("Working hour deleted successfully");
//     } catch (error) {
//       console.error("Error deleting working hour:", error);
//       setError("Failed to delete working hour");
//     }
//   };

//   return (
//     <Card
//       direction="column"
//       w="100%"
//       px="0px"
//       overflowX={{ sm: "scroll", lg: "hidden" }}
//       boxShadow="lg"
//     >
//       <Flex px="25px" justify="space-between" mb="20px" align="center">
//         <Text color="black" fontSize="22px" fontWeight="700" lineHeight="100%">
//           Provider Working Hours List
//         </Text>
//         {error && <Text color="red.500">{error}</Text>}
//       </Flex>
//       <Box overflowY="auto" maxHeight="400px">
//         <Table variant="simple" color="gray.500">
//           <Thead>
//             <Tr bg="blue.500" textColor="white">
//               <Th textColor={"white"}>Index</Th>
//               <Th textColor="white">Provider</Th>
//               <Th textColor="white">Day</Th>
//               <Th textColor="white">Start Time</Th>
//               <Th textColor="white">End Time</Th>
//               <Th textColor="white">Actions</Th>
//             </Tr>
//           </Thead>
//           <Tbody>
//             {workingHours.map((hour, index) => (
//               <Tr key={hour.PWID}>
//                 <Td>{index + 1}</Td>
//                 <Td>
//                   {providers.find((p) => p.provID === hour.provID)
//                     ?.providerUsername || "Unknown"}
//                 </Td>
//                 <Td>
//                   {editableHour && editableHour.PWID === hour.PWID ? (
//                     <Select
//                       value={editableHour.Day || ""}
//                       onChange={(e) => handleChange(e, "Day")}
//                     >
//                       <option value="Sunday">Sunday</option>
//                       <option value="Monday">Monday</option>
//                       <option value="Tuesday">Tuesday</option>
//                       <option value="Wednesday">Wednesday</option>
//                       <option value="Thursday">Thursday</option>
//                       <option value="Friday">Friday</option>
//                       <option value="Saturday">Saturday</option>
//                     </Select>
//                   ) : (
//                     hour.Day
//                   )}
//                 </Td>

//                 <Td>
//                   {editableHour && editableHour.PWID === hour.PWID ? (
//                     <Input
//                       type="time"
//                       value={editableHour.start || ""}
//                       onChange={(e) => handleChange(e, "start")}
//                     />
//                   ) : (
//                     hour.start
//                   )}
//                 </Td>
//                 <Td>
//                   {editableHour && editableHour.PWID === hour.PWID ? (
//                     <Input
//                       type="time"
//                       value={editableHour.end || ""}
//                       onChange={(e) => handleChange(e, "end")}
//                     />
//                   ) : (
//                     hour.end
//                   )}
//                 </Td>
//                 <Td>
//                   {editableHour && editableHour.PWID === hour.PWID ? (
//                     <Flex>
//                       <Button
//                         leftIcon={<MdDone />}
//                         colorScheme="green"
//                         size="sm"
//                         onClick={handleSave}
//                       >
//                         Save
//                       </Button>
//                       <Button
//                         leftIcon={<MdCancel />}
//                         colorScheme="red"
//                         size="sm"
//                         ml={2}
//                         onClick={handleCancel}
//                       >
//                         Cancel
//                       </Button>
//                     </Flex>
//                   ) : (
//                     <Flex>
//                       <Button
//                         leftIcon={<MdEdit />}
//                         colorScheme="blue"
//                         size="sm"
//                         onClick={() => handleEditClick(hour.PWID)}
//                       >
//                         Edit
//                       </Button>
//                       <Button
//                         leftIcon={<MdDelete />}
//                         colorScheme="red"
//                         size="sm"
//                         onClick={() => handleDelete(hour.PWID)}
//                         ml={2}
//                       >
//                         Delete
//                       </Button>
//                     </Flex>
//                   )}
//                 </Td>
//               </Tr>
//             ))}
//           </Tbody>
//         </Table>
//       </Box>
//     </Card>
//   );
// };

// export default ListProviderWorkingHours;
import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Box,
  Input,
  Select,
  VStack,
  HStack,
  List,
  ListItem,
} from "@chakra-ui/react";
import { MdDelete, MdEdit, MdDone, MdCancel } from "react-icons/md";
import Card from "components/card/Card";
import fetchWithToken from "views/auth/signIn/axiosInstance"; // Adjust the path

const ListProviderWorkingHours = () => {
  const [workingHours, setWorkingHours] = useState([]);
  const [editableHour, setEditableHour] = useState(null);
  const [error, setError] = useState("");
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [UpdateUser, setUpdateUser] = useState("");

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      const [header, payload, signature] = accessToken.split(".");
      const decodedPayload = JSON.parse(atob(payload));
      const entryUser = decodedPayload.clientId;
      setUpdateUser(entryUser);
    } else {
      console.error("Access token not found.");
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [hoursResponse, providersResponse] = await Promise.all([
          fetchWithToken(
            `${process.env.REACT_APP_API_URL}/api/providerWorkingHr`
          ),
          fetchWithToken(`${process.env.REACT_APP_API_URL}/api/provider`),
        ]);
        setWorkingHours(hoursResponse);
        setProviders(providersResponse);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, []);

  const handleEditClick = (hourId) => {
    const hourToEdit = workingHours.find((hour) => hour.PWID === hourId);
    setEditableHour({ ...hourToEdit });
  };

  const handleCancel = () => {
    setEditableHour(null);
  };

  const handleSave = async () => {
    if (!editableHour || !editableHour.PWID) {
      console.error("Editable hour or PWID is missing");
      return;
    }

    try {
      const updatedParams = {
        PWID: editableHour.PWID,
        provID: editableHour.provID,
        start: editableHour.start,
        end: editableHour.end,
        UpdateUser: UpdateUser,
        Day: editableHour.Day,
      };

      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/providerWorkingHr`,
        "PUT",
        updatedParams
      );

      const updatedHour = response;
      setWorkingHours(
        workingHours.map((hour) =>
          hour.PWID === editableHour.PWID ? { ...hour, ...updatedHour } : hour
        )
      );
      setEditableHour(null);
      window.location.reload();
    } catch (error) {
      console.error("Error updating provider working hour:", error);
      setError(error.message);
    }
  };

  const handleChange = (e, field) => {
    setEditableHour({ ...editableHour, [field]: e.target.value });
  };

  const handleDelete = async (PWID) => {
    if (!window.confirm("Are you sure you want to delete this working hour?")) {
      return;
    }

    try {
      await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/providerWorkingHr`,
        "DELETE",
        { PWID }
      );

      setWorkingHours(workingHours.filter((hour) => hour.PWID !== PWID));
      console.log("Working hour deleted successfully");
    } catch (error) {
      console.error("Error deleting working hour:", error);
      setError("Failed to delete working hour");
    }
  };

  const handleProviderClick = (providerId) => {
    setSelectedProvider(providerId);
  };

  const filteredProviders = providers.filter((provider) =>
    provider.providerUsername.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const displayedWorkingHours = workingHours.filter(
    (hour) => hour.provID === selectedProvider
  );

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
      boxShadow="lg"
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text color="black" fontSize="22px" fontWeight="700" lineHeight="100%">
          Provider Working Hours List
        </Text>
        {error && <Text color="red.500">{error}</Text>}
      </Flex>
      <Box>
        <Input
          placeholder="Search provider"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          mb="20px"
        />
        <HStack align="start">
          <VStack align="start" spacing="10px" w="30%">
            <List spacing={3} w="100%">
              {filteredProviders.map((provider) => (
                <ListItem
                  key={provider.provID}
                  w="100%"
                  bg={
                    selectedProvider === provider.provID
                      ? "blue.200"
                      : "gray.100"
                  }
                  p="10px"
                  borderRadius="md"
                  cursor="pointer"
                  onClick={() => handleProviderClick(provider.provID)}
                >
                  {provider.providerUsername}
                </ListItem>
              ))}
            </List>
          </VStack>
          <Box w="70%">
            {selectedProvider && (
              <Box overflowY="auto" maxHeight="400px">
                <Table variant="simple" color="gray.500">
                  <Thead>
                    <Tr bg="blue.500" textColor="white">
                      <Th textColor="white">Index</Th>
                      <Th textColor="white">Day</Th>
                      <Th textColor="white">Start Time</Th>
                      <Th textColor="white">End Time</Th>
                      <Th textColor="white">Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {displayedWorkingHours.map((hour, index) => (
                      <Tr key={hour.PWID}>
                        <Td>{index + 1}</Td>
                        <Td>
                          {editableHour && editableHour.PWID === hour.PWID ? (
                            <Select
                              value={editableHour.Day || ""}
                              onChange={(e) => handleChange(e, "Day")}
                            >
                              <option value="Sunday">Sunday</option>
                              <option value="Monday">Monday</option>
                              <option value="Tuesday">Tuesday</option>
                              <option value="Wednesday">Wednesday</option>
                              <option value="Thursday">Thursday</option>
                              <option value="Friday">Friday</option>
                              <option value="Saturday">Saturday</option>
                            </Select>
                          ) : (
                            hour.Day
                          )}
                        </Td>
                        <Td>
                          {editableHour && editableHour.PWID === hour.PWID ? (
                            <Input
                              type="time"
                              value={editableHour.start || ""}
                              onChange={(e) => handleChange(e, "start")}
                            />
                          ) : (
                            hour.start
                          )}
                        </Td>
                        <Td>
                          {editableHour && editableHour.PWID === hour.PWID ? (
                            <Input
                              type="time"
                              value={editableHour.end || ""}
                              onChange={(e) => handleChange(e, "end")}
                            />
                          ) : (
                            hour.end
                          )}
                        </Td>
                        <Td>
                          {editableHour && editableHour.PWID === hour.PWID ? (
                            <Flex>
                              <Button
                                leftIcon={<MdDone />}
                                colorScheme="green"
                                size="sm"
                                onClick={handleSave}
                              >
                                Save
                              </Button>
                              <Button
                                leftIcon={<MdCancel />}
                                colorScheme="red"
                                size="sm"
                                ml={2}
                                onClick={handleCancel}
                              >
                                Cancel
                              </Button>
                            </Flex>
                          ) : (
                            <Flex>
                              <Button
                                leftIcon={<MdEdit />}
                                colorScheme="blue"
                                size="sm"
                                onClick={() => handleEditClick(hour.PWID)}
                              >
                                Edit
                              </Button>
                              <Button
                                leftIcon={<MdDelete />}
                                colorScheme="red"
                                size="sm"
                                onClick={() => handleDelete(hour.PWID)}
                                ml={2}
                              >
                                Delete
                              </Button>
                            </Flex>
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            )}
          </Box>
        </HStack>
      </Box>
    </Card>
  );
};

export default ListProviderWorkingHours;
