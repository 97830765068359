import React, { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Input,
  Button,
  Flex,
  Box,
} from "@chakra-ui/react";
import { MdDelete, MdEdit, MdDone, MdCancel } from "react-icons/md";

import Card from "components/card/Card";
import fetchWithToken from "views/auth/signIn/axiosInstance";
const ListOfCategory = () => {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [editableCategory, setEditableCategory] = useState(null);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [entryUser, setEntryUser] = useState("");
  const [updateUser, setUpdateUser] = useState("");
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      const [header, payload, signature] = accessToken.split(".");
      const decodedPayload = JSON.parse(atob(payload));
      const user = decodedPayload.clientId;
      setEntryUser(user);
      setUpdateUser(user);
    } else {
      console.error("Access token not found.");
    }
  }, []);
  //fetch api list category
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/category`
        );
        // if (!response) {
        //   throw new Error("Failed to fetch categories");
        // }
        const data = response; // await response.json();

        if (Array.isArray(data)) {
          setCategories(data);
          setFilteredCategories(data);
        } else {
          setCategories([]);
        }
      } catch (error) {
        setError(error.message);
      }
    };

    fetchCategories();
  }, []);

  const handleEditCategory = (category) => {
    setEditableCategory(category);
  };

  const handleSaveCategory = async () => {
    try {
      const formData = new FormData();
      formData.append("catId", editableCategory.catId);
      formData.append("catNameEng", editableCategory.catNameEng);
      formData.append("description", editableCategory.description);
      formData.append("catNameArb", editableCategory.catNameArb);
      formData.append("ordernumber", editableCategory.ordernumber);
      if (imageFile) {
        formData.append("icon", imageFile);
      }

      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/category`,
        //{
        //method:
        "PUT",
        // body:
        formData
        // }
      );

      // if (!response) {
      //   const errorData = response; // await .json();
      //   throw new Error(
      //     `Failed to update category: ${JSON.stringify(errorData)}`
      //   );
      // }

      //   const updatedData = await response.json();
      // Update permissions in the local state
      setCategories(
        categories.map((category) => {
          if (category.catId === editableCategory.catId) {
            return editableCategory;
          }
          return category;
        })
      );
      setFilteredCategories(
        filteredCategories.map((category) => {
          if (category.catId === editableCategory.catId) {
            return editableCategory;
          }
          return category;
        })
      );

      setEditableCategory(null);
      setImageFile(null);
      window.location.reload();
    } catch (error) {
      console.error("Error updating category:", error);
      setError(error.message);
    }
  };

  const handleDeleteCategory = async (catId) => {
    try {
      //
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/category`,
        "DELETE",
        { catId }
        // {
        //   method:
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify({  }),
        // }
      );
      if (!response) {
        throw new Error("Failed to delete category");
      }
      setError("");
      // Remove the deleted category from the state
      setCategories(categories.filter((category) => category.catId !== catId));
      setFilteredCategories(
        filteredCategories.filter((category) => category.catId !== catId)
      );
    } catch (error) {
      console.error("Error deleting category:", error);
      setError("Failed to delete category");
    }
  };

  // for show the icon
  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;

    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    return window.btoa(binary);
  };

  const displayIcon = (iconBuffer) => {
    // if (iconBuffer && iconBuffer.type === "Buffer") {
    //   const base64Icon = `data:image/png;base64,${arrayBufferToBase64(
    //     iconBuffer.data
    //   )}`;
    if (iconBuffer) {
      return (
        <img
          src={iconBuffer}
          alt="Category Icon"
          style={{ maxWidth: "50px", maxHeight: "50px" }}
        />
      );
    }
    return null;
  };
  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = categories.filter(
      (categorie) =>
        categorie.catNameEng.toLowerCase().includes(query.toLowerCase()) ||
        categorie.catNameArb.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredCategories(filtered);
  };
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color="gray.800"
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          List of Categories
        </Text>
        <Input
          placeholder="Search Category..."
          value={searchQuery}
          onChange={handleSearch}
          size="sm"
          w="200px"
        />
      </Flex>

      <Box overflowY="auto" maxHeight="400px">
        <Table variant="simple" color="gray.500" mb="24px">
          <Thead>
            <Tr bg="blue.500">
              <Th textColor="white">index</Th>
              <Th textColor="white">order</Th>
              <Th textColor="white">Category Name (English)</Th>
              <Th textColor="white">Description</Th>
              <Th textColor="white">Category Name (Arabic)</Th>
              <Th textColor="white">Icon</Th>
              <Th textColor="white">Actions</Th>
            </Tr>
          </Thead>

          <Tbody bg="white">
            {filteredCategories.map((category, index) => (
              <Tr key={category.catId}>
                <Td>{index + 1}</Td>
                <Td>
                  <Flex>
                    {editableCategory &&
                    editableCategory.catId === category.catId ? (
                      <Input
                        value={editableCategory.ordernumber}
                        onChange={(e) =>
                          setEditableCategory({
                            ...editableCategory,
                            ordernumber: e.target.value,
                          })
                        }
                      />
                    ) : (
                      category.ordernumber
                    )}
                  </Flex>
                </Td>
                <Td>
                  <Flex>
                    {editableCategory &&
                    editableCategory.catId === category.catId ? (
                      <Input
                        value={editableCategory.catNameEng}
                        onChange={(e) =>
                          setEditableCategory({
                            ...editableCategory,
                            catNameEng: e.target.value,
                          })
                        }
                      />
                    ) : (
                      category.catNameEng
                    )}
                  </Flex>
                </Td>
                <Td>
                  <Flex>
                    {editableCategory &&
                    editableCategory.catId === category.catId ? (
                      <Input
                        value={editableCategory.description}
                        onChange={(e) =>
                          setEditableCategory({
                            ...editableCategory,
                            description: e.target.value,
                          })
                        }
                      />
                    ) : (
                      category.description
                    )}
                  </Flex>
                </Td>
                <Td>
                  <Flex>
                    {editableCategory &&
                    editableCategory.catId === category.catId ? (
                      <Input
                        value={editableCategory.catNameArb}
                        onChange={(e) =>
                          setEditableCategory({
                            ...editableCategory,
                            catNameArb: e.target.value,
                          })
                        }
                      />
                    ) : (
                      category.catNameArb
                    )}
                  </Flex>
                </Td>

                <Td>
                  <Flex>
                    {editableCategory &&
                    editableCategory.catId === category.catId ? (
                      <Input
                        type="file"
                        accept="image/*"
                        onChange={(e) => setImageFile(e.target.files[0])}
                      />
                    ) : (
                      category.icon && displayIcon(category.icon)
                    )}
                  </Flex>
                </Td>

                <Td>
                  {editableCategory &&
                  editableCategory.catId === category.catId ? (
                    <Flex>
                      <Button
                        leftIcon={<MdDone />}
                        colorScheme="green"
                        size="sm"
                        onClick={handleSaveCategory}
                      >
                        Save
                      </Button>
                      <Button
                        leftIcon={<MdCancel />}
                        colorScheme="red"
                        ml={2}
                        size="sm"
                        onClick={() => setEditableCategory(null)}
                      >
                        Cancel
                      </Button>
                    </Flex>
                  ) : (
                    <Flex>
                      <Button
                        leftIcon={<MdEdit />}
                        colorScheme="blue"
                        size="sm"
                        onClick={() => handleEditCategory(category)}
                      >
                        Edit
                      </Button>
                      <Button
                        leftIcon={<MdDelete />}
                        colorScheme="red"
                        size="sm"
                        ml={2}
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to delete this role permission?"
                            )
                          ) {
                            handleDeleteCategory(category.catId);
                          }
                        }}
                      >
                        Delete
                      </Button>
                    </Flex>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {error && <Text color="red.500">{error}</Text>}
    </Card>
  );
};

export default ListOfCategory;
