import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  Polyline,
  Polygon,
  Circle,
  Rectangle,
  DrawingManager,
  StandaloneSearchBox,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { MdDelete, MdLocationCity, MdMyLocation } from "react-icons/md";
import { Button, Input, Box, Heading } from "@chakra-ui/react";
import { motion } from "framer-motion";
import fetchWithToken from "views/auth/signIn/axiosInstance"; // Adjust the path
const containerStyle = {
  width: "100%",
  height: "600px",
};
const center = { lat: 33.88251415887743, lng: 35.499557542115774 };

const libraries = ["places", "drawing"];

const MapComponent = () => {
  const [nameArb, setnameArb] = useState("");
  const [nameEng, setnameEng] = useState("");
  const [Coordinates, setCoordinates] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [selected, setSelected] = useState(null);
  const [drawings, setDrawings] = useState([]);
  const [origin, setOrigin] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [polygonPaths, setPolygonPaths] = useState([]);

  const [directions, setDirections] = useState(null);
  const searchBoxRef = useRef(null);
  const [drawingManagerOptions, setDrawingManagerOptions] = useState(null);
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = {
        nameEng: nameEng,
        nameArb: nameArb,
        Coordinates: polygonCoordinatesString,
      };
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/zone`,

        "POST",
        data
        // headers: {
        //   "Content-Type": "application/json",
        // },
      );

      // if (!response) {
      //   const errorData = await response.json();
      //   throw new Error(errorData.error || "Failed to add zone");
      // }

      setnameArb("");
      setnameEng("");
      setCoordinates("");

      setError("");
      setSuccess(true);

      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      setError(error.message || "An error occurred. Please try again later.");
    }
  };

  // Function to check and set drawing options
  const initializeDrawingManager = useCallback(() => {
    if (window.google && window.google.maps && window.google.maps.drawing) {
      setDrawingManagerOptions({
        drawingControl: true,
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.LEFT_CENTER,
          drawingModes: [
            window.google.maps.drawing.OverlayType.POLYGON,
            window.google.maps.drawing.OverlayType.POLYLINE,
            window.google.maps.drawing.OverlayType.RECTANGLE,
            window.google.maps.drawing.OverlayType.MARKER,
          ],
        },
      });
    } else {
      setTimeout(initializeDrawingManager, 1000); // Retry after 1 second
    }
  }, []);

  useEffect(() => {
    initializeDrawingManager();
  }, [initializeDrawingManager]);

  const onMapLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onMapClick = useCallback((e) => {
    setOrigin({ lat: e.latLng.lat(), lng: e.latLng.lng() });
  }, []);

  const onSearchBoxLoad = useCallback((ref) => {
    searchBoxRef.current = ref;
  }, []);

  const onPlacesChanged = useCallback(() => {
    const places = searchBoxRef.current.getPlaces();
    console.log(places); // Check what places look like in the console
    if (places.length) {
      const place = places[0];
      console.log(place); // Check the details of the selected place
      setSelected(place);
      map.fitBounds(place.geometry.viewport);
    }
  }, [map]);

  const onMarkerDragEnd = useCallback((e) => {
    const newPos = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    setOrigin(newPos);
    setMarkerPosition(newPos);
  }, []);

  const onOverlayComplete = (e) => {
    const newDrawing = e.overlay;
    newDrawing.type = e.type;
    if (newDrawing.type === "polygon") {
      // Get array, ensure the polygon is closed by adding the first point to the end if necessary
      let path = newDrawing
        .getPath()
        .getArray()
        .map((p) => ({ lat: p.lat(), lng: p.lng() }));
      if (
        path.length > 0 &&
        (path[0].lat !== path[path.length - 1].lat ||
          path[0].lng !== path[path.length - 1].lng)
      ) {
        path.push(path[0]); // Append the first point at the end to close the polygon
      }
      setPolygonPaths(path);
    }
  };

  // const onOverlayComplete = (e) => {
  //   const newDrawing = e.overlay;
  //   newDrawing.type = e.type;
  // };

  const clearDrawings = () => {
    drawings.forEach((drawing) => {
      if (drawing.setMap) {
        drawing.setMap(null);
      }
    });
    setDrawings([]);
  };
  // const onMarkerDragEnd = useCallback((e) => {
  //   setOrigin({ lat: e.latLng.lat(), lng: e.latLng.lng() });
  // }, []);
  // const fetchDirections = () => {
  //   if (locations.origin && locations.destination) {
  //     const directionsService = new window.google.maps.DirectionsService();
  //     directionsService.route({
  //       origin: locations.origin,
  //       destination: locations.destination,
  //       travelMode: window.google.maps.TravelMode.DRIVING
  //     }, (result, status) => {
  //       if (status === window.google.maps.DirectionsStatus.OK) {
  //         setDirections(result);
  //         // Calculate the total distance of the journey
  //         let totalDistance = 0;
  //         const route = result.routes[0];
  //         for (let i = 0; i < route.legs.length; i++) {
  //           totalDistance += route.legs[i].distance.value; // Distance in meters
  //         }
  //         totalDistance = totalDistance / 1000; // Convert to kilometers
  //         console.log(`Total Distance: ${totalDistance} km`);
  //         alert(`Total Distance: ${totalDistance} km`); // Display distance
  //       } else {
  //         console.error(`Error fetching directions: ${status}`);
  //       }
  //     });
  //   } else {
  //     alert('Please select both an origin and a destination on the map.');
  //   }
  // };

  const polygonCoordinatesString =
    polygonPaths.length > 0
      ? polygonPaths
          .map((pos) => `${pos.lat.toFixed(10)} ${pos.lng.toFixed(10)}`)
          .join(",")
      : "";

  const styles = {
    container: {
      position: "absolute",
      top: "10px",
      left: "10px",
      zIndex: "1001",
      backgroundColor: "rgba(255, 255, 255, 0.9)",
      padding: "10px",
      borderRadius: "8px",
      boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
      maxWidth: "300px",
    },
    input: {
      width: "100%",
      padding: "5px",
      marginTop: "5px",
      marginBottom: "5px",
      fontSize: "14px",
      border: "1px solid #ddd",
      borderRadius: "4px",
    },
    label: {
      fontWeight: "bold",
      color: "#555",
    },
  };

  const handleLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          // Update markers to include the new position with details if needed
          setMarkers((current) => [
            ...current,
            { ...pos, info: "You are here!" },
          ]);
          // Pan the map to the new position
          map.panTo(pos);
          // Set the zoom level closer to ground level for better view
          map.setZoom(15); // Adjust zoom level as needed (e.g., 15 is fairly close)
        },
        () => {
          alert("Failed to get your location");
        },
        { enableHighAccuracy: true } // This provides a more accurate position if available
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 0.5 }}
      style={{ textAlign: "center", maxWidth: "400px", margin: "auto" }}
    >
      <Box
        mt={10}
        boxShadow="0px 0px 10px rgba(0, 0, 255, 0.2)"
        p="6"
        borderRadius="xl"
        bg="white"
      >
        <Heading
          fontSize="20px"
          mb="10px"
          style={{ color: "#333", textAlign: "center" }}
        >
          Add New Zone
        </Heading>
        {error && <p style={{ color: "red", textAlign: "center" }}>{error}</p>}
        {success && (
          <p style={{ color: "green", textAlign: "center" }}>
            Zone added successfully!
          </p>
        )}
        <form onSubmit={handleSubmit}>
          <Input
            variant="filled"
            placeholder="English Name"
            value={nameEng}
            onChange={(e) => setnameEng(e.target.value)}
            required
            borderColor={"blue.500"}
            mb={4}
          />

          <Input
            variant="filled"
            placeholder="Arabic Name"
            value={nameArb}
            onChange={(e) => setnameArb(e.target.value)}
            required
            borderColor={"blue.500"}
            mb={4}
          />

          <Input
            variant="filled"
            placeholder=" Coordinates"
            value={polygonCoordinatesString}
            onChange={(e) => setCoordinates(e.target.value)}
            required
            borderColor={"blue.500"}
            mb={4}
          />

          <Button colorScheme="blue" type="submit" w="100%">
            Submit
          </Button>
        </form>
      </Box>

      <LoadScript
        googleMapsApiKey="AIzaSyCEllG77hecIdXvlZpaevITNS36FiPtasA"
        libraries={libraries}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={10}
          onLoad={onMapLoad}
          onClick={onMapClick}
        >
          {origin && (
            <Marker
              position={origin}
              draggable={true}
              onDragEnd={onMarkerDragEnd}
            />
          )}

          {selected && (
            <InfoWindow
              position={{
                lat: selected.geometry.location.lat(),
                lng: selected.geometry.location.lng(),
              }}
              onCloseClick={() => setSelected(null)}
            >
              <div>
                <h2>{selected.name}</h2>
                <p>{selected.formatted_address}</p>
              </div>
            </InfoWindow>
          )}

          {markers.map((marker, idx) => (
            <Marker key={idx} position={marker} />
          ))}

          <StandaloneSearchBox
            onLoad={onSearchBoxLoad}
            onPlacesChanged={onPlacesChanged}
          >
            <input
              type="text"
              placeholder="Search places"
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `240px`,
                height: `32px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                left: "50%",
                marginLeft: "-120px",
              }}
            />
          </StandaloneSearchBox>
          {drawingManagerOptions && (
            <DrawingManager
              options={drawingManagerOptions}
              onOverlayComplete={onOverlayComplete}
            />
          )}
          <Button
            leftIcon={<MdDelete />}
            onClick={clearDrawings}
            style={{
              position: "absolute",
              top: "180px", // Distance from the top
              left: "8px", // Distance from the left
              zIndex: 1000, // Ensure it's visible over the map
            }}
          ></Button>

          {/* {directions && <DirectionsRenderer directions={directions} />}
        <Button 
  style={{
    position: 'absolute', // Position it over the map
    top: '180px',          // Distance from the top
    left: '8px',           // Distance from the left
    zIndex: 1000           // Ensure it's visible over the map
  }}
  onClick={fetchDirections}>Get Directions</Button> */}

          <Button
            leftIcon={<MdMyLocation />}
            onClick={handleLocationClick}
            style={{
              position: "absolute", // Position it over the map
              top: "230px", // Distance from the top
              left: "8px", // Distance from the left
              zIndex: 1000, // Ensure it's visible over the map
            }}
          ></Button>
        </GoogleMap>

        <div style={styles.container}>
          {markerPosition && (
            <>
              <label>Marker Position:</label>
              <input
                readOnly
                value={`Lat: ${markerPosition.lat.toFixed(
                  6
                )}, Lng: ${markerPosition.lng.toFixed(6)}`}
              />
            </>
          )}
          {polygonPaths.length > 0 && (
            <Input
              variant="filled"
              placeholder="Polygon Coordinates"
              value={polygonCoordinatesString}
              borderColor="blue.500"
              mb={4}
              size="lg" // Optional, for larger input size
            />
          )}
        </div>
      </LoadScript>
    </motion.div>
  );
};

export default MapComponent;
