import React, { useState } from "react";
import { Box, Button, Center, Flex } from "@chakra-ui/react";
import { MdAdd, MdRemove } from "react-icons/md";
import CreateServiceForm from "./components/CreateService"; // Adjusted component name
import ListOfServices from "./components/ListServices"; // Adjusted component name

const Services = () => {
  const [showServiceForm, setShowServiceForm] = useState(false);

  const toggleServiceForm = () => {
    setShowServiceForm(!showServiceForm);
  };

  return (
    <Flex direction={"column"} alignContent={"center"}>
      <ListOfServices />

      <Flex direction={"column"} alignItems="center">
        <Center>
          <Button
            colorScheme={showServiceForm ? "red" : "blue"}
            leftIcon={showServiceForm ? <MdRemove /> : <MdAdd />}
            onClick={toggleServiceForm}
            marginBottom="20px"
            mt={"10"}
          >
            {showServiceForm ? "Hide Service Form" : "Add Service"}
          </Button>
        </Center>

        {showServiceForm && <CreateServiceForm />}
      </Flex>
    </Flex>
  );
};

export default Services;
