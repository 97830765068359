import React, { useState, useEffect } from "react";
import { Container } from "@chakra-ui/react";
import CreateProvider from "./components/createProvider";
import ListProvider from "./components/ProviderList";
import fetchWithToken from "views/auth/signIn/axiosInstance";
export default function Provider() {
  const [providers, setProviders] = useState([]);
  const [error, setError] = useState("");
  const [filteredProvider, setFilteredProvider] = useState([]);
  useEffect(() => {
    // Fetch providers from the API when the component mounts
    fetchProviders();
  }, []);
  const fetchClassificationName = async (idCode) => {
    try {
      //console.log(idCode);
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/code/${idCode}`
      );
      if (!response) {
        throw new Error("Failed to fetch code description");
      }
      const data = response; //await .json();
      if (Array.isArray(data) && data.length > 0) {
        return data[0].Description;
      } else {
        throw new Error("Role description not found");
      }
    } catch (error) {
      setError(error.message);
      return "";
    }
  };
  const fetchEducationName = async (idCode) => {
    try {
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/code/${idCode}`
      );
      if (!response) {
        throw new Error("Failed to fetch code description");
      }
      const data = response; //await .json();
      if (Array.isArray(data) && data.length > 0) {
        return data[0].Description;
      } else {
        throw new Error("Role description not found");
      }
    } catch (error) {
      setError(error.message);
      return "";
    }
  };
  const fetchSexName = async (idCode) => {
    try {
      //console.log(idCode);
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/code/${idCode}`
      );
      if (!response) {
        throw new Error("Failed to fetch code description");
      }
      const data = response; //await .json();
      if (Array.isArray(data) && data.length > 0) {
        return data[0].Description;
      } else {
        throw new Error("Role description not found");
      }
    } catch (error) {
      setError(error.message);
      return "";
    }
  };
  const fetchHomeLocation = async (idcity) => {
    try {
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/citys/${idcity}`
      );
      if (!response) {
        throw new Error("Failed to fetch  city");
      }
      const data = response; //await .json();
      if (Array.isArray(data) && data.length > 0) {
        return data[0].EName;
      } else {
        throw new Error("city  not found");
      }
    } catch (error) {
      setError(error.message);
      return "";
    }
  };

  const fetchProviders = async () => {
    try {
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/provider`
      );
      if (!response) {
        throw new Error("Failed to fetch provider");
      }
      // console.log(response);
      const data = response; //await .json();
      const updatePRovider = data.map(async (provider) => {
        const sexname = await fetchSexName(provider.Sex);
        //console.log(provider.Sex);
        const Classificationname = await fetchClassificationName(
          provider.Classification
        );
        const educationname = await fetchEducationName(provider.Education);
        // console.log(provider.LocationCode);
        const savecity = await fetchHomeLocation(provider.LocationCode);
        //  console.log(savecity);

        return {
          ...provider,
          savecity,
          sexname,
          Classificationname,
          educationname,
        };
      });
      const resolvedRolePermissions = await Promise.all(updatePRovider);

      setProviders(resolvedRolePermissions);
      setFilteredProvider(resolvedRolePermissions);
    } catch (error) {
      setError(error.message);
    }
  };

  //   const fetchProviders = async () => {
  //     try {
  //       const response = await fetchWithToken(
  //         `${process.env.REACT_APP_API_URL}/api/provider`
  //       );
  //       const data = response;
  //       setProviders(data);
  //     } catch (error) {
  //       console.error("Failed to fetch providers:", error);
  //     }
  //   };
  const deleteProvider = async (Provider) => {
    try {
      //   console.log(`Attempting to delete provider with ID: ${Provider}`);
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/provider`,
        "DELETE",
        Provider
      );

      if (!response) {
        throw new Error("Failed to delete provider");
      }

      setProviders((prevProviders) =>
        prevProviders.filter((provider) => provider.provID !== Provider.provID)
      );
      setFilteredProvider((prevFilteredProviders) =>
        prevFilteredProviders.filter(
          (provider) => provider.provID !== Provider.provID
        )
      );
    } catch (error) {
      console.error("Error deleting provider:", error);
    }
  };
  const addProvider = async (providerId) => {
    try {
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/provider/${providerId}`
      );
      const newProvider = response;
      setProviders((prevProviders) => [...prevProviders, newProvider]);
    } catch (error) {
      console.error("Failed to fetch new provider details:", error);
    }
  };

  return (
    <Container maxW="xxl" mt={100}>
      <div style={{ marginBottom: "20px" }}>
        <CreateProvider
          onAddProvider={addProvider}
          onFetchProviders={fetchProviders}
        />
      </div>
      <ListProvider
        providers={providers}
        onDeleteProvider={deleteProvider}
        filteredProviders={filteredProvider}
      />
      {/* /// /> */}
    </Container>
  );
}
