import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdPerson,
  MdHome,
  MdLock,
  MdSettings,
  MdPermContactCalendar,
  MdWorkspacePremium,
  MdHomeRepairService,
  MdOutlineNaturePeople,
  MdCategory,
  MdDisabledByDefault,
  MdImage,
  MdMyLocation,
  MdLocationCity,
  MdOutlinePayments,
  MdAccountTree,
} from "react-icons/md";
import { RiUserSettingsLine } from "react-icons/ri";
import { TbClockHour4 } from "react-icons/tb";
import { RiUserLocationFill } from "react-icons/ri";
import { GrServices } from "react-icons/gr";
// Admin Imports
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";
import users from "views/admin/Users";
import Role from "views/admin/Role";
import RolePermission from "views/admin/RolePermission";
import Services from "views/admin/Services";
import Category from "views/Category/index.jsx";

import Code from "views/admin/Code";
import Client from "views/Clients/index";
import Defaults from "views/Defaults/Defaults.jsx";

import Provider from "views/Provider/index";
import ProviderServices from "views/ProviderServices/index";
import ProviderZone from "views/ProviderZone/index";
import ProviderWorkingHours from "views/ProviderWorkingHour/index";
import ListProviderPayments from "views/ProviderPayment/index";
import ListProviderProject from "views/ProviderProject/index";
// Auth Imports
import SignInCentered from "views/auth/signIn";

//permission import
import Permission from "views/admin/Permission/Permission.jsx";

import ImageSlider from "views/ImageSlider/ImageSlider.jsx";

import Zone from "views/Zone//Zone.jsx";
import City from "views/City/index.jsx";
import ListProviderCertification from "views/ProviderCertification/index";
const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  // {
  //   name: "NFT Marketplace",
  //   layout: "/admin",
  //   path: "/nft-marketplace",
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   component: NFTMarketplace,
  //   secondary: true,
  // },
  // {
  //   name: "Data Tables",
  //   layout: "/admin",
  //   icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
  //   path: "/data-tables",
  //   component: DataTables,
  // },
  {
    name: "Provider",
    layout: "/admin",
    path: "/provider",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Provider,
  },
  {
    name: "Service",
    layout: "/admin",
    path: "/serviceprov",
    icon: <Icon as={GrServices} width="20px" height="20px" color="inherit" />,
    component: ProviderServices,
  },
  {
    name: "zone",
    layout: "/admin",
    path: "/zonprov",
    icon: (
      <Icon
        as={RiUserLocationFill}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: ProviderZone,
  },
  {
    name: "Working Hours",
    layout: "/admin",
    path: "/workinghours",
    icon: <Icon as={TbClockHour4} width="20px" height="20px" color="inherit" />,
    component: ProviderWorkingHours,
  },
  {
    name: "Payment",
    layout: "/admin",
    path: "/payment",
    icon: (
      <Icon as={MdOutlinePayments} width="20px" height="20px" color="inherit" />
    ),
    component: ListProviderPayments,
  },
  {
    name: "Certification",
    layout: "/admin",
    path: "/Certification",
    icon: (
      <Icon as={MdOutlinePayments} width="20px" height="20px" color="inherit" />
    ),
    component: ListProviderCertification,
  },
  {
    name: "Project",
    layout: "/admin",
    path: "/Project",
    icon: (
      <Icon as={MdAccountTree} width="20px" height="20px" color="inherit" />
    ),
    component: ListProviderProject,
  },

  {
    name: "City",
    layout: "/admin",
    path: "/city",
    icon: (
      <Icon as={MdLocationCity} width="20px" height="20px" color="inherit" />
    ),
    component: City,
  },

  {
    name: "Profile",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Profile,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "/rtl-default",
  //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  //   component: RTL,
  // },
  {
    name: "Services",
    layout: "/admin",
    path: "/services",
    icon: (
      <Icon
        as={MdHomeRepairService}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: Services,
  },
  {
    name: "Category",
    layout: "/admin",
    path: "/category",
    icon: <Icon as={MdCategory} width="20px" height="20px" color="inherit" />,
    component: Category,
  },
  {
    name: "Users",
    layout: "/admin",
    path: "/users",
    icon: (
      <Icon
        as={MdPermContactCalendar}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: users,
  },
  {
    name: "Role",
    layout: "/admin",
    path: "/role",
    icon: <Icon as={MdSettings} width="20px" height="20px" color="inherit" />,
    component: Role,
  },
  {
    name: "Role Permission",
    layout: "/admin",
    path: "/permrole",
    icon: (
      <Icon
        as={RiUserSettingsLine}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: RolePermission,
  },
  {
    name: "Permission",
    layout: "/admin",
    path: "/permission",
    icon: (
      <Icon
        as={MdWorkspacePremium}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: Permission,
  },
  {
    name: "Client",
    layout: "/admin",
    path: "/clients",
    icon: (
      <Icon
        as={MdOutlineNaturePeople}
        width="20px"
        height="24px"
        color="inherit"
      />
    ),
    component: Client,
  },
  {
    name: "Code",
    layout: "/admin",
    path: "/code",
    icon: (
      <Icon
        as={MdHomeRepairService}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: Code,
  },
  {
    name: "Defaults",
    layout: "/admin",
    path: "/defaults",
    icon: (
      <Icon
        as={MdDisabledByDefault}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: Defaults,
  },
  {
    name: "ImageSlider",
    layout: "/admin",
    path: "/imageslider",
    icon: <Icon as={MdImage} width="20px" height="20px" color="inherit" />,
    component: ImageSlider,
  },
  {
    name: "Zone",
    layout: "/admin",
    path: "/zone",
    icon: <Icon as={MdMyLocation} width="20px" height="20px" color="inherit" />,
    component: Zone,
  },
];

export default routes;
