import React, { useState, useEffect } from "react";
import { Button, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { motion } from "framer-motion";
import fetchWithToken from "views/auth/signIn/axiosInstance";

const AddImageSliderForm = () => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [entryUser, setEntryUser] = useState("");
  const [updateUser, setUpdateUser] = useState("");

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      const [header, payload, signature] = accessToken.split(".");
      const decodedPayload = JSON.parse(atob(payload));
      const user = decodedPayload.clientId;
      setEntryUser(user);
      setUpdateUser(user);
    } else {
      console.error("Access token not found.");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      setError("imgUrl is required");
      return;
    }

    const formData = new FormData();
    formData.append("entryUser", entryUser);
    formData.append("updateUser", updateUser);
    formData.append("imgUrl", file);

    // Log formData entries
    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    try {
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/slider`,
        "POST",
        formData
      );

      if (response && response.success) {
        setFile(null);
        setSuccessMessage("ImageSlider added successfully");
        setError("");
        window.location.reload();
      } else {
        const errorData = response || {};
        setError(errorData.error || "Failed to add ImageSlider");
        setSuccessMessage("");
      }
    } catch (error) {
      console.error("Error adding ImageSlider:", error);
      setError("Failed to add ImageSlider");
      setSuccessMessage("");
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 0.5 }}
      style={{
        textAlign: "center",
        maxWidth: "400px",
        margin: "auto",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0px 0px 10px rgba(0, 0, 255, 0.3)",
      }}
    >
      <h2 style={{ marginBottom: "20px", fontSize: "24px" }}>
        Add ImageSlider
      </h2>
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <FormControl style={{ width: "100%" }}>
          <FormLabel>
            ImageSlider:
            <Input
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
              style={{ marginBottom: "10px" }}
            />
          </FormLabel>
          <Button colorScheme="blue" type="submit">
            Add ImageSlider
          </Button>
        </FormControl>
      </form>
      {error && <p style={{ color: "red", marginTop: "10px" }}>{error}</p>}
      {successMessage && (
        <p style={{ color: "green", marginTop: "10px" }}>{successMessage}</p>
      )}
    </motion.div>
  );
};

export default AddImageSliderForm;
