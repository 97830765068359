/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";
import fetchWithToken from "views/auth/signIn/axiosInstance";
function CreateCode() {
  const [idCodeKind, setIdCodeKind] = useState("");
  const [Description, setDescription] = useState("");
  const [entryUser, setEntryUser] = useState(null); // State to store the entryUser
  const [updateUser, setUpdateUser] = useState("");
  const [error, setError] = useState(null);
  const [kinds, setKinds] = useState([]);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      // Split the token into header, payload, and signature
      const [header, payload, signature] = accessToken.split(".");
      // Decode the payload (Base64 decoded)
      const decodedPayload = JSON.parse(atob(payload));
      // Extract the username
      const entryUser = decodedPayload.clientId;
      // Set the entryUser in the state
      setEntryUser(entryUser);
      setUpdateUser(entryUser);
    } else {
      console.error("Access token not found.");
    }
  }, []); // Run this effect only once, similar to componentDidMount

  // API request for fetching kinds
  useEffect(() => {
    const fetchKinds = async () => {
      try {
        const response = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/kind`
        );
        if (response) {
          const kindsData = response; //await .json();
          setKinds(kindsData);
        } else {
          console.error("Error fetching kinds");
        }
      } catch (error) {
        console.error("Error fetching kinds:", error);
      }
    };

    fetchKinds();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if entryUser is available
    if (!entryUser) {
      console.error("Entry user not available.");
      return;
    }

    // API request for creating code
    try {
      const data = {
        idCodeKind,
        Description,
        entryUser,
        updateUser,
      };
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/code`,
        "POST",
        data
        // {
        //   method:
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify({
        //     idCodeKind,
        //     Description,
        //     entryUser,
        //     updateUser,
        //   }),
        // }
      );

      if (response) {
        //  console.log("Code created successfully");
        window.location.reload();
      } else {
        const errorData = response; //await .json();
        setError(errorData.error || "Failed to create code");
      }
    } catch (error) {
      console.error("Error creating code:", error);
      setError("Failed to create code");
    }
  };

  return (
    <Box
      mt={10}
      boxShadow="0px 0px 10px rgba(0, 0, 255, 0.2)"
      p="6"
      borderRadius="md"
      bg="white"
    >
      <Heading fontSize="36px" mb="10px">
        Create Code
      </Heading>
      <FormControl>
        <FormLabel
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          mb="8px"
        >
          Kind
        </FormLabel>
        <Select
          variant="auth"
          fontSize="sm"
          ms={{ base: "0px", md: "0px" }}
          placeholder="Select Kind"
          mb="24px"
          fontWeight="500"
          size="lg"
          value={idCodeKind}
          onChange={(e) => setIdCodeKind(e.target.value)}
        >
          {kinds.map((kind) => (
            <option key={kind.idCodeKind} value={kind.idCodeKind}>
              {kind.Description}
            </option>
          ))}
        </Select>
        <FormLabel ms="4px" fontSize="sm" fontWeight="500" display="flex">
          Description
        </FormLabel>
        <Input
          isRequired={true}
          variant="auth"
          fontSize="sm"
          ms={{ base: "0px", md: "0px" }}
          type="text"
          placeholder="Description"
          mb="24px"
          fontWeight="500"
          size="lg"
          value={Description}
          onChange={(e) => setDescription(e.target.value)}
        />

        <Button
          fontSize="sm"
          colorScheme="blue"
          fontWeight="500"
          w="100%"
          h="50"
          mb="24px"
          onClick={handleSubmit}
        >
          Create Code
        </Button>
        {error && <Text color="red">{error}</Text>}
      </FormControl>
    </Box>
  );
}

export default CreateCode;
