import React, { useState , useEffect} from 'react';
import { Button, Input } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import fetchWithToken from 'views/auth/signIn/axiosInstance';

export const InsertPermission = () => {
    // State variables to store form data and status
    const [description, setDescription] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [entryUser, setEntryUser] = useState(null);
    const [updateUser, setUpdateUser] = useState("");
    // Function to handle changes in description input
    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };
    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");
        //console.log("Access Token:", accessToken);
    
        if (accessToken) {
          // Split the token into header, payload, and signature
          // eslint-disable-next-line no-unused-vars
          const [header, payload, signature] = accessToken.split(".");
          // Decode the payload (Base64 decoded)
          const decodedPayload = JSON.parse(atob(payload));
          // Extract the username
          const entryUser = decodedPayload.clienId;
          // Set the entryUser in the state
          setEntryUser(entryUser);
          setUpdateUser(entryUser);
        } else {
          console.error("Access token not found.");
        }
      }, []); 
    // Function to handle form submission
    // const handleSubmit = async (event) => {
    //     event.preventDefault();
    //     try {

    //         const formData = new FormData();
    //         formData.append("description",description);
    //         const formDataString = JSON.stringify(Object.fromEntries(formData));

    //         // Encrypt the form data before sending it
    //       const encryptedFormData = await fetchWithToken('http://localhost:3001/encrypt', {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({ data: formDataString }),
    //       });
    
    //       if (!encryptedFormData.ok) {
    //         throw new Error('Failed to encrypt data');
    //       }
    
    //       const encryptedResult = await encryptedFormData.json();
    //       console.log(encryptedResult);
    //       console.log({
    //         data: encryptedResult.encryptedData,
    //         iv: encryptedResult.iv,
           
    //       });
    //       const formData1 = new FormData();
    //       formData1.append("data",  encryptedResult.encryptedData);
    //         formData1.append("iv", encryptedResult.iv);
      
    //       //  formData1.append("aa",  entryUser);

           

           
    //     const response = await fetchWithToken("http://localhost:3001/api/permission", {
    //       method: "POST",
    //       body:  formData1
    //     });

    //         // Check if request was successful
    //         if (!response) {
    //             const errorData = await response.json();
    //             throw new Error(errorData.error || 'Failed to add permission');
    //         }

    //         // Reset form fields after successful submission
    //         setDescription('');
    //         setError('');
    //         setSuccess(true);
    //         // Reload the page after a short delay
    //         setTimeout(() => {
    //             window.location.reload();
    //         }, 500);
    //     } catch (error) {
    //         // Handle error if POST request fails
    //         setError(error.message || 'An error occurred. Please try again later.');
    //     }
    // };
    const handleSubmit = async (event) => {
        event.preventDefault();
    
        try {
            const formData = new FormData();
            formData.append("description", description);
            
            const formDataString = JSON.stringify(Object.fromEntries(formData));
    
            // Encrypt the form data before sending it
            // const encryptedFormData = await fetchWithToken(`${process.env.REACT_APP_API_URL}/encrypt`, {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify({ data: formDataString }),
            // });
    
            // if (!encryptedFormData.ok) {
            //     throw new Error('Failed to encrypt data');
            // }
    
            // const encryptedResult = await encryptedFormData.json();
           
    const data = formDataString;
            const response = await fetchWithToken(`${process.env.REACT_APP_API_URL}/api/permission`,
                "POST", formDataString
            //      {
            //     method: 'POST',
            //     headers: {
            //       'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify({
            //       data: encryptedResult.encryptedData,
            //       iv: encryptedResult.iv
            //     }),
            //   }
            );
    
            // Check if request was successful
            if (!response) {
                const errorData = response//await .json();
                throw new Error(errorData.error || 'Failed to add permission');
            }
    
            // Reset form fields after successful submission
            setDescription('');
            setError('');
            setSuccess(true);
    
            // Reload the page after a short delay
            setTimeout(() => {
                window.location.reload();
            }, 500);
        } catch (error) {
            // Handle error if POST request fails
            setError(error.message || 'An error occurred. Please try again later.');
        }
    };
    
    return (
        <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
        >
            <h2 style={{ color: '#333', textAlign: 'center' }}>Add New Permission</h2>
            {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}
            {success && <p style={{ color: 'green', textAlign: 'center' }}>Permission added successfully!</p>}
            <form onSubmit={handleSubmit}>
                <Input
                    variant="filled"
                    placeholder="Permission Description"
                    value={description}
                    onChange={handleDescriptionChange}
                    required
                />
                <Button colorScheme="blue" type="submit" w="100%">
                    Submit
                </Button>
            </form>
        </motion.div>
    );
};
