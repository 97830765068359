import React, { useState } from "react";
import { Button, Container, Center } from "@chakra-ui/react";


import { ClientList } from "../Clients/components/listClient";

export default function Client() {

    return (
        <Container maxW="xxl" mt={100}>
            <ClientList />
        </Container>
    );
}
