// import React, { useState } from "react";
// import { Button, Container, Center } from "@chakra-ui/react";
// import { MdAdd, MdRemove } from "react-icons/md";
// import createProject from "./components/creatProject";
// import ListProject from "./components/ListProject";

// export default function Provider() {
//   return (
//     <Container maxW="xxl" mt={100}>
//       <div style={{ marginBottom: "20px" }}>{<createProject />}</div>
//       <ListProject />
//     </Container>
//   );
// }
import React from "react";
import { Container } from "@chakra-ui/react";

import ListCertification from "./Components/ListCertification";

export default function Providerservices() {
  return (
    <Container maxW="xxl" mt={100}>
      <ListCertification />
    </Container>
  );
}
