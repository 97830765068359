import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Box,
  Input,
  Checkbox,
} from "@chakra-ui/react";
import {
  MdDelete,
  MdEdit,
  MdDone,
  MdCancel,
  MdChevronLeft,
  MdChevronRight,
} from "react-icons/md";
import Card from "components/card/Card";
import fetchWithToken from "views/auth/signIn/axiosInstance";
export const DefaultList = () => {
  const [defaults, setDefaults] = useState([]);
  const [filteredDefaults, setFilteredDefaults] = useState([]);
  const [error, setError] = useState("");
  const [editableDefault, setEditableDefault] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [updateUser, setUpdateUser] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      const [header, payload, signature] = accessToken.split(".");
      const decodedPayload = JSON.parse(atob(payload));
      const entryUser = decodedPayload.clientId;
      setUpdateUser(entryUser);
    } else {
      console.error("Access token not found.");
    }
  }, []);

  useEffect(() => {
    const fetchDefaults = async () => {
      try {
        const response = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/def`
        );
        // if (!response) {
        //   throw new Error("Failed to fetch defaults");
        // }
        const data = response; //await .json();
        setDefaults(data);
        setFilteredDefaults(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchDefaults();
  }, []);

  const handleDeleteDefault = async (idDefaults) => {
    try {
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/def`,
        "DELETE",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        { defId: idDefaults }
      );

      //   if (!response) {
      //     throw new Error("Failed to delete default");
      //   }

      setDefaults(
        defaults.filter((defaultItem) => defaultItem.idDefaults !== idDefaults)
      );
      setFilteredDefaults(
        filteredDefaults.filter(
          (defaultItem) => defaultItem.idDefaults !== idDefaults
        )
      );
    } catch (error) {
      console.error("Error deleting default:", error);
      setError("Failed to delete default");
    }
  };

  const handleEditDefault = (defaultItem) => {
    setEditableDefault(defaultItem);
  };

  const handleSaveDefault = async () => {
    try {
      if (!editableDefault) {
        return;
      }

      const flagValue =
        editableDefault.flag && editableDefault.flag.data[0] === 1;

      const data = {
        defId: editableDefault.idDefaults,
        DefName: editableDefault.DefName,
        text: editableDefault.text,
        flag: flagValue,
        number: parseInt(editableDefault.number),
        UpdateUser: updateUser,
      };

      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/def`,
        "PUT",
        data
        // {
        //     method: "PUT",
        //     headers: {
        //       "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify(data),
        //   }
      );

      //   if (!response) {
      //     const errorData = await response.json();
      //     throw new Error(
      //       `Failed to update defaults: ${JSON.stringify(errorData)}`
      //     );
      //   }

      setDefaults(
        defaults.map((defaultItem) => {
          if (defaultItem.idDefaults === editableDefault.idDefaults) {
            return editableDefault;
          }
          return defaultItem;
        })
      );

      setFilteredDefaults(
        filteredDefaults.map((defaultItem) => {
          if (defaultItem.idDefaults === editableDefault.idDefaults) {
            return editableDefault;
          }
          return defaultItem;
        })
      );

      setEditableDefault(null);
    } catch (error) {
      console.error("Error updating default:", error);
      setError(error.message);
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = defaults.filter((defaultItem) =>
      defaultItem.DefName.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredDefaults(filtered);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  // Calculate pagination indexes
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredDefaults.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  return (
    <Card px="0px" boxShadow="xl" overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        {error && <Text color="red.500">{error}</Text>}
        <Text
          color="gray.800"
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Default List
        </Text>
        <Input
          placeholder="Search Defaults..."
          value={searchQuery}
          onChange={handleSearch}
          size="sm"
          w="200px"
        />
      </Flex>

      <Box overflowY="auto" maxHeight="600px">
        <Table color="gray.500" mb="24px">
          <Thead>
            <Tr bg="blue.500" textColor="white">
              <Th textColor="white">Index</Th>
              <Th textColor="white">Default Name</Th>
              <Th textColor="white">Text</Th>
              <Th textColor="white">Number</Th>
              <Th textColor="white">Flag</Th>
              <Th textColor="white">Entry User</Th>
              <Th textColor="white">Entry Date</Th>
              <Th textColor="white">Update User</Th>
              <Th textColor="white">Update Date</Th>
              <Th textColor="white">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {currentItems.map((defaultItem, index) => (
              <Tr key={defaultItem.idDefaults}>
                <Td>{index + 1}</Td>
                <Td>
                  <Flex>
                    {editableDefault &&
                    editableDefault.idDefaults === defaultItem.idDefaults ? (
                      <Input
                        value={editableDefault.DefName}
                        onChange={(e) =>
                          setEditableDefault({
                            ...editableDefault,
                            DefName: e.target.value,
                          })
                        }
                      />
                    ) : (
                      defaultItem.DefName
                    )}
                  </Flex>
                </Td>
                <Td>
                  <Flex>
                    {editableDefault &&
                    editableDefault.idDefaults === defaultItem.idDefaults ? (
                      <Input
                        value={editableDefault.text}
                        onChange={(e) =>
                          setEditableDefault({
                            ...editableDefault,
                            text: e.target.value,
                          })
                        }
                      />
                    ) : (
                      defaultItem.text
                    )}
                  </Flex>
                </Td>
                <Td>
                  <Flex>
                    {editableDefault &&
                    editableDefault.idDefaults === defaultItem.idDefaults ? (
                      <Input
                        type="number"
                        value={editableDefault.number}
                        onChange={(e) =>
                          setEditableDefault({
                            ...editableDefault,
                            number: e.target.value,
                          })
                        }
                      />
                    ) : (
                      defaultItem.number
                    )}
                  </Flex>
                </Td>
                <Td>
                  <Flex>
                    {editableDefault &&
                    editableDefault.idDefaults === defaultItem.idDefaults ? (
                      <Checkbox
                        isChecked={editableDefault.flag}
                        onChange={(e) =>
                          setEditableDefault({
                            ...editableDefault,
                            flag: e.target.checked,
                          })
                        }
                      />
                    ) : (
                      <div>{defaultItem.flag === 1 ? "Yes" : "No"}</div>
                    )}
                  </Flex>
                </Td>

                <Td>{defaultItem.EntryUser}</Td>
                <Td>{defaultItem.EntryDate}</Td>
                <Td>{defaultItem.UpdateUser}</Td>
                <Td>{defaultItem.UpdateDate}</Td>
                <Td>
                  {editableDefault &&
                  editableDefault.idDefaults === defaultItem.idDefaults ? (
                    <>
                      <Button
                        leftIcon={<MdDone />}
                        colorScheme="green"
                        size="sm"
                        onClick={handleSaveDefault}
                      >
                        Save
                      </Button>
                      <Button
                        leftIcon={<MdCancel />}
                        colorScheme="red"
                        size="sm"
                        onClick={() => setEditableDefault(null)}
                        mt={"1px"}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        leftIcon={<MdEdit />}
                        colorScheme="blue"
                        size="sm"
                        onClick={() => handleEditDefault(defaultItem)}
                      >
                        Edit
                      </Button>
                      <Button
                        leftIcon={<MdDelete />}
                        colorScheme="red"
                        size="sm"
                        mt={"1px"}
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to delete this default?"
                            )
                          ) {
                            handleDeleteDefault(defaultItem.idDefaults);
                          }
                        }}
                      >
                        Delete
                      </Button>
                    </>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <Flex justify="space-between" alignItems="center" px="25px" mt="20px">
        <Text color="gray.800">
          Showing {indexOfFirstItem + 1} -{" "}
          {Math.min(indexOfLastItem, filteredDefaults.length)} of{" "}
          {filteredDefaults.length}
        </Text>
        <Flex>
          <Button
            leftIcon={<MdChevronLeft />}
            colorScheme="blue"
            size="sm"
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <Button
            leftIcon={<MdChevronRight />}
            colorScheme="blue"
            size="sm"
            onClick={handleNextPage}
            ml="2"
            disabled={indexOfLastItem >= filteredDefaults.length}
          >
            Next
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
};
