import React, { useState } from 'react';
import { Button, Container, Center } from "@chakra-ui/react";

import { MdAdd, MdRemove } from 'react-icons/md';
import { CreateDefaults } from './components/CreateDefualts';
import { DefaultList } from './components/ListDefaults';

const Category = () => {
const [showDefaultForm, setShowDefaultForm] = useState(false);

  const toggleDefaultsForm = () => {
    setShowDefaultForm(!showDefaultForm);
  };

  return (
    <Container maxW="xxl" mt={100}>
      <Center>
        <Button 
          colorScheme={showDefaultForm ? "red" : "blue"} 
          leftIcon={showDefaultForm ? <MdRemove/> : <MdAdd />}
          onClick={toggleDefaultsForm}
          marginBottom="20px"
        >
          {showDefaultForm ? "Hide Default Form" : "Add  Default"}
        </Button>
      </Center>

      {showDefaultForm && (
        <div style={{ marginBottom: '20px' }}>
          <CreateDefaults /> 
        </div>
      )}

      <DefaultList/>
    </Container>
  );
};

export default Category;
