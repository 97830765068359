import React, { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Input,
  Button,
  Flex,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { MdDelete, MdEdit, MdDone, MdCancel } from "react-icons/md";
import fetchWithToken from "views/auth/signIn/axiosInstance";
import Card from "components/card/Card";
import { IoMdEye } from "react-icons/io";

const ListOfImageSlider = () => {
  const [ImageSliders, setImageSliders] = useState([]);
  const [error, setError] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [editableImageSliders, setEditableImageSliders] = useState(null);
  const [filteredImageSliders, setFilteredImageSliders] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [updateUser, setUpdateUser] = useState("");
  const [selectedImage, setSelectedImage] = useState(null); // State for managing the selected image for viewing

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      const [header, payload, signature] = accessToken.split(".");
      const decodedPayload = JSON.parse(atob(payload));
      const user = decodedPayload.clientId;
      setUpdateUser(user);
    } else {
      console.error("Access token not found.");
    }
  }, []);

  useEffect(() => {
    const fetchImageSlider = async () => {
      try {
        const response = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/slider`
        );

        if (Array.isArray(response)) {
          setImageSliders(response);
          setFilteredImageSliders(response);
        } else {
          setImageSliders([]);
          setFilteredImageSliders([]);
          console.log("Error: Decrypted data is not an array");
        }
      } catch (error) {
        setError(error.message);
      }
    };

    fetchImageSlider();
  }, []);

  const handleEditImageSlider = (ImageSlider) => {
    setEditableImageSliders(ImageSlider);
  };

  const handleSaveImageSlider = async () => {
    try {
      const formData = new FormData();
      formData.append("imgId", editableImageSliders.imgId);
      formData.append("updateUser", updateUser);

      if (imageFile) {
        formData.append("imgUrl", imageFile);
      }

      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/slider`,
        "PUT",
        formData
      );

      if (response.success) {
        setImageSliders(
          ImageSliders.map((ImageSlider) => {
            if (ImageSlider.imgId === editableImageSliders.imgId) {
              return { ...ImageSlider, ...editableImageSliders };
            }
            return ImageSlider;
          })
        );
        setFilteredImageSliders(
          filteredImageSliders.map((ImageSlider) => {
            if (ImageSlider.imgId === editableImageSliders.imgId) {
              return { ...ImageSlider, ...editableImageSliders };
            }
            return ImageSlider;
          })
        );

        setEditableImageSliders(null);
        setImageFile(null);
      } else {
        throw new Error(response.message || "Failed to update ImageSlider");
      }
    } catch (error) {
      console.error("Error updating ImageSlider:", error);
      setError("Failed to update ImageSlider");
    }
  };

  const handleDeleteImageSlider = async (imgId) => {
    try {
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/slider`,
        "DELETE",
        { imgId }
      );

      if (response.success) {
        setImageSliders(
          ImageSliders.filter((ImageSlider) => ImageSlider.imgId !== imgId)
        );
        setFilteredImageSliders(
          filteredImageSliders.filter(
            (ImageSlider) => ImageSlider.imgId !== imgId
          )
        );
      } else {
        throw new Error(response.message || "Failed to delete ImageSlider");
      }
    } catch (error) {
      console.error("Error deleting ImageSlider:", error);
      setError("Failed to delete ImageSlider");
    }
  };

  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;

    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    return window.btoa(binary);
  };

  const displayIcon = (imageUrl) => {
    if (imageUrl) {
      return (
        <img
          src={imageUrl}
          alt="ImageSlider"
          style={{ maxWidth: "100px", maxHeight: "100px" }}
        />
      );
    }
    return null;
  };

  const displayIconView = (imageUrl) => {
    if (imageUrl) {
      return (
        <img
          src={imageUrl}
          alt="ImageSlider"
          style={{ maxWidth: "100px", maxHeight: "100px" }}
        />
      );
    }
    return null;
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = ImageSliders.filter(
      (ImageSlider) =>
        ImageSlider.EntryUser.includes(query.toLowerCase()) ||
        ImageSlider.UpdateUser.includes(query.toLowerCase())
    );
    setFilteredImageSliders(filtered);
  };

  const handleViewImage = (ImageSlider) => {
    setSelectedImage(ImageSlider);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color="gray.800"
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          List of ImageSlider
        </Text>
        <Input
          placeholder="Search Category..."
          value={searchQuery}
          onChange={handleSearch}
          size="sm"
          w="200px"
        />
      </Flex>

      <Box overflowY="auto" maxHeight="400px">
        <Table variant="simple" color="gray.500" mb="24px">
          <Thead>
            <Tr bg="blue.500" textColor="white">
              <Th textColor="white">Index</Th>
              <Th textColor="white">Entry User</Th>
              <Th textColor="white">Entry Date</Th>
              <Th textColor="white">Update User</Th>
              <Th textColor="white">Update Date</Th>
              <Th textColor="white">Photo</Th>
              <Th textColor="white">Actions</Th>
            </Tr>
          </Thead>

          <Tbody bg="white">
            {filteredImageSliders.map((ImageSlider, index) => (
              <Tr key={ImageSlider.imgId}>
                <Td>{index + 1}</Td>
                <Td>{ImageSlider.EntryUser}</Td>
                <Td>{ImageSlider.EntryDate}</Td>
                <Td>{ImageSlider.UpdateUser}</Td>
                <Td>{ImageSlider.UpdateDate}</Td>
                <Td>
                  <Flex>
                    {editableImageSliders &&
                    editableImageSliders.imgId === ImageSlider.imgId ? (
                      <Input
                        type="file"
                        accept="image/*"
                        onChange={(e) => setImageFile(e.target.files[0])}
                      />
                    ) : (
                      ImageSlider.imgUrl && displayIcon(ImageSlider.imgUrl)
                    )}
                  </Flex>
                </Td>
                <Td>
                  <Flex>
                    {editableImageSliders &&
                    editableImageSliders.imgId === ImageSlider.imgId ? (
                      <React.Fragment>
                        <Button
                          leftIcon={<MdDone />}
                          colorScheme="green"
                          size="sm"
                          onClick={handleSaveImageSlider}
                        >
                          Save
                        </Button>
                        <Button
                          leftIcon={<MdCancel />}
                          colorScheme="red"
                          ml={2}
                          size="sm"
                          onClick={() => setEditableImageSliders(null)}
                        >
                          Cancel
                        </Button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Button
                          leftIcon={<MdEdit />}
                          colorScheme="blue"
                          size="sm"
                          onClick={() => handleEditImageSlider(ImageSlider)}
                        >
                          Edit
                        </Button>
                        <Button
                          leftIcon={<MdDelete />}
                          colorScheme="red"
                          size="sm"
                          ml={2}
                          onClick={() => {
                            if (
                              window.confirm(
                                "Are you sure you want to delete this role permission?"
                              )
                            ) {
                              handleDeleteImageSlider(ImageSlider.imgId);
                            }
                          }}
                        >
                          Delete
                        </Button>
                        <Button
                          leftIcon={<IoMdEye />}
                          colorScheme="teal"
                          size="sm"
                          ml={2}
                          onClick={() => handleViewImage(ImageSlider)}
                        >
                          View
                        </Button>
                      </React.Fragment>
                    )}
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {selectedImage && (
        <Modal isOpen={true} onClose={handleCloseModal} size="xl">
          <ModalOverlay />
          <ModalContent
            display="flex"
            alignItems="center"
            justifyContent="center"
            animation="fadeIn 0.3s ease-out"
          >
            <ModalHeader>View Image</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box textAlign="center">
                {selectedImage.imgUrl && displayIconView(selectedImage.imgUrl)}
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="teal" onClick={handleCloseModal}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {error && <Text color="red.500">{error}</Text>}
    </Card>
  );
};

export default ListOfImageSlider;
