import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Input,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Icon,
} from "@chakra-ui/react";
import { MdEdit, MdDelete, MdDone, MdCancel } from "react-icons/md";

import Card from "components/card/Card";
import fetchWithToken from "views/auth/signIn/axiosInstance";
const ServicesList = () => {
  const [services, setServices] = useState([]);
  const [error, setError] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [editableService, setEditableService] = useState(null);
  const [filteredServices, setFilteredServices] = useState([]);
  const [Categories, setCategories] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [UpdateUser, setUpdateUser] = useState("");
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      // Split the token into header, payload, and signature
      // eslint-disable-next-line no-unused-vars
      const [header, payload, signature] = accessToken.split(".");
      // Decode the payload (Base64 decoded)
      const decodedPayload = JSON.parse(atob(payload));
      // Extract the username
      const UpdateUser = decodedPayload.clientId;
      // Set the entryUser in the state
      setUpdateUser(UpdateUser);
    } else {
      console.error("Access token not found.");
    }
  }, []);
  // Fetch services from API
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const responseServices = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/service`
        );
        const responseCategories = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/category`
        );

        if (responseServices && responseCategories) {
          const servicesData = responseServices; //await responseServices.json();
          const categoriesData = responseCategories; //await responseCategories.json();
          setCategories(categoriesData);
          const servicesWithCategoryNames = servicesData.map((service) => {
            const category = categoriesData.find(
              (category) => category.catId === service.catId
            );
            return {
              ...service,
              catNameEng: category ? category.catNameEng : "",
              catNameArb: category ? category.catNameArb : "",
            };
          });

          setServices(servicesWithCategoryNames);
          setFilteredServices(servicesWithCategoryNames);
        } else {
          console.error("Error fetching services or categories");
        }
      } catch (error) {
        setError(error.message);
      }
    };

    fetchServices();
  }, []);

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    const filtered = services.filter(
      (service) =>
        service.serviceNameEng.toLowerCase().includes(query.toLowerCase()) ||
        service.catNameEng.toLowerCase().includes(query.toLowerCase()) ||
        service.catNameArb.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredServices(filtered);
  };

  const handleEditService = (service) => {
    setEditableService(service);
  };

  const handleSaveService = async () => {
    try {
      const data = {
        catId: editableService.catId,
        serviceNameEng: editableService.serviceNameEng,
        description: editableService.description,
        serviceId: editableService.serviceId,
        updateUser: UpdateUser,
        serviceNameArb: editableService.serviceNameArb,
        serviceIcon: "null",
        ordernumber: editableService.ordernumber,
      };
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/service`,
        //{
        //method:
        "PUT",
        // body:
        data
        // }
      );

      // if (!response) {
      //   const errorData = await response.json();
      //   throw new Error(
      //     `Failed to update service: ${JSON.stringify(errorData)}`
      //   );
      // }

      setServices(
        services.map((service) =>
          service.serviceId === editableService.serviceId
            ? editableService
            : service
        )
      );
      setFilteredServices(
        filteredServices.map((service) =>
          service.serviceId === editableService.serviceId
            ? editableService
            : service
        )
      );
      setEditableService(null);
      setImageFile(null);
      window.location.reload();
    } catch (error) {
      console.error("Error updating service:", error);
      setError(error.message);
    }
  };

  const handleDeleteService = async (serviceId) => {
    try {
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/service`,
        "DELETE",
        { serviceId }
        // {
        //   method: "DELETE",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify({ serviceId }),
        // }
      );

      // if (!response) {
      //   throw new Error("Failed to delete service");
      // }

      setServices(
        services.filter((service) => service.serviceId !== serviceId)
      );
      setFilteredServices(
        filteredServices.filter((service) => service.serviceId !== serviceId)
      );
    } catch (error) {
      console.error("Error deleting service:", error);
      setError("Failed to delete service");
    }
  };

  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;

    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    return window.btoa(binary);
  };

  const displayIcon = (iconBuffer) => {
    if (iconBuffer && iconBuffer.type === "Buffer") {
      const base64Icon = `data:image/png;base64,${arrayBufferToBase64(
        iconBuffer.data
      )}`;
      return (
        <img
          src={base64Icon}
          alt="Category Icon"
          style={{ maxWidth: "50px", maxHeight: "50px" }}
        />
      );
    }
    return null;
  };

  return (
    <Card
      mt="90"
      boxShadow="xl"
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text color="black" fontSize="22px" fontWeight="700" lineHeight="100%">
          Services List
        </Text>
        <Input
          placeholder="Search Service..."
          value={searchQuery}
          onChange={handleSearch}
          size="sm"
          w="200px"
        />
      </Flex>

      <Box overflowY="auto" maxHeight="400px">
        <Table variant="simple" color="black" mb="24px">
          <Thead>
            <Tr bg="blue.500" textColor="white">
              <Th textColor={"white"}>Index</Th>
              <Th textColor="white">order</Th>
              {/* <Th textColor={"white"}>Service Icon</Th> */}
              <Th textColor={"white"}>Service Name (Eng)</Th>
              <Th textColor={"white"}>Service Name (Arabic)</Th>
              <Th textColor={"white"}>Description</Th>
              <Th textColor={"white"}>Category (Eng)</Th>
              <Th textColor={"white"}>Category (Arabic)</Th>
              <Th textColor={"white"}>Actions</Th>
            </Tr>
          </Thead>
          <Tbody bg="white">
            {filteredServices.map((service, index) => (
              <Tr key={service.serviceId}>
                <Td>{index + 1}</Td>

                {/* <Td>
                  {editableService &&
                  editableService.serviceId === service.serviceId ? (
                    <Input
                      type="file"
                      accept="image/*"
                      onChange={(e) => setImageFile(e.target.files[0])}
                    />
                  ) : (
                    service.serviceIcon && displayIcon(service.serviceIcon)
                  )}
                </Td> */}
                <Td>
                  {editableService &&
                  editableService.serviceId === service.serviceId ? (
                    <Input
                      value={editableService.ordernumber}
                      onChange={(e) =>
                        setEditableService({
                          ...editableService,
                          ordernumber: e.target.value,
                        })
                      }
                    />
                  ) : (
                    service.ordernumber
                  )}
                </Td>
                <Td>
                  {editableService &&
                  editableService.serviceId === service.serviceId ? (
                    <Input
                      value={editableService.serviceNameEng}
                      onChange={(e) =>
                        setEditableService({
                          ...editableService,
                          serviceNameEng: e.target.value,
                        })
                      }
                    />
                  ) : (
                    service.serviceNameEng
                  )}
                </Td>

                <Td>
                  {editableService &&
                  editableService.serviceId === service.serviceId ? (
                    <Input
                      value={editableService.serviceNameArb}
                      onChange={(e) =>
                        setEditableService({
                          ...editableService,
                          serviceNameArb: e.target.value,
                        })
                      }
                    />
                  ) : (
                    service.serviceNameArb
                  )}
                </Td>

                <Td>
                  {editableService &&
                  editableService.serviceId === service.serviceId ? (
                    <Input
                      value={editableService.description}
                      onChange={(e) =>
                        setEditableService({
                          ...editableService,
                          description: e.target.value,
                        })
                      }
                    />
                  ) : (
                    service.description
                  )}
                </Td>

                <Td>
                  {editableService &&
                  editableService.serviceId === service.serviceId ? (
                    <select
                      value={editableService.catId}
                      onChange={(e) =>
                        setEditableService({
                          ...editableService,
                          catId: e.target.value,
                        })
                      }
                    >
                      {Categories.map((category) => (
                        <option key={category.catId} value={category.catId}>
                          {category.catNameEng}
                        </option>
                      ))}
                    </select>
                  ) : (
                    service.catNameEng
                  )}
                </Td>
                <Td>{service.catNameArb}</Td>
                <Td>
                  {editableService &&
                  editableService.serviceId === service.serviceId ? (
                    <Flex>
                      <Button
                        leftIcon={<MdDone />}
                        colorScheme="green"
                        size="sm"
                        onClick={handleSaveService}
                      >
                        Save
                      </Button>
                      <Button
                        leftIcon={<MdCancel />}
                        colorScheme="red"
                        ml={2}
                        size="sm"
                        onClick={() => setEditableService(null)}
                      >
                        Cancel
                      </Button>
                    </Flex>
                  ) : (
                    <Flex>
                      <Button
                        colorScheme="blue"
                        width={10}
                        size="sm"
                        onClick={() => handleEditService(service)}
                      >
                        <Icon as={MdEdit} boxSize={5} />
                      </Button>
                      <Button
                        colorScheme="red"
                        size="sm"
                        width={10}
                        ml={2}
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to delete this service?"
                            )
                          ) {
                            handleDeleteService(service.serviceId);
                          }
                        }}
                      >
                        <Icon as={MdDelete} boxSize={5} />
                      </Button>
                    </Flex>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {error && <Text color="red.500">{error}</Text>}
    </Card>
  );
};

export default ServicesList;
