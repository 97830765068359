import React, { useState, useEffect } from "react";
import { Button, Input, Box, Heading } from "@chakra-ui/react";
import { motion } from "framer-motion";
import fetchWithToken from "views/auth/signIn/axiosInstance";
export const CreateCity = () => {
  const [EName, setEName] = useState("");
  const [AName, setAName] = useState("");
  const [entryUser, setEntryUser] = useState("");
  const [updateUser, setUpdateUser] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      const [header, payload, signature] = accessToken.split(".");
      const decodedPayload = JSON.parse(atob(payload));
      const entryUser = decodedPayload.clientId;
      setEntryUser(entryUser);
      setUpdateUser(entryUser);
    } else {
      console.error("Access token not found.");
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = {
        EName: EName,
        AName: AName,
        entryUser: entryUser,
        updateUser: updateUser,
      };
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/city`,
        "POST",
        data
        // {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify({
        //     EName: EName,
        //     AName: AName,
        //     entryUser: entryUser,
        //     updateUser: updateUser,
        //   }),
        // }
      );

      if (!response) {
        const errorData = response; //await response.json();
        throw new Error(errorData.error || "Failed to add permission");
      }

      setAName("");
      setEName("");
      setEntryUser("");
      setUpdateUser("");
      setError("");
      setSuccess(true);

      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      setError(error.message || "An error occurred. Please try again later.");
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 0.5 }}
      style={{ textAlign: "center", maxWidth: "400px", margin: "auto" }}
    >
      <Box
        mt={10}
        boxShadow="0px 0px 10px rgba(0, 0, 255, 0.2)"
        p="6"
        borderRadius="xl"
        bg="white"
      >
        <Heading
          fontSize="20px"
          mb="10px"
          style={{ color: "#333", textAlign: "center" }}
        >
          Add New City
        </Heading>
        {error && <p style={{ color: "red", textAlign: "center" }}>{error}</p>}
        {success && (
          <p style={{ color: "green", textAlign: "center" }}>
            City added successfully!
          </p>
        )}
        <form onSubmit={handleSubmit}>
          <Input
            variant="filled"
            placeholder="English Name"
            value={EName}
            onChange={(e) => setEName(e.target.value)}
            required
            borderColor={"blue.500"}
            mb={4}
          />

          <Input
            variant="filled"
            placeholder="Arabic Name"
            value={AName}
            onChange={(e) => setAName(e.target.value)}
            required
            borderColor={"blue.500"}
            mb={4}
          />

          <Button colorScheme="blue" type="submit" w="100%">
            Submit
          </Button>
        </form>
      </Box>
    </motion.div>
  );
};
