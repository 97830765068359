import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Box,
  Input,
  Select,
  VStack,
  HStack,
  List,
  ListItem,
} from "@chakra-ui/react";
import { MdDelete, MdEdit, MdDone, MdCancel } from "react-icons/md";
import Card from "components/card/Card";
import fetchWithToken from "views/auth/signIn/axiosInstance"; // Adjust the path
const ListProviderservices = () => {
  const [providerservices, setProviderservices] = useState([]);
  const [filteredproviderservices, setFilteredproviderservices] = useState([]);
  const [error, setError] = useState("");
  const [editableproviderservices, setEditableproviderservices] =
    useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [updateUser, setupdateUser] = useState(null);
  const [service, setService] = useState([]);
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      const [header, payload, signature] = accessToken.split(".");
      const decodedPayload = JSON.parse(atob(payload));
      const updateUser = decodedPayload.clientId;
      setupdateUser(updateUser);
    } else {
      console.error("Access token not found.");
    }
  }, []);

  useEffect(() => {
    const fetchListProviderservices = async () => {
      try {
        const data = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/providerservice`
        );
        const updatedRolePermissions = data.map(async (provService) => {
          const serviceNameEng = await fetchServiceName(provService.serviceId);
          const ProvName = await fetchProvName(provService.provID);
          return { ...provService, serviceNameEng, ProvName };
        });
        const resolvedRolePermissions = await Promise.all(
          updatedRolePermissions
        );
        setProviderservices(resolvedRolePermissions);
        setFilteredproviderservices(resolvedRolePermissions);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchListProviderservices();
  }, []);

  const fetchServiceName = async (serviceId) => {
    try {
      const data = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/service/${serviceId}`
      );
      if (Array.isArray(data) && data.length > 0) {
        return data[0].serviceNameEng;
      } else {
        throw new Error("Service description not found");
      }
    } catch (error) {
      setError(error.message);
      return "";
    }
  };

  const fetchProvName = async (provID) => {
    try {
      const data = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/provider/${provID}`
      );
      if (Array.isArray(data) && data.length > 0) {
        return data[0].providerUsername;
      } else {
        throw new Error("Provider not found");
      }
    } catch (error) {
      setError(error.message);
      return "";
    }
  };

  useEffect(() => {
    const fetchService = async () => {
      try {
        const data = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/service`
        );
        setService(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchService();
  }, []);

  useEffect(() => {
    const fetchProvider = async () => {
      try {
        const data = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/provider`
        );
        setProviders(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchProvider();
  }, []);

  const handleDeleteproviderservices = async (providerserviceId) => {
    try {
      console.log("Deleting providerservicesId:", providerserviceId);
      await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/providerservice`,
        "DELETE",
        { providerserviceId }
      );
      setProviderservices(
        providerservices.filter(
          (providerservice) =>
            providerservice.providerserviceId !== providerserviceId
        )
      );
      setFilteredproviderservices(
        filteredproviderservices.filter(
          (providerservice) =>
            providerservice.providerserviceId !== providerserviceId
        )
      );
    } catch (error) {
      console.error("Error deleting providerservices:", error);
      setError("Failed to delete providerservices");
    }
  };

  const handleEditproviderservices = (providerservices) => {
    setEditableproviderservices(providerservices);
  };

  const handleSaveproviderservices = async () => {
    try {
      const data = {
        providerserviceId: editableproviderservices.providerserviceId,
        provID: editableproviderservices.provID,
        serviceId: editableproviderservices.serviceId,
        UpdateUser: updateUser,
      };

      await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/providerservice`,
        "PUT",
        data
      );

      setProviderservices((prevRolePermissions) =>
        prevRolePermissions.map((providerservice) => {
          if (
            providerservice.providerserviceId ===
            editableproviderservices.providerserviceId
          ) {
            return { ...editableproviderservices, ...providerservice };
          }
          return providerservice;
        })
      );

      setFilteredproviderservices((prevFilteredRolePermissions) =>
        prevFilteredRolePermissions.map((providerservice) => {
          if (
            providerservice.providerserviceId ===
            editableproviderservices.providerserviceId
          ) {
            return { ...editableproviderservices, ...providerservice };
          }
          return providerservice;
        })
      );

      setEditableproviderservices(null);
      window.location.reload();
    } catch (error) {
      console.error("Error updating role permission:", error);
      setError(error.message);
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = providerservices.filter((providerservice) =>
      providerservice.ProvName.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredproviderservices(filtered);
  };
  const filteredProviders = providers.filter((provider) =>
    provider.providerUsername.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const displayedProviderSERvice = providerservices.filter(
    (providerservices) => providerservices.provID === selectedProvider
  );
  const handleProviderClick = (providerId) => {
    setSelectedProvider(providerId);
  };
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
      boxShadow="lg"
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text color="black" fontSize="22px" fontWeight="700" lineHeight="100%">
          Provider Services List
        </Text>
        {error && <Text color="red.500">{error}</Text>}
        {/* <Input
          placeholder="Search providerservices..."
          value={searchQuery}
          onChange={handleSearch}
          size="sm"
          w="200px"
        /> */}
      </Flex>
      <Box>
        <Input
          placeholder="Search provider"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          mb="20px"
        />
        <HStack align="start">
          <VStack align="start" spacing="10px" w="30%">
            <List spacing={3} w="100%">
              {filteredProviders.map((provider) => (
                <ListItem
                  key={provider.provID}
                  w="100%"
                  bg={
                    selectedProvider === provider.provID
                      ? "blue.200"
                      : "gray.100"
                  }
                  p="10px"
                  borderRadius="md"
                  cursor="pointer"
                  onClick={() => handleProviderClick(provider.provID)}
                >
                  {provider.providerUsername}
                </ListItem>
              ))}
            </List>
          </VStack>
          <Box w="70%">
            {selectedProvider && (
              <Box overflowY="auto" maxHeight="400px">
                <Table variant="simple" color="gray.500" mb="24px">
                  <Thead>
                    <Tr bg="blue.500" textColor="white">
                      <Th textColor="white">Index</Th>
                      <Th textColor="white">Provider User</Th>
                      <Th textColor="white">Service</Th>
                      <Th textColor="white">Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {displayedProviderSERvice.map((providerservice, index) => (
                      <Tr key={providerservice.providerservicesId}>
                        <Td>{index + 1}</Td>
                        <Td>
                          {editableproviderservices &&
                          editableproviderservices.providerserviceId ===
                            providerservice.providerserviceId ? (
                            <Select
                              value={editableproviderservices.provID}
                              onChange={(e) => {
                                console.log("Selected Value:", e.target.value);
                                setEditableproviderservices({
                                  ...editableproviderservices,
                                  provID: e.target.value,
                                });
                              }}
                            >
                              {providers.map((provider) => (
                                <option
                                  key={provider.provID}
                                  value={provider.provID}
                                >
                                  {provider.providerUsername}
                                </option>
                              ))}
                            </Select>
                          ) : (
                            providerservice.ProvName
                          )}
                        </Td>
                        <Td>
                          {editableproviderservices &&
                          editableproviderservices.providerserviceId ===
                            providerservice.providerserviceId ? (
                            <Select
                              value={editableproviderservices.serviceId}
                              onChange={(e) =>
                                setEditableproviderservices({
                                  ...editableproviderservices,
                                  serviceId: e.target.value,
                                })
                              }
                            >
                              {service.map((services) => (
                                <option
                                  key={services.serviceId}
                                  value={services.serviceId}
                                >
                                  {services.serviceNameEng}
                                </option>
                              ))}
                            </Select>
                          ) : (
                            providerservice.serviceNameEng
                          )}
                        </Td>
                        <Td>
                          {editableproviderservices &&
                          editableproviderservices.providerserviceId ===
                            providerservice.providerserviceId ? (
                            <Flex>
                              <Button
                                leftIcon={<MdDone />}
                                colorScheme="green"
                                size="sm"
                                onClick={handleSaveproviderservices}
                              >
                                Save
                              </Button>
                              <Button
                                leftIcon={<MdCancel />}
                                colorScheme="red"
                                ml={2}
                                size="sm"
                                onClick={() =>
                                  setEditableproviderservices(null)
                                }
                              >
                                Cancel
                              </Button>
                            </Flex>
                          ) : (
                            <Flex>
                              <Button
                                leftIcon={<MdEdit />}
                                colorScheme="blue"
                                size="sm"
                                onClick={() =>
                                  handleEditproviderservices(providerservice)
                                }
                              >
                                Edit
                              </Button>
                              <Button
                                leftIcon={<MdDelete />}
                                colorScheme="red"
                                size="sm"
                                ml={2}
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you sure you want to delete this role permission?"
                                    )
                                  ) {
                                    handleDeleteproviderservices(
                                      providerservice.providerserviceId
                                    );
                                  }
                                }}
                              >
                                Delete
                              </Button>
                            </Flex>
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            )}
          </Box>
        </HStack>
      </Box>
    </Card>
  );
};

export default ListProviderservices;
