import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Box,
  Input,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormLabel,
  Grid,
} from "@chakra-ui/react";
import { MdDelete, MdEdit, MdDone } from "react-icons/md";
import Card from "components/card/Card";
import moment from "moment";

import { IoMdEye } from "react-icons/io";
import Map from "views/map/map";
import fetchWithToken from "views/auth/signIn/axiosInstance";
export const ListProvider = ({
  providers,
  onDeleteProvider,
  filteredProviders,
}) => {
  const [editMode, setEditMode] = useState(false); // State to manage edit mode

  const [sex, setSex] = useState("");

  const [Provider, setProvider] = useState([]);
  const [filteredProvider, setFilteredProvider] = useState([]);
  const [sexOptions, setSexOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [ClassificationOptions, setClassificationOptions] = useState([]);
  const [educationOptions, setEducationOptions] = useState([]);
  const [error, setError] = useState("");
  const [EditableProvider, setEditableProvider] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [citys, setcitys] = useState([]);

  const [ProviderUsername, setProviderUsername] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null); // State for managing the selected image for viewing
  const [imageFile, setImageFile] = useState(null);
  const [residence, setResidence] = useState(null);
  const [idFront, setIdFront] = useState(null);
  const [idBack, setIdBack] = useState(null);
  const [passport, setPassport] = useState(null);
  const [sejilAdel, setsejilAdel] = useState(null);
  // const [homeLocation, sethomeLocationl] = useState(null);

  const [updateUser, setUpdateUser] = useState("");
  const [profileImage, setProfileImage] = useState(null);

  const totalProvider = Provider.length;
  const totalMales = Provider.filter(
    (Provider) => Provider.Sex === "956cd7f0-4c87-4ac0-a7b7-6afbda52f4f8"
  ).length;
  const totalFemales = Provider.filter(
    (Provider) => Provider.Sex === "c6d7c072-da59-4af4-aceb-beb6b7973908"
  ).length;

  const [editableLocation, setEditableLocation] = useState("");

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    console.log("Access Token:", accessToken);

    if (accessToken) {
      // Split the token into header, payload, and signature
      // eslint-disable-next-line no-unused-vars
      const [header, payload, signature] = accessToken.split(".");
      // Decode the payload (Base64 decoded)
      const decodedPayload = JSON.parse(atob(payload));
      // Extract the username
      const entryUser = decodedPayload.clientId;
      // Set the entryUser in the state
      setUpdateUser(entryUser);
    } else {
      console.error("Access token not found.");
    }
  }, []); // Run this effect only once, similar to componentDidMount

  useEffect(() => {
    const fetchProvider = async () => {
      try {
        const response = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/provider`
        );
        if (!response) {
          throw new Error("Failed to fetch provider");
        }
        // console.log(response);
        const data = response; //await .json();
        const updatePRovider = data.map(async (provider) => {
          const sexname = await fetchSexName(provider.Sex);
          //console.log(provider.Sex);
          const Classificationname = await fetchClassificationName(
            provider.Classification
          );
          const educationname = await fetchEducationName(provider.Education);
          // console.log(provider.LocationCode);
          const savecity = await fetchHomeLocation(provider.LocationCode);
          //  console.log(savecity);

          return {
            ...provider,
            savecity,
            sexname,
            Classificationname,
            educationname,
          };
        });
        const resolvedRolePermissions = await Promise.all(updatePRovider);
        setProvider(resolvedRolePermissions);
        setFilteredProvider(resolvedRolePermissions);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchProvider();
  }, []);

  const fetchHomeLocation = async (idcity) => {
    try {
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/citys/${idcity}`
      );
      if (!response) {
        throw new Error("Failed to fetch  city");
      }
      const data = response; //await .json();
      if (Array.isArray(data) && data.length > 0) {
        return data[0].EName;
      } else {
        throw new Error("city  not found");
      }
    } catch (error) {
      setError(error.message);
      return "";
    }
  };

  const fetchSexName = async (idCode) => {
    try {
      //console.log(idCode);
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/code/${idCode}`
      );
      if (!response) {
        throw new Error("Failed to fetch code description");
      }
      const data = response; //await .json();
      if (Array.isArray(data) && data.length > 0) {
        return data[0].Description;
      } else {
        throw new Error("Role description not found");
      }
    } catch (error) {
      setError(error.message);
      return "";
    }
  };
  const fetchClassificationName = async (idCode) => {
    try {
      //console.log(idCode);
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/code/${idCode}`
      );
      if (!response) {
        throw new Error("Failed to fetch code description");
      }
      const data = response; //await .json();
      if (Array.isArray(data) && data.length > 0) {
        return data[0].Description;
      } else {
        throw new Error("Role description not found");
      }
    } catch (error) {
      setError(error.message);
      return "";
    }
  };
  useEffect(() => {
    const fetchCity = async () => {
      try {
        const response = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/city`
        );
        if (!response) {
          throw new Error("Failed to fetch permissions");
        }
        const data = response; //await .json();
        setcitys(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchCity();
  }, []);

  useEffect(() => {
    const fetchSex = async () => {
      try {
        const kindResponse = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/code`
        );
        const kindData = kindResponse; //await .json();
        const filteredSexCodes = kindResponse.filter(
          (code) => code.idCodeKind === "56647d21-82d5-4250-8e57-54d50e25ed5e"
        );
        const filteredClassCodes = kindResponse.filter(
          (code) => code.idCodeKind === "92f4e41a-cc9e-4071-bfde-96b0e9a268ea"
        );
        const filteredEduCodes = kindResponse.filter(
          (code) => code.idCodeKind === "6274fc85-b2fa-4f73-a7c6-d38c7d43a87e"
        );
        const filteredTypeCodes = kindResponse.filter(
          (code) => code.idCodeKind === "b951cdbe-ac58-4f5d-9470-2fa51901ed73"
        );
        setTypeOptions(filteredTypeCodes);
        setEducationOptions(filteredEduCodes);
        setClassificationOptions(filteredClassCodes);
        setSexOptions(filteredSexCodes);
      } catch (error) {
        setError("Failed to fetch sex");
      }
    };
    fetchSex();
  }, []);
  // useEffect(() => {
  //   const fetchClassification = async () => {
  //     try {
  //       const kindResponse = await fetchWithToken(
  //         `${process.env.REACT_APP_API_URL}/api/kind`
  //       );
  //       const kindData = kindResponse; //await .json();
  //       const sexKind = kindData.find(
  //         (kind) => kind.idCodeKind === "92f4e41a-cc9e-4071-bfde-96b0e9a268ea"
  //       );
  //       if (sexKind) {
  //         const codeResponse = await fetchWithToken(
  //           `${process.env.REACT_APP_API_URL}/api/code`
  //         );
  //         const codes = codeResponse; //await .json();
  //         const filteredCodes = codes.filter(
  //           (code) => code.idCodeKind === sexKind.idCodeKind
  //         );
  //         setClassificationOptions(filteredCodes);
  //       } else {
  //         setError('No kind with description "cals" found');
  //       }
  //     } catch (error) {
  //       setError("Failed to fetch sex");
  //     }
  //   };
  //   fetchClassification();
  // }, []);
  const fetchEducationName = async (idCode) => {
    try {
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/code/${idCode}`
      );
      if (!response) {
        throw new Error("Failed to fetch code description");
      }
      const data = response; //await .json();
      if (Array.isArray(data) && data.length > 0) {
        return data[0].Description;
      } else {
        throw new Error("Role description not found");
      }
    } catch (error) {
      setError(error.message);
      return "";
    }
  };

  // useEffect(() => {
  //   const fetchEducation = async () => {
  //     try {
  //       const kindResponse = await fetchWithToken(
  //         `${process.env.REACT_APP_API_URL}/api/kind`
  //       );
  //       const kindData = kindResponse; //await .json();
  //       const EducationKind = kindData.find(
  //         (kind) => kind.Description === "Education"
  //       );
  //       if (EducationKind) {
  //         const codeResponse = await fetchWithToken(
  //           `${process.env.REACT_APP_API_URL}/api/code`
  //         );
  //         const codes = codeResponse; //await .json();
  //         const filteredCodes = codes.filter(
  //           (code) => code.idCodeKind === EducationKind.idCodeKind
  //         );
  //         setEducationOptions(filteredCodes);
  //       } else {
  //         setError('No kind with description "Education" found');
  //       }
  //     } catch (error) {
  //       setError("Failed to fetch sex");
  //     }
  //   };
  //   fetchEducation();
  // }, []);
  const handleDeleteProvider = async (Provider) => {
    try {
      const prevProviders = Provider;

      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/provider`,
        "DELETE",
        Provider
        // {
        //   method:
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify({
        //     ,
        //   }),
        // }
      );
      if (!response) {
        throw new Error("Failed to delete provider");
      }
      // Remove the deleted Provider from the Providers state

      // Remove the deleted provider from the Providers state

      setProvider((prevProviders) =>
        prevProviders.filter((provider) => provider.provID !== Provider.provID)
      );
      setFilteredProvider((prevFilteredProviders) =>
        prevFilteredProviders.filter(
          (provider) => provider.provID !== Provider.provID
        )
      );

      // setProvider(
      //   Provider.filter((provider) => provider.provID !== Provider.provID)
      // );
      // setFilteredProvider(
      //   filteredProvider.filter(
      //     (provider) => provider.provID !== Provider.provID
      //   )
      // );
    } catch (error) {
      console.error("Error deleting provider:", error);
      setError("Failed to delete provider");
    }
  };
  const handleDeleteProviders = (Provider) => {
    //    console.log(`Delete button clicked for provider ID: ${Provider}`);
    onDeleteProvider(Provider);
  };
  const handlePointSelect = (point) => {
    // Update the input field value with the selected point
    setEditableLocation(`${point.lat.toFixed(6)} ${point.lng.toFixed(6)}`);
  };

  // Function to handle file change for each photo
  const handleFileChange = (file, setterFunction) => {
    setterFunction(file);
  };

  const handleEditProvider = (provider) => {
    setProviderUsername(provider.providerUsername);
    setEditableProvider({
      ...provider,
      providerUsername: "",
      providerPass: "", // Set providerUsername to null when editing
    });
  };

  const handleSaveProvider = async () => {
    try {
      const formData = new FormData();
      formData.append("provId", EditableProvider.provID);
      formData.append("firstName", EditableProvider.firstName);
      formData.append("middleName", EditableProvider.middleName);
      formData.append("lastName", EditableProvider.lastName);
      formData.append("sex", EditableProvider.Sex);
      formData.append("phoneNumber", EditableProvider.phoneNumber);
      formData.append("description", EditableProvider.description);
      // Check if providerUsername has been edited before appending
      if (EditableProvider.providerUsername.trim() !== "") {
        formData.append("providerUsername", EditableProvider.providerUsername);
      }
      formData.append("provUsername", ProviderUsername);

      if (EditableProvider.providerPass)
        formData.append("providerPass", EditableProvider.providerPass);

      formData.append("onlineStatus", 1);
      if (EditableProvider.idcity)
        formData.append("homeLocation", EditableProvider.idcity);
      formData.append("updateUser", updateUser);
      // formData.append('UpdateDate', "")

      formData.append("Verified", EditableProvider.Verified);
      formData.append("type", EditableProvider.Type);
      formData.append("Instagram", EditableProvider.Instagram);
      formData.append("FaceBook", EditableProvider.FaceBook);
      formData.append("LinkedIn", EditableProvider.LinkedIn);
      formData.append("Website", EditableProvider.Website);
      formData.append("Education", EditableProvider.Education);
      formData.append("Keyword", EditableProvider.Keyword);
      formData.append("Classification", EditableProvider.Classification);
      if (EditableProvider.Indexs)
        formData.append("Indexs", EditableProvider.Indexs);
      if (profileImage) formData.append("profileImage", profileImage);
      if (idBack) formData.append("idBack", idBack);
      if (idFront) formData.append("idFront", idFront);
      if (passport) formData.append("passport", passport);
      if (residence) formData.append("residence", residence);
      if (sejilAdel) formData.append("sejilAdel", sejilAdel);
      if (editableLocation) formData.append("Location", editableLocation);

      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/provider`,
        "PUT",
        formData
        // {
        //   method:
        //   body: ,
        // }
      );

      if (!response) {
        const errorData = response; //await .json();
        throw new Error(
          `Failed to update category: ${JSON.stringify(errorData)}`
        );
      }

      //   const updatedData = await response.json();
      // Update permissions in the local state
      setProvider(
        Provider.map((category) => {
          if (category.catId === EditableProvider.provID) {
            return EditableProvider;
          }
          return category;
        })
      );
      setFilteredProvider(
        filteredProvider.map((provider) => {
          if (provider.provID === EditableProvider.provID) {
            return EditableProvider;
          }
          return provider;
        })
      );

      setEditableProvider(null);
      setImageFile(null);
      // window.location.reload();
    } catch (error) {
      console.error("Error updating category:", error);
      setError(error.message);
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = Provider.filter((provider) =>
      provider.providerUsername.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredProvider(filtered);
  };

  const calculateDayWithMostProvider = (Provider) => {
    if (Provider.length === 0) {
      return null;
    }

    const dayCountMap = {};

    Provider.forEach((provider) => {
      const creationDay = moment(provider.entryDate).format("YYYY-MM-DD");
      dayCountMap[creationDay] = (dayCountMap[creationDay] || 0) + 1;
    });

    const maxDate = Object.keys(dayCountMap).reduce((a, b) =>
      dayCountMap[a] > dayCountMap[b] ? a : b
    );

    return maxDate;
  };

  // const arrayBufferToBase64 = (buffer) => {

  //     let binary = '';
  //     const bytes = new Uint8Array(buffer);

  //     const len = bytes.byteLength;

  //     for (let i = 0; i < len; i++) {
  //       binary += String.fromCharCode(bytes[i]);
  //     }

  //     return window.btoa(binary);
  //   };

  const displayIconView = (imageUrl) => {
    if (imageUrl) {
      return (
        <img
          src={imageUrl}
          alt="ImageSlider"
          style={{ maxWidth: "100px", maxHeight: "100px" }}
        />
      );
    }
    return null;
  };

  const handleViewProvider = (provider) => {
    setSelectedProvider(provider);
    setEditMode(false); // Set edit mode to false when viewing
  };

  const handleCloseModal = () => {
    setProviderUsername(null);
    setSelectedProvider(null);
    setEditMode(false); // Set edit mode to false when closing
  };

  // const DayWithMostProvider = DayWithMostProvider(Provider);

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color="gray.800"
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Provider List
        </Text>
        <Input
          placeholder="Search provider..."
          value={searchQuery}
          onChange={handleSearch}
          size="sm"
          w="200px"
        />
      </Flex>
      <Box overflowY="auto" maxHeight="400px">
        <Table variant="simple" color="gray.500" mb="24px">
          <Thead>
            <Tr bg="blue.500" textColor="white">
              <Th textColor="white">Id</Th>
              <Th textColor="white">first Name</Th>
              <Th textColor="white">middle Name</Th>
              <Th textColor="white">last Name</Th>
              <Th textColor="white">Username</Th>

              <Th textColor="white">phone</Th>

              <Th textColor="white">Location</Th>
              <Th textColor="white">Verified</Th>
              <Th textColor="white">EntryUser</Th>
              <Th textColor="white">EntryDate</Th>
              <Th textColor="white">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredProviders.map((Provider, index) => (
              <Tr key={Provider.provID}>
                <Td>{index + 1}</Td>
                <Td>
                  <Flex>{Provider.firstName}</Flex>
                </Td>
                <Td>
                  <Flex>{Provider.middleName}</Flex>
                </Td>
                <Td>
                  <Flex>{Provider.lastName}</Flex>
                </Td>
                <Td>{Provider.providerUsername}</Td>

                <Td>{Provider.phoneNumber}</Td>
                <Td>{Provider.savecity}</Td>
                <Td>
                  {Provider.Verified === 1 ? (
                    <div>Verified</div>
                  ) : (
                    <div>Not Verified</div>
                  )}
                </Td>
                <Td>{Provider.entryUser}</Td>
                <Td>{Provider.UpdateUser}</Td>
                <Td>
                  <>
                    <Button
                      leftIcon={<MdEdit />}
                      colorScheme="blue"
                      size="sm"
                      onClick={() => handleViewProvider(Provider)}
                    >
                      Edit
                    </Button>
                    <Button
                      leftIcon={<MdDelete />}
                      colorScheme="red"
                      size="sm"
                      ml={2}
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to delete this provider?"
                          )
                        ) {
                          console.log(123);
                          handleDeleteProviders(Provider);
                        }
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      leftIcon={<IoMdEye />}
                      colorScheme="teal"
                      size="sm"
                      ml={2}
                      onClick={() => handleViewProvider(Provider)}
                    >
                      View
                    </Button>
                  </>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <Box mt="4">
        <Text fontSize="lg" fontWeight="bold" color="blue.500">
          Totals:
        </Text>
        <Flex mt="2">
          <Text mr="4">Total Provider: {totalProvider}</Text>
          <Text mr="4">Total Males: {totalMales}</Text>
          <Text mr="4">Total Females: {totalFemales}</Text>
          <Text>Day with most registers: {calculateDayWithMostProvider}</Text>
        </Flex>
      </Box>
      {selectedProvider && (
        <Modal isOpen={true} onClose={handleCloseModal} size="xl">
          <ModalOverlay />
          <ModalContent
            // Center the modal vertically and horizontally
            display="flex"
            alignItems="center"
            justifyContent="center"
            // Add animation to the modal
            animation="fadeIn 0.3s ease-out"
          >
            <ModalHeader>View Image</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {/* Display the selected image with a custom styling */}
              <Box text>
                <Flex direction="column" mb="20px">
                  {/* First Name */}
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={6}>
                        First Name:
                      </Text>
                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <Input
                            value={EditableProvider.firstName}
                            onChange={(e) =>
                              setEditableProvider({
                                ...EditableProvider,
                                firstName: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <>{selectedProvider.firstName}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>

                  {/* Middle Name */}
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={2}>
                        Middle Name:
                      </Text>
                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <Input
                            value={EditableProvider.middleName}
                            onChange={(e) =>
                              setEditableProvider({
                                ...EditableProvider,
                                middleName: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <>{selectedProvider.middleName}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>

                  {/* Last Name */}
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={7}>
                        Last Name:
                      </Text>
                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <Input
                            value={EditableProvider.lastName}
                            onChange={(e) =>
                              setEditableProvider({
                                ...EditableProvider,
                                lastName: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <>{selectedProvider.lastName}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>

                  {/* Sex */}
                  {/* <div>
        <Flex   mb={2}>
        <Text fontSize="l" color="black" mr={20}>

            Sex:   
          </Text>
          <Flex>
          {EditableProvider && EditableProvider.provID === selectedProvider.provID ? (
            <Input
              value={"soon"}
              onChange={(e) => setEditableProvider({ ...EditableProvider, Sex: e.target.value })}
            />
          ) : (
            <>soon</>
          )}
          </Flex>
        </Flex>
      </div> */}

                  {/* Phone Number */}
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="sm" color="black" mr={3}>
                        Phone Number:
                      </Text>
                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <Input
                            value={EditableProvider.phoneNumber}
                            onChange={(e) =>
                              setEditableProvider({
                                ...EditableProvider,
                                phoneNumber: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <>{selectedProvider.phoneNumber}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>

                  {/* Description */}
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={5}>
                        Description:
                      </Text>
                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <Input
                            value={EditableProvider.description}
                            onChange={(e) =>
                              setEditableProvider({
                                ...EditableProvider,
                                description: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <>{selectedProvider.description}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>

                  {/* Provider Username */}
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={7}>
                        Username:
                      </Text>
                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <Input
                            value={EditableProvider.providerUsername}
                            onChange={(e) =>
                              setEditableProvider({
                                ...EditableProvider,
                                providerUsername: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <>{selectedProvider.providerUsername}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>

                  {/* Provider Password */}
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={8}>
                        Password:
                      </Text>
                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <Input
                            type="password"
                            value={EditableProvider.providerPass}
                            onChange={(e) =>
                              setEditableProvider({
                                ...EditableProvider,
                                providerPass: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <>********</>
                        )}
                      </Flex>
                    </Flex>
                  </div>

                  {/* Online Status */}
                  <div>
                    <Flex mb={2}>
                      <Flex>
                        <Text fontSize="l" color="black" mr={1}>
                          Online Status:
                        </Text>
                      </Flex>

                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <Input
                            value={EditableProvider.onlineStatus}
                            onChange={(e) =>
                              setEditableProvider({
                                ...EditableProvider,
                                onlineStatus: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <div className="data">
                            {selectedProvider.onlineStatus === 1
                              ? "online"
                              : "Not online"}
                          </div>
                        )}
                      </Flex>
                    </Flex>
                  </div>

                  {/* Sex */}
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={20}>
                        Sex
                      </Text>
                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <Select
                            placeholder="Select"
                            id="sex"
                            name="sex"
                            value={EditableProvider.Sex}
                            onChange={(e) =>
                              setEditableProvider({
                                ...EditableProvider,
                                Sex: e.target.value,
                              })
                            }
                          >
                            {sexOptions.map((option) => (
                              <option key={option.idCode} value={option.idCode}>
                                {option.Description}
                              </option>
                            ))}
                          </Select>
                        ) : (
                          <>{selectedProvider.sexDesc}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>
                  {/* Verified */}
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={10}>
                        Verified :
                      </Text>
                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <Select
                            id="Verified"
                            name="Verified"
                            value={EditableProvider.Verified}
                            onChange={(e) =>
                              setEditableProvider({
                                ...EditableProvider,
                                Verified: e.target.value,
                              })
                            }
                          >
                            <option value="1">Verified</option>
                            <option value="0">Not Verified</option>
                          </Select>
                        ) : (
                          <div className="data">
                            {selectedProvider.Verified === 1
                              ? "Verified"
                              : "Not Verified"}
                          </div>
                        )}
                      </Flex>
                    </Flex>
                  </div>

                  {/* Type */}

                  {/* Sex */}
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={20}>
                        Type:
                      </Text>
                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <Select
                            placeholder="Select"
                            id="Type"
                            name="Type"
                            value={EditableProvider.Type}
                            onChange={(e) =>
                              setEditableProvider({
                                ...EditableProvider,
                                Type: e.target.value,
                              })
                            }
                          >
                            {typeOptions.map((option) => (
                              <option
                                key={option.idCode}
                                value={option.Description}
                              >
                                {option.Description}
                              </option>
                            ))}
                          </Select>
                        ) : (
                          <>{selectedProvider.Type}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>
                  {/* <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={16}>
                        Type:
                      </Text>
                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <Input
                            value={EditableProvider.Type}
                            onChange={(e) =>
                              setEditableProvider({
                                ...EditableProvider,
                                Type: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <>{selectedProvider.Type}</>
                        )}
                      </Flex>
                    </Flex>
                  </div> */}

                  {/* Instagram */}
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={7}>
                        Instagram:
                      </Text>
                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <Input
                            value={EditableProvider.Instagram}
                            onChange={(e) =>
                              setEditableProvider({
                                ...EditableProvider,
                                Instagram: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <div className="data">
                            {selectedProvider.Instagram}
                          </div>
                        )}
                      </Flex>
                    </Flex>
                  </div>

                  {/* Facebook */}
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={7}>
                        Facebook:
                      </Text>
                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <Input
                            value={EditableProvider.FaceBook}
                            onChange={(e) =>
                              setEditableProvider({
                                ...EditableProvider,
                                FaceBook: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <div className="data">
                            {selectedProvider.FaceBook}
                          </div>
                        )}
                      </Flex>
                    </Flex>
                  </div>
                  {/* Display LinkedIn */}
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={10}>
                        LinkedIn:
                      </Text>
                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <Input
                            value={EditableProvider.LinkedIn}
                            onChange={(e) =>
                              setEditableProvider({
                                ...EditableProvider,
                                LinkedIn: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <>{selectedProvider.LinkedIn}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>
                  {/* Display Website */}
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={10}>
                        Website:
                      </Text>
                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <Input
                            value={EditableProvider.Website}
                            onChange={(e) =>
                              setEditableProvider({
                                ...EditableProvider,
                                Website: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <>{selectedProvider.website}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>
                  {/* Display Education */}
                  {/* <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={7}>
                        Education:
                      </Text>
                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <Input
                            value={EditableProvider.Education}
                            onChange={(e) =>
                              setEditableProvider({
                                ...EditableProvider,
                                Education: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <>{selectedProvider.Education}</>
                        )}
                      </Flex>
                    </Flex>
                  </div> */}
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={20}>
                        Education
                      </Text>
                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <Select
                            placeholder="Select"
                            id="Education"
                            name="Education"
                            value={EditableProvider.Education}
                            onChange={(e) =>
                              setEditableProvider({
                                ...EditableProvider,
                                Education: e.target.value,
                              })
                            }
                          >
                            {educationOptions.map((option) => (
                              <option key={option.idCode} value={option.idCode}>
                                {option.Description}
                              </option>
                            ))}
                          </Select>
                        ) : (
                          <>{selectedProvider.EducationDetail}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={20}>
                        Classification
                      </Text>
                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <Select
                            placeholder="Select"
                            id="Classification"
                            name="Classification"
                            value={EditableProvider.Classification}
                            onChange={(e) =>
                              setEditableProvider({
                                ...EditableProvider,
                                Classification: e.target.value,
                              })
                            }
                          >
                            {ClassificationOptions.map((option) => (
                              <option key={option.idCode} value={option.idCode}>
                                {option.Description}
                              </option>
                            ))}
                          </Select>
                        ) : (
                          <>{selectedProvider.ClassificationDesc}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={10}>
                        Keyword:
                      </Text>
                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <Input
                            value={EditableProvider.Keyword}
                            onChange={(e) =>
                              setEditableProvider({
                                ...EditableProvider,
                                Keyword: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <>{selectedProvider.Keyword}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>
                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={14}>
                        Indexs:
                      </Text>
                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <Input
                            type="number"
                            value={EditableProvider.Indexs}
                            onChange={(e) =>
                              setEditableProvider({
                                ...EditableProvider,
                                Indexs: parseInt(e.target.value),
                              })
                            }
                          />
                        ) : (
                          <>{selectedProvider.Indexs}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>
                  <div>
                    <Flex>
                      <Text fontSize="l" color="black" mr={10}>
                        Location:
                      </Text>

                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <Select
                            value={EditableProvider.idcity}
                            mb={4}
                            onChange={(e) =>
                              setEditableProvider({
                                ...EditableProvider,
                                idcity: e.target.value,
                              })
                            }
                          >
                            {citys.map((city) => (
                              <option key={city.idcity} value={city.idcity}>
                                {city.EName}
                              </option>
                            ))}
                          </Select>
                        ) : (
                          <>{selectedProvider.savecity}</>
                        )}
                      </Flex>
                    </Flex>
                  </div>

                  <div>
                    <Flex mb={2}>
                      <Text fontSize="l" color="black" mr={16}>
                        Point:
                      </Text>
                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <div>
                            <Input
                              placeholder="select location"
                              type="text"
                              value={editableLocation}
                              readOnly
                            />
                            <Map onPointSelect={handlePointSelect} />
                          </div>
                        ) : (
                          <>
                            {selectedProvider.Location
                              ? "available"
                              : "Not available"}
                          </>
                        )}
                      </Flex>
                    </Flex>
                  </div>
                  <div></div>
                  <Flex justify="center" mt="4" wrap="wrap">
                    <div>
                      <Text fontSize="l" color="black" mr={10}>
                        Profile:
                      </Text>
                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <Input
                            type="file"
                            accept="image/*"
                            onChange={(e) =>
                              handleFileChange(
                                e.target.files[0],
                                setProfileImage
                              )
                            }
                          />
                        ) : (
                          selectedProvider.profileImage &&
                          displayIconView(selectedProvider.profileImage)
                        )}
                      </Flex>

                      <Text fontSize="l" color="black" mr={10}>
                        Residence:
                      </Text>

                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <Input
                            type="file"
                            accept="image/*"
                            onChange={(e) =>
                              handleFileChange(e.target.files[0], setResidence)
                            }
                          />
                        ) : (
                          selectedProvider.residence &&
                          displayIconView(selectedProvider.residence)
                        )}
                      </Flex>
                    </div>
                    <div>
                      <Text fontSize="l" color="black" mr={10}>
                        Idfront:
                      </Text>
                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <Input
                            type="file"
                            accept="image/*"
                            onChange={(e) =>
                              handleFileChange(e.target.files[0], setIdFront)
                            }
                          />
                        ) : (
                          selectedProvider.idFront &&
                          displayIconView(selectedProvider.idFront)
                        )}
                      </Flex>
                      <Text fontSize="l" color="black" mr={10}>
                        Idback:
                      </Text>
                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <Input
                            type="file"
                            accept="image/*"
                            onChange={(e) =>
                              handleFileChange(e.target.files[0], setIdBack)
                            }
                          />
                        ) : (
                          selectedProvider.idBack &&
                          displayIconView(selectedProvider.idBack)
                        )}
                      </Flex>
                    </div>
                    <div>
                      <Text fontSize="l" color="black" mr={10}>
                        Passport:
                      </Text>
                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <Input
                            type="file"
                            accept="image/*"
                            onChange={(e) =>
                              handleFileChange(e.target.files[0], setPassport)
                            }
                          />
                        ) : (
                          selectedProvider.passport &&
                          displayIconView(selectedProvider.passport)
                        )}
                      </Flex>
                      <Text fontSize="l" color="black" mr={10}>
                        sejilAdel:
                      </Text>
                      <Flex>
                        {EditableProvider &&
                        EditableProvider.provID === selectedProvider.provID ? (
                          <Input
                            type="file"
                            accept="image/*"
                            onChange={(e) =>
                              handleFileChange(e.target.files[0], setsejilAdel)
                            }
                          />
                        ) : (
                          selectedProvider.sejilAdel &&
                          displayIconView(selectedProvider.sejilAdel)
                        )}
                      </Flex>
                    </div>

                    {/* Display other images in a similar manner */}
                  </Flex>
                  {/* Entry User */}
                  <div>
                    <Flex mb={4}>
                      <Text fontSize="l" color="black" mr={8}>
                        Entry User:
                      </Text>
                      {selectedProvider.entryUser}
                    </Flex>
                  </div>

                  {/* Entry Date */}
                  <div>
                    <Flex mb={4}>
                      <Text fontSize="l" color="black" mr={8}>
                        Entry Date:
                      </Text>
                      {selectedProvider.entryDate}
                    </Flex>
                  </div>

                  {/* Update User */}
                  <div>
                    <Flex mb={4}>
                      <Text fontSize="l" color="black" mr={5}>
                        Update User:
                      </Text>
                      {selectedProvider.UpdateUser}
                    </Flex>
                  </div>

                  {/* Update Date */}
                  <div>
                    <Flex mb={4}>
                      <Text fontSize="l" color="black" mr={5}>
                        Update Date:
                      </Text>
                      {selectedProvider.UpdateDate}
                    </Flex>
                  </div>
                </Flex>
                {/* Edit / Save / Cancel buttons */}
                {EditableProvider &&
                EditableProvider.provID === selectedProvider.provID ? (
                  <>
                    <Button
                      leftIcon={<MdDone />}
                      colorScheme="green"
                      size="sm"
                      onClick={handleSaveProvider}
                    >
                      Save
                    </Button>
                    <Button
                      leftIcon={<MdDelete />}
                      colorScheme="red"
                      ml={2}
                      size="sm"
                      onClick={() => setEditableProvider(null)}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      leftIcon={<MdEdit />}
                      colorScheme="blue"
                      size="sm"
                      onClick={() => handleEditProvider(selectedProvider)}
                    >
                      Edit
                    </Button>
                    <Button
                      leftIcon={<MdDelete />}
                      colorScheme="red"
                      size="sm"
                      ml={2}
                      onClick={handleCloseModal}
                    >
                      Close
                    </Button>
                  </>
                )}
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="teal" onClick={handleCloseModal}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Card>
  );
};
export default ListProvider;
