import React, { useState, useEffect } from "react";
import {
  ChakraProvider,
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
} from "@chakra-ui/react";
import fetchWithToken from "views/auth/signIn/axiosInstance";

const CreateKindForm = () => {
  const [description, setDescription] = useState("");
  const [entryUser, setEntryUser] = useState(null); // State to store the entryUser
  const [updateUser, setUpdateUser] = useState("");
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      // Split the token into header, payload, and signature
      const [header, payload, signature] = accessToken.split(".");
      // Decode the payload (Base64 decoded)
      const decodedPayload = JSON.parse(atob(payload));
      // Extract the username
      const entryUser = decodedPayload.clientId;
      // Set the entryUser in the state
      setEntryUser(entryUser);
      setUpdateUser(entryUser);
    } else {
      console.error("Access token not found.");
    }
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // const Description = description;
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/Kind`,
        "POST",
        JSON.stringify({ Description: description })
        // {
        //   method:,
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify({ Description:  }),
        // }
      );

      //const result = await response.json();
      //  console.log(result);

      // Reset the form or handle success accordingly
      setDescription("");
    } catch (error) {
      console.error("Error creating kind:", error);
    }
  };

  return (
    <ChakraProvider>
      <Box
        mt={10}
        boxShadow="0px 0px 10px rgba(0, 0, 255, 0.3)"
        p="8"
        borderRadius="md"
        bg="white"
        width={"l"}
      >
        <Heading mb={5}>Create Kind</Heading>
        <form onSubmit={handleSubmit}>
          <FormControl id="description" mb={3}>
            <FormLabel>Description</FormLabel>
            <Input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormControl>

          <Button type="submit" colorScheme="blue">
            Create Kind
          </Button>
        </form>
      </Box>
    </ChakraProvider>
  );
};

export default CreateKindForm;
