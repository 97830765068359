import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  Alert,
  AlertIcon,
  useColorModeValue,
  Spinner,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { motion } from "framer-motion";
import fetchWithToken from "views/auth/signIn/axiosInstance";
import Map from "views/map/map";

const CreateProvider = ({ onAddProvider, onFetchProviders }) => {
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [sex, setSex] = useState("");
  const [idFront, setIdFront] = useState(null);
  const [idBack, setIdBack] = useState(null);
  const [passport, setPassport] = useState(null);
  const [residence, setResidence] = useState(null);
  const [sejilAdel, setSejilAdel] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [description, setDescription] = useState("");
  const [providerUsername, setProviderUsername] = useState("");
  const [providerPass, setProviderPass] = useState("");
  const [homeLocation, setHomeLocation] = useState("");
  const [EntryUser, setentryUser] = useState("");
  const [UpdateUser, setupdateUser] = useState("");
  const [Verified, setverified] = useState("0");
  const [Type, setType] = useState("");
  const [Instagram, setinstagram] = useState("");
  const [Facebook, setfacebook] = useState("");
  const [LinkedIn, setlinkedIn] = useState("");
  const [Website, setwebsite] = useState("");
  const [Education, seteducation] = useState("");
  const [Indexs, setIndexs] = useState("");
  const [Classification, setClassification] = useState("");
  const [Keyword, setkeyword] = useState("");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  // const [providerData, setProviderData] = useState(initialValue);
  const [activeBullets, setActiveBullets] = useState({
    provider: true,
    address: false,
    profile: false,
    services: false,
    zone: false,
    hour: false,
    payment: false,
    project: false,
    cert: false,
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [Proerror, setProError] = useState("");
  const [cityIdOptions, setCityIdOptions] = useState([]);
  const [sexOptions, setSexOptions] = useState([]);
  const [ClassificationOptions, setClassificationOptions] = useState([]);
  const [educationOption, setEdcuationOptions] = useState([]);
  const [serviceId, setServiceId] = useState("");
  const [isSubmittingService, setIsSubmittingService] = useState(false);
  const [serviceError, setServiceError] = useState("");
  const [serviceSuccess, setServiceSuccess] = useState("");
  const [services, setServices] = useState([]);
  const [typeOption, setTypeOption] = useState([]);
  const [day, setDay] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [isSubmittingHours, setIsSubmittingHours] = useState(false);
  const [hoursError, setHoursError] = useState("");
  const [hoursSuccess, setHoursSuccess] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [amount, setAmount] = useState("");
  const [isPaymentSubmitting, setIsPaymentSubmitting] = useState(false);
  const [paymentError, setPaymentError] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState("");

  const [providers, setProviders] = useState([]);
  const [providerID, setProviderID] = useState("");
  const [isLoadingProviders, setIsLoadingProviders] = useState(false);
  const [fetchError, setFetchError] = useState("");

  const [zoneId, setZoneId] = useState("");
  const [isSubmittingZone, setIsSubmittingZone] = useState(false);
  const [zoneError, setZoneError] = useState("");
  const [zoneSuccess, setZoneSuccess] = useState("");
  const [zones, setZones] = useState([]);

  const [Point, setPoint] = useState([]);
  const [projectdesc, setprojectdesc] = useState("");
  const [isProjectSubmitting, setIsProjectSubmitting] = useState(false);
  const [projectError, setProjectError] = useState("");
  const [projectSuccess, setprojectSuccess] = useState("");
  const [image, setimage] = useState(null);
  const [image2, setimage2] = useState(null);
  const [image3, setimage3] = useState(null);
  const [image4, setimage4] = useState(null);
  const [image5, setimage5] = useState(null);

  const [certificationName, setcertificationName] = useState("");
  const [certificationDate, setcertificationDate] = useState("");
  const [isCertSubmitting, setIscertSubmitting] = useState(false);
  const [certError, setcertError] = useState("");
  const [certSuccess, setcertSuccess] = useState("");

  const providerTab = useRef();
  const addressTab = useRef();
  const profileTab = useRef();
  const servicesTab = useRef();
  const zoneTab = useRef();
  const workingHourTab = useRef();
  const paymentTab = useRef();
  const projectTab = useRef();
  const certTab = useRef();
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      const [header, payload, signature] = accessToken.split(".");
      const decodedPayload = JSON.parse(atob(payload));
      const entryUser = decodedPayload.clientId;
      setentryUser(entryUser);
      setupdateUser(entryUser);
    } else {
      console.error("Access token not found.");
    }
  }, []);

  useEffect(() => {
    const fetchCity = async () => {
      try {
        const cityResponse = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/city`
        );
        const cityData = cityResponse; //await .json();
        setCityIdOptions(
          cityData.map((city) => ({ id: city.idcity, EName: city.EName }))
        );
      } catch (error) {
        setError("Failed to fetch city");
      }
    };

    fetchCity();
  }, []);

  useEffect(() => {
    const fetchSex = async () => {
      try {
        const kindResponse = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/code`
        );
        const kindData = kindResponse; //await .json();
        const filteredSexCodes = kindResponse.filter(
          (code) => code.idCodeKind === "56647d21-82d5-4250-8e57-54d50e25ed5e"
        );
        const filteredClassCodes = kindResponse.filter(
          (code) => code.idCodeKind === "92f4e41a-cc9e-4071-bfde-96b0e9a268ea"
        );
        const filteredEduCodes = kindResponse.filter(
          (code) => code.idCodeKind === "6274fc85-b2fa-4f73-a7c6-d38c7d43a87e"
        );
        const filteredTypeCodes = kindResponse.filter(
          (code) => code.idCodeKind === "b951cdbe-ac58-4f5d-9470-2fa51901ed73"
        );
        setTypeOption(filteredTypeCodes);
        setEdcuationOptions(filteredEduCodes);
        setClassificationOptions(filteredClassCodes);
        setSexOptions(filteredSexCodes);
      } catch (error) {
        setError("Failed to fetch sex");
      }
    };
    fetchSex();
  }, []);
  // useEffect(() => {
  //   const fetchClassification = async () => {
  //     try {
  //       const kindResponse = await fetchWithToken(
  //         `${process.env.REACT_APP_API_URL}/api/code`
  //       );
  //       // const kindData = kindResponse; //await .json();
  //       // const sexKind = kindData.find(
  //       //   (kind) => kind.idCodeKind === "92f4e41a-cc9e-4071-bfde-96b0e9a268ea"
  //       // );
  //       // if (sexKind) {
  //       //   const codeResponse = await fetchWithToken(
  //       //     `${process.env.REACT_APP_API_URL}/api/code`
  //       //   );
  //       //const codes = codeResponse; //await .json();
  //       const filteredCodes = kindResponse.filter(
  //         (code) => code.idCodeKind === "92f4e41a-cc9e-4071-bfde-96b0e9a268ea"
  //       );
  //       setClassificationOptions(filteredCodes);
  //     } catch (error) {
  //       setError("Failed to fetch sex");
  //     }
  //   };
  //   fetchClassification();
  // }, []);
  // useEffect(() => {
  //   // 5/25/2024
  //   const fetchEducation = async () => {
  //     try {
  //       const kindResponse = await fetchWithToken(
  //         `${process.env.REACT_APP_API_URL}/api/code`
  //       );
  //       const kindData = kindResponse; //await .json();
  //       // const EdcuationKind = kindData.find(
  //       //   (kind) => kind.Description === "Education"
  //       // );
  //       // if (EdcuationKind) {
  //       //   const codeResponse = await fetchWithToken(
  //       //     `${process.env.REACT_APP_API_URL}/api/code`
  //       //   );
  //       //   const codes = codeResponse; //await .json();
  //       const filteredCodes = kindResponse.filter(
  //         (code) => code.idCodeKind === "6274fc85-b2fa-4f73-a7c6-d38c7d43a87e"
  //       );
  //       setEdcuationOptions(filteredCodes);
  //     } catch (error) {
  //       setError("Failed to fetch Edcuation");
  //     }
  //   };
  //   fetchEducation();
  // }, []);
  // useEffect(() => {
  //   const fetchType = async () => {
  //     try {
  //       const codeResponse = await fetchWithToken(
  //         `${process.env.REACT_APP_API_URL}/api/code`
  //       );
  //       const CodeData = codeResponse; //await .json();
  //       // const typeKind = CodeData.find((code) => code.idCodeKind === "b951cdbe-ac58-4f5d-9470-2fa51901ed73");
  //       //        const codes = typeKind; //await .json();
  //       //   const filteredCodes = codes.filter(
  //       //     (code) => code.idCodeKind === sexKind.idCodeKind
  //       //   );
  //       const filteredCodes = CodeData.filter(
  //         (code) => code.idCodeKind === "b951cdbe-ac58-4f5d-9470-2fa51901ed73"
  //       );
  //       setTypeOption(filteredCodes);
  //     } catch (error) {
  //       setError("Failed to fetch sex");
  //     }
  //   };
  //   fetchType();
  //}, []);
  useEffect(() => {
    const fetchZones = async () => {
      try {
        const response = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/zone`
        );

        if (response) {
          setError("");
          const data = response; //await .json();
          setZones(data);
          setError("");
        } else {
          const errorData = response; //await .json();
          setError(errorData.message || "Failed to fetch provider zones");
        }
      } catch (error) {
        setError("Failed to fetch provider zones. Please try again later.");
      }
    };

    fetchZones();
  }, []);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/service`
        ); // Adjust API endpoint as necessary
        const data = response; //await .json();
        if (response) {
          setServices(data);
        } else {
          throw new Error("Failed to retrieve services");
        }
      } catch (error) {
        console.error("Error fetching services:", error);
        setServiceError(error.message || "Failed to load services");
      }
    };

    fetchServices();
  }, []);

  useEffect(() => {
    const fetchProviders = async () => {
      setIsLoadingProviders(true);
      setFetchError("");
      try {
        const response = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/provider`
        );
        const data = response; //await .json();
        if (response) {
          setProviders(data);
        } else {
          throw new Error("Failed to retrieve providers");
        }
      } catch (error) {
        console.error("Error fetching providers:", error);
        setFetchError(error.message || "Failed to load providers");
      } finally {
        setIsLoadingProviders(false);
      }
    };

    fetchProviders();
  }, []);
  const fetchProviders = async () => {
    setIsLoadingProviders(true);
    setFetchError("");
    try {
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/provider`
      );
      if (response) {
        const data = response;
        setProviders(data);
      } else {
        throw new Error("Failed to retrieve providers");
      }
    } catch (error) {
      console.error("Error fetching providers:", error);
      setFetchError(error.message || "Failed to load providers");
    } finally {
      setIsLoadingProviders(false);
    }
  };
  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    // Basic validation for required fields
    const missingFields = [];

    if (!phoneNumber) missingFields.push("Phone Number");
    if (!providerUsername) missingFields.push("Provider Username");
    if (!providerPass) missingFields.push("Provider Password");
    if (!homeLocation) missingFields.push("Home Location");
    if (!EntryUser) missingFields.push("Entry User");
    if (!UpdateUser) missingFields.push("Update User");
    if (!Verified) missingFields.push("Verified");
    if (!Type) missingFields.push("Type");
    if (!Classification) missingFields.push("Classification");
    if (!idFront) missingFields.push("ID Front");
    if (!idBack) missingFields.push("ID Back");
    if (!sejilAdel) missingFields.push("Sejil Adel");
    if (!passport) missingFields.push("Passport");
    if (!residence) missingFields.push("Residence");
    if (!profileImage) missingFields.push("Profile Image");
    if (!description) missingFields.push("Description");
    if (!Point) missingFields.push("Location");
    if (!Indexs) missingFields.push("Indexs");

    if (missingFields.length > 0) {
      setProError(
        `Please fill in all required fields: ${missingFields.join(", ")}`
      );
      setSuccess(false); // Clear any previous success messages
      return;
    }

    try {
      const formData = new FormData();

      if (idFront) formData.append("idFront", idFront);
      if (idBack) formData.append("idBack", idBack);
      if (sejilAdel) formData.append("sejilAdel", sejilAdel);
      if (passport) formData.append("passport", passport);
      if (residence) formData.append("residence", residence);
      if (profileImage) formData.append("profileImage", profileImage);
      formData.append("phoneNumber", phoneNumber);
      formData.append("description", description);
      formData.append("providerUsername", providerUsername);
      formData.append("providerPass", providerPass);
      formData.append("homeLocation", homeLocation);
      formData.append("entryUser", EntryUser);
      formData.append("updateUser", UpdateUser);
      formData.append("Verified", Verified);
      formData.append("type", Type);
      formData.append("Instagram", Instagram);
      formData.append("FaceBook", Facebook);
      formData.append("LinkedIn", LinkedIn);
      formData.append("Website", Website);
      formData.append("Education", Education);
      formData.append("firstName", firstName);
      formData.append("middleName", middleName);
      formData.append("lastName", lastName);
      formData.append("sex", sex);
      formData.append("Location", Point);
      if (Indexs !== "") {
        formData.append("Indexs", Indexs);
      }
      formData.append("Classification", Classification);
      formData.append("Keyword", Keyword);
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/provider`,
        "POST",
        formData
      );

      if (response) {
        setProError("");
        setSuccess(true);
        const data = response; //await response.json();
        setProviderID(data.data);
        // console.log(data.data);
        fetchProviders();
        onAddProvider(data.data);
        onFetchProviders(); //setTimeout(() => servicesTab.current.click(), 1100);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
        setTimeout(() => servicesTab.current.click(), 1100);
      } else if (response.status === 400) {
        const errorData = response; //await response.json();
        // console.log(errorData);
        setProError(errorData.error || "Validation error occurred.");
        setSuccess(false);
      } else {
        const errorData = response; //await response.json();
        setSuccess(false);
        setProError(
          errorData.error || "An error occurred. Please try again later."
        );
      }
    } catch (error) {
      setProError(
        error.message || "An error occurred. Please try again later."
      );
      setSuccess(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleTabClick = (tab) => {
    setActiveBullets({
      provider: tab === "provider",
      address: tab === "address",
      profile: tab === "profile",
      services: tab === "services",
      zone: tab === "zone",
      hours: tab === "hours",
      payment: tab === "payment",
      project: tab === "project",
      cert: tab === "cert",
    });
  };

  const handleProviderService = async () => {
    setIsSubmittingService(true);
    try {
      const data = {
        provID: providerID,
        serviceId,
        EntryUser: EntryUser,
        UpdateUser: UpdateUser,
      };
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/providerservice`,
        "POST",
        data
        // {
        //   // Adjust URL as necessary
        //   method:
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify({
        //     provID: providerID,
        //     serviceId,
        //     EntryUser: EntryUser,
        //     UpdateUser: UpdateUser,
        //   }),
        // }
      );
      //const data = await response.json();
      if (response) {
        setServiceSuccess("Service added successfully.");
        setServiceId("");
        setServiceError("");
      } else {
        throw new Error(response.error || "Failed to add service");
      }
    } catch (error) {
      setServiceError(error.message);
    } finally {
      setIsSubmittingService(false);
    }
  };

  const handleAssignZone = async () => {
    setIsSubmittingZone(true);
    setZoneError("");
    try {
      const dt = {
        provId: providerID,
        zoneId,
        entryUser: EntryUser, // Assuming 'admin' is handling this
        updateUser: EntryUser,
      };
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/providerzone`,
        "POST",
        dt
        // {
        //   // Adjust URL as necessary
        //   method:
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify({
        //     provId: providerID,
        //     zoneId,
        //     entryUser: EntryUser, // Assuming 'admin' is handling this
        //     updateUser: EntryUser,
        //   }),
        // }
      );
      // const data = await response.json();
      if (response) {
        setZoneSuccess("Provider assigned to zone successfully.");
        setZoneId("");
      } else {
        throw new Error(response.error || "Failed to assign provider to zone");
      }
    } catch (error) {
      setZoneError(error.message);
    } finally {
      setIsSubmittingZone(false);
    }
  };

  const handlePaymentSubmit = async () => {
    setIsPaymentSubmitting(true);
    setPaymentError(""); // Clear any previous error messages
    try {
      const dataPayment = {
        provID: providerID,
        Amount: parseFloat(amount),
        EntryUser: EntryUser,
        updateUser: EntryUser,
      };
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/providerPay`,
        "POST",
        dataPayment
        // {
        //   method:
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify({
        //     provID: providerID,
        //     Amount: parseFloat(amount),
        //     EntryUser: EntryUser,
        //     updateUser: EntryUser,
        //   }),
        // }
      );
      const data = response; //await .json();
      if (response) {
        setPaymentSuccess("Payment has been successfully processed."); // Display success message
        setAmount("");
      } else {
        throw new Error(data.error || "Failed to process payment");
      }
    } catch (error) {
      setPaymentError(error.message); // Set error message from catch block
    } finally {
      setIsPaymentSubmitting(false);
    }
  };
  const handleCertSubmit = async () => {
    console.log(0);
    setIscertSubmitting(true);
    setcertError(""); // Clear any previous error messages
    try {
      const dataPayment = {
        provID: providerID,
        certificationName: certificationName,
        certificationDate: certificationDate,
        EntryUser: EntryUser,
        UpdateUser: EntryUser,
      };
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/providerCert`,
        "POST",
        dataPayment
      );
      const data = response; //await .json();
      if (response) {
        setcertSuccess("certification has been successfully processed."); // Display success message
        setcertificationName("");
        setcertificationDate("");
      } else {
        throw new Error(data.error || "Failed to process payment");
      }
    } catch (error) {
      setcertError(error.message); // Set error message from catch block
    } finally {
      setIscertSubmitting(false);
    }
  };
  const handleAddWorkingHours = async () => {
    setIsSubmittingHours(true);
    try {
      const a = {
        provID: providerID,
        Day: day,
        start,
        end,
        EntryUser: EntryUser, // Example user
        UpdateUser: EntryUser, // Example user
      };
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/providerWorkingHr`,
        "POST",
        a
      );
      //
      // {
      //   // Adjust URL as necessary
      //   method:
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({
      //     provID: providerID,
      //     Day: day,
      //     start,
      //     end,
      //     EntryUser: EntryUser, // Example user
      //     UpdateUser: EntryUser, // Example user
      //   }),
      // }
      const data = response; //await .json();
      if (response) {
        setHoursSuccess("Working hours added successfully.");
        setDay("");
        setStart("");
        setEnd("");
      } else {
        throw new Error(data.error || "Failed to add working hours");
      }
    } catch (error) {
      setHoursError(error.message);
    } finally {
      setIsSubmittingHours(false);
    }
  };
  const handleAddProject = async () => {
    setIsProjectSubmitting(true);
    try {
      const formData = new FormData();

      if (image) formData.append("image", image);
      if (image2) formData.append("image2", image2);
      if (image3) formData.append("image3", image3);
      if (image4) formData.append("image4", image4);
      if (image5) formData.append("image5", image5);

      formData.append("provID", providerID);
      formData.append("projectDesc", projectdesc);
      formData.append("EntryUser", EntryUser);
      formData.append("UpdateUser", EntryUser);
      //console.log(formData);
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/ProvProject`,
        "POST",
        formData
      );
      //
      // {
      //   // Adjust URL as necessary
      //   method:
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({
      //     provID: providerID,
      //     Day: day,
      //     start,
      //     end,
      //     EntryUser: EntryUser, // Example user
      //     UpdateUser: EntryUser, // Example user
      //   }),
      // }
      const data = response; //await .json();
      if (response) {
        setprojectSuccess("Project added successfully.");
        setprojectdesc("");
        setimage(null);
        setimage2(null);
        setimage3(null);
        setimage4(null);
        setimage4(null);
      } else {
        throw new Error(data.error || "Failed to add working hours");
      }
    } catch (error) {
      setProjectError(error.message);
    } finally {
      setIsProjectSubmitting(false);
    }
  };
  const handlePointSelect = (point) => {
    // Update the input field value with the selected point
    setPoint(`${point.lat.toFixed(6)} ${point.lng.toFixed(6)}`);
  };
  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 0.5 }}
    >
      <Flex
        direction="column"
        minH="100vh"
        align="center"
        pt={{ sm: "125px", lg: "75px" }}
        position="relative"
      >
        <Box
          h="45vh"
          bgGradient="linear(to-b, blue.400, brand.600)"
          position="absolute"
          w="105%"
          borderRadius="20px"
        />

        <Tabs
          variant="unstyled"
          zIndex="0"
          mt={{ base: "60px", md: "165px" }}
          display="flex"
          flexDirection="column"
        >
          <TabList
            display="flex"
            alignItems="center"
            alignSelf="center"
            justifySelf="center"
          >
            <Tab
              _focus={{ border: "0px", boxShadow: "unset" }}
              ref={providerTab}
              w={{ sm: "80px", md: "100px", lg: "150px" }}
              onClick={() => handleTabClick("provider")}
              position="relative"
            >
              <Flex direction="column" justify="center" align="center">
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.provider ? "white" : "blue.400"}
                  bgGradient="linear(to-b, blue.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />

                <Text
                  color={activeBullets.provider ? "white" : "gray.300"}
                  fontWeight={activeBullets.provider ? "bold" : "normal"}
                  display={{ sm: "none", md: "block" }}
                >
                  provider Info
                </Text>
                {/* Underline Effect */}
                {activeBullets.provider && (
                  <Box
                    _before={{
                      content: "''",
                      width: "100%",
                      height: "3px",
                      bg: "white",
                      position: "absolute",
                      bottom: "0",
                      left: "0",
                    }}
                  />
                )}
              </Flex>
            </Tab>
            <Tab
              _focus={{ border: "0px", boxShadow: "unset" }}
              ref={addressTab}
              w={{ sm: "80px", md: "100px", lg: "150px" }}
              onClick={() => handleTabClick("address")}
              position="relative"
            >
              <Flex direction="column" justify="center" align="center">
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.address ? "white" : "blue.400"}
                  bgGradient="linear(to-b, blue.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.address ? "white" : "gray.300"}
                  fontWeight={activeBullets.address ? "bold" : "normal"}
                  display={{ sm: "none", md: "block" }}
                >
                  Address
                </Text>
                {/* Underline Effect */}
                {activeBullets.address && (
                  <Box
                    _before={{
                      content: "''",
                      width: "100%",
                      height: "3px",
                      bg: "white",
                      position: "absolute",
                      bottom: "0",
                      left: "0",
                    }}
                  />
                )}
              </Flex>
            </Tab>
            <Tab
              _focus={{ border: "0px", boxShadow: "unset" }}
              ref={profileTab}
              w={{ sm: "80px", md: "100px", lg: "150px" }}
              onClick={() => handleTabClick("profile")}
              position="relative"
            >
              <Flex direction="column" justify="center" align="center">
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.profile ? "white" : "blue.400"}
                  bgGradient="linear(to-b, blue.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.profile ? "white" : "gray.300"}
                  fontWeight={activeBullets.profile ? "bold" : "normal"}
                  display={{ sm: "none", md: "block" }}
                >
                  Profile
                </Text>
                {/* Underline Effect */}
                {activeBullets.profile && (
                  <Box
                    _before={{
                      content: "''",
                      width: "100%",
                      height: "3px",
                      bg: "white",
                      position: "absolute",
                      bottom: "0",
                      left: "0",
                    }}
                  />
                )}
              </Flex>
            </Tab>
            <Tab
              _focus={{ border: "0px", boxShadow: "unset" }}
              ref={servicesTab}
              w={{ sm: "80px", md: "100px", lg: "150px" }}
              onClick={() => handleTabClick("services")}
              position="relative"
            >
              <Flex direction="column" justify="center" align="center">
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.services ? "white" : "blue.400"}
                  bgGradient="linear(to-b, blue.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.services ? "white" : "gray.300"}
                  fontWeight={activeBullets.services ? "bold" : "normal"}
                  display={{ sm: "none", md: "block" }}
                >
                  Services
                </Text>
                {/* Underline Effect */}
                {activeBullets.services && (
                  <Box
                    _before={{
                      content: "''",
                      width: "100%",
                      height: "3px",
                      bg: "white",
                      position: "absolute",
                      bottom: "0",
                      left: "0",
                    }}
                  />
                )}
              </Flex>
            </Tab>
            <Tab
              _focus={{ border: "0px", boxShadow: "unset" }}
              ref={zoneTab}
              w={{ sm: "80px", md: "100px", lg: "150px" }}
              onClick={() => handleTabClick("zone")}
              position="relative"
            >
              <Flex direction="column" justify="center" align="center">
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.zone ? "white" : "blue.400"}
                  bgGradient="linear(to-b, blue.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.zone ? "white" : "gray.300"}
                  fontWeight={activeBullets.zone ? "bold" : "normal"}
                  display={{ sm: "none", md: "block" }}
                >
                  Zone
                </Text>
                {/* Underline Effect */}
                {activeBullets.zone && (
                  <Box
                    _before={{
                      content: "''",
                      width: "100%",
                      height: "3px",
                      bg: "white",
                      position: "absolute",
                      bottom: "0",
                      left: "0",
                    }}
                  />
                )}
              </Flex>
            </Tab>
            <Tab
              _focus={{ border: "0px", boxShadow: "unset" }}
              ref={workingHourTab}
              w={{ sm: "80px", md: "100px", lg: "150px" }}
              onClick={() => handleTabClick("hours")}
              position="relative"
            >
              <Flex direction="column" justify="center" align="center">
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.hours ? "white" : "blue.400"} // Change to 'hours'
                  bgGradient="linear(to-b, blue.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.hours ? "white" : "gray.300"} // Change to 'hours'
                  fontWeight={activeBullets.hours ? "bold" : "normal"} // Change to 'hours'
                  display={{ sm: "none", md: "block" }}
                >
                  Working Hours
                </Text>
                {activeBullets.hours && (
                  <Box
                    _before={{
                      content: "''",
                      width: "100%",
                      height: "3px",
                      bg: "white",
                      position: "absolute",
                      bottom: "0",
                      left: "0",
                    }}
                  />
                )}
              </Flex>
            </Tab>
            <Tab
              _focus={{ border: "0px", boxShadow: "unset" }}
              ref={paymentTab}
              w={{ sm: "80px", md: "100px", lg: "150px" }}
              onClick={() => handleTabClick("payment")}
              position="relative"
            >
              <Flex direction="column" justify="center" align="center">
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.payment ? "white" : "blue.400"}
                  bgGradient="linear(to-b, blue.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.payment ? "white" : "gray.300"}
                  fontWeight={activeBullets.payment ? "bold" : "normal"}
                  display={{ sm: "none", md: "block" }}
                >
                  Payment
                </Text>
                {activeBullets.payment && (
                  <Box
                    _before={{
                      content: "''",
                      width: "100%",
                      height: "3px",
                      bg: "white",
                      position: "absolute",
                      bottom: "0",
                      left: "0",
                    }}
                  />
                )}
              </Flex>
            </Tab>
            <Tab
              _focus={{ border: "0px", boxShadow: "unset" }}
              ref={projectTab}
              w={{ sm: "80px", md: "100px", lg: "150px" }}
              onClick={() => handleTabClick("project")}
              position="relative"
            >
              <Flex direction="column" justify="center" align="center">
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.project ? "white" : "blue.400"}
                  bgGradient="linear(to-b, blue.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.project ? "white" : "gray.300"}
                  fontWeight={activeBullets.project ? "bold" : "normal"}
                  display={{ sm: "none", md: "block" }}
                >
                  Project
                </Text>
                {activeBullets.project && (
                  <Box
                    _before={{
                      content: "''",
                      width: "100%",
                      height: "3px",
                      bg: "white",
                      position: "absolute",
                      bottom: "0",
                      left: "0",
                    }}
                  />
                )}
              </Flex>
            </Tab>
            <Tab
              _focus={{ border: "0px", boxShadow: "unset" }}
              ref={certTab}
              w={{ sm: "80px", md: "100px", lg: "150px" }}
              onClick={() => handleTabClick("cert")}
              position="relative"
            >
              <Flex direction="column" justify="center" align="center">
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.cert ? "white" : "blue.400"}
                  bgGradient="linear(to-b, blue.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.cert ? "white" : "gray.300"}
                  fontWeight={activeBullets.cert ? "bold" : "normal"}
                  display={{ sm: "none", md: "block" }}
                >
                  Certification
                </Text>
                {activeBullets.cert && (
                  <Box
                    _before={{
                      content: "''",
                      width: "100%",
                      height: "3px",
                      bg: "white",
                      position: "absolute",
                      bottom: "0",
                      left: "0",
                    }}
                  />
                )}
              </Flex>
            </Tab>
          </TabList>
          <TabPanels mt="24px" maxW={{ md: "90%", lg: "100%" }} mx="auto">
            <TabPanel
              w={{ sm: "330px", md: "700px", lg: "850px" }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text
                  color={textColor}
                  fontSize="2xl"
                  fontWeight="700"
                  mb="20px"
                >
                  provider Info
                </Text>

                <Flex direction="column" w="100%">
                  <Flex direction="row" justify="space-between" mb="20px">
                    <Box w={"30%"} mb="20px">
                      <Text fontSize="sm" color="gray.500" mb="2">
                        First Name
                      </Text>
                      <Input
                        mb="0px"
                        id="firstName"
                        placeholder="eg. John"
                        name="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                    </Box>
                    <Box w={"30%"} mb="20px">
                      <Text fontSize="sm" color="gray.500" mb="2">
                        Middle Name
                      </Text>
                      <Input
                        mb="0px"
                        id="middleName"
                        placeholder="eg. Doe"
                        name="middleName"
                        value={middleName}
                        onChange={(e) => setMiddleName(e.target.value)}
                      />
                    </Box>
                    <Box w={"30%"} mb="20px">
                      <Text fontSize="sm" color="gray.500" mb="2">
                        Last Name
                      </Text>
                      <Input
                        mb="0px"
                        id="lastName"
                        placeholder="eg. Smith"
                        name="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                    </Box>
                  </Flex>

                  <Flex direction="row" justify="space-between" mb="20px">
                    <Box w={"30%"} mb="20px">
                      <Text fontSize="sm" color="gray.500" mb="2">
                        Sex
                      </Text>
                      <Select
                        placeholder="Select"
                        id="sex"
                        name="sex"
                        value={sex}
                        onChange={(e) => setSex(e.target.value)}
                        required
                      >
                        {sexOptions.map((option) => (
                          <option key={option.idCode} value={option.idCode}>
                            {option.Description}
                          </option>
                        ))}
                      </Select>
                    </Box>
                    <Box w={"30%"} mb="20px">
                      <Text fontSize="sm" color="gray.500" mb="2">
                        Phone Number
                      </Text>
                      <Input
                        mb="0px"
                        id="phoneNumber"
                        placeholder="03000000"
                        name="phoneNumber"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        required
                      />
                    </Box>
                    <Box w={"30%"} mb="20px">
                      <Text fontSize="sm" color="gray.500" mb="2">
                        Description
                      </Text>
                      <Textarea
                        mb="0px"
                        id="description"
                        name="description"
                        placeholder="Enter description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                      />
                    </Box>
                  </Flex>

                  <Flex
                    direction="row"
                    justifyContent="space-between"
                    mb="20px"
                  >
                    <Box w={"30%"} mb="20px">
                      <Text fontSize="sm" color="gray.500" mb="2">
                        Username
                      </Text>
                      <Input
                        mb="0px"
                        id="providerUsername"
                        placeholder="EZ_122"
                        name="providerUsername"
                        value={providerUsername}
                        onChange={(e) => setProviderUsername(e.target.value)}
                        required
                      />
                    </Box>
                    <Box w={"30%"} mb="20px">
                      <Text fontSize="sm" color="gray.500" mb="2">
                        Password
                      </Text>
                      <Input
                        mb="0px"
                        type="password" // Assuming it's a password input for Provider Password
                        id="providerPass"
                        name="providerPass"
                        value={providerPass}
                        onChange={(e) => setProviderPass(e.target.value)}
                        required
                      />
                    </Box>
                    <Box w={"30%"} mb="20px">
                      <Text fontSize="sm" color="gray.500" mb="2">
                        Verification
                      </Text>
                      <Select
                        id="Verified"
                        name="Verified"
                        value={Verified}
                        onChange={(e) => setverified(e.target.value)}
                        required
                      >
                        <option value="1">Verified</option>
                        <option value="0">Not Verified</option>
                      </Select>
                    </Box>
                  </Flex>
                </Flex>
                <Flex justify="space-between" mt="24px">
                  <Button
                    colorScheme="blue"
                    fontSize="m"
                    borderRadius="16px"
                    w={{ base: "128px", md: "148px" }}
                    h="46px"
                    ms="auto"
                    onClick={() => addressTab.current.click()}
                  >
                    Next
                  </Button>
                </Flex>
              </Card>
            </TabPanel>
            <TabPanel
              w={{ sm: "330px", md: "700px", lg: "850px" }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text
                  color={textColor}
                  fontSize="2xl"
                  fontWeight="700"
                  mb="20px"
                >
                  Address
                </Text>
                <Flex direction="column" w="100%">
                  <Box mb="20px">
                    <Text fontSize="sm" color="gray.500" mb="2">
                      Home
                    </Text>
                    <Select
                      variant="filled"
                      placeholder="Select"
                      value={homeLocation}
                      onChange={(e) => setHomeLocation(e.target.value)}
                      mb={4}
                      required
                    >
                      {cityIdOptions.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.EName}
                        </option>
                      ))}
                    </Select>
                  </Box>
                  <Box mb="20px">
                    <Input
                      placeholder="select Point"
                      type="text"
                      value={Point}
                      readOnly
                    />
                    <Map onPointSelect={handlePointSelect} />
                  </Box>
                  <Box mb="20px">
                    <Text fontSize="sm" color="gray.500" mb="2">
                      Instagram
                    </Text>
                    <Input
                      mb="0px"
                      type="text"
                      id="Instagram"
                      name="Instagram"
                      value={Instagram}
                      onChange={(e) => setinstagram(e.target.value)}
                    />
                  </Box>
                  <Box mb="20px">
                    <Text fontSize="sm" color="gray.500" mb="2">
                      Facebook
                    </Text>
                    <Input
                      mb="0px"
                      type="text"
                      id="Facebook"
                      name="Facebook"
                      value={Facebook}
                      onChange={(e) => setfacebook(e.target.value)}
                    />
                  </Box>
                  <Box mb="20px">
                    <Text fontSize="sm" color="gray.500" mb="2">
                      LinkedIn
                    </Text>
                    <Input
                      mb="0px"
                      type="text"
                      id="LinkedIn"
                      name="LinkedIn"
                      value={LinkedIn}
                      onChange={(e) => setlinkedIn(e.target.value)}
                    />
                  </Box>
                  <Box mb="20px">
                    <Text fontSize="sm" color="gray.500" mb="2">
                      Website
                    </Text>
                    <Input
                      mb="0px"
                      type="text"
                      id="Website"
                      name="Website"
                      value={Website}
                      onChange={(e) => setwebsite(e.target.value)}
                    />
                  </Box>
                  <Box mb="20px">
                    <Text fontSize="sm" color="gray.500" mb="2">
                      residence
                    </Text>
                    <Input
                      mb="0px"
                      id="residence"
                      type="file"
                      accept="image/*"
                      name="residence"
                      onChange={(e) => setResidence(e.target.files[0])}
                    />
                  </Box>
                </Flex>
                <Flex justify="space-between">
                  <Button
                    variant="light"
                    fontSize="m"
                    borderRadius="16px"
                    w={{ base: "128px", md: "148px" }}
                    h="46px"
                    onClick={() => providerTab.current.click()}
                  >
                    Prev
                  </Button>
                  <Button
                    colorScheme="blue"
                    fontSize="m"
                    borderRadius="16px"
                    w={{ base: "128px", md: "148px" }}
                    h="46px"
                    ms="auto"
                    onClick={() => profileTab.current.click()}
                  >
                    Next
                  </Button>
                </Flex>
              </Card>
            </TabPanel>
            <TabPanel
              w={{ sm: "330px", md: "700px", lg: "850px" }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                {Proerror && (
                  <Alert status="error" mb="4">
                    <AlertIcon />
                    {Proerror}
                  </Alert>
                )}
                {success && (
                  <Alert status="success" mb="4">
                    <AlertIcon />
                    Provider created successfully!
                  </Alert>
                )}
                <Text
                  color={textColor}
                  fontSize="2xl"
                  fontWeight="700"
                  mb="20px"
                >
                  Profile
                </Text>

                <Flex justify="space-between" direction="column" mt="24px">
                  <Box mb="20px">
                    <Text fontSize="sm" color="gray.500" mb="2">
                      profileImage
                    </Text>
                    <Input
                      mb="0px"
                      id="profileIamge"
                      type="file"
                      accept="image/*"
                      name="profileImage"
                      onChange={(e) => setProfileImage(e.target.files[0])}
                    />
                  </Box>
                  <Box mb="20px">
                    <Text fontSize="sm" color="gray.500" mb="2">
                      ID Front Image
                    </Text>
                    <Input
                      mb="0px"
                      id="idFront"
                      type="file"
                      accept="image/*"
                      name="idFront"
                      onChange={(e) => setIdFront(e.target.files[0])}
                    />
                  </Box>
                  <Box mb="20px">
                    <Text fontSize="sm" color="gray.500" mb="2">
                      ID Back Image
                    </Text>
                    <Input
                      mb="0px"
                      id=" idBack"
                      type="file"
                      accept="image/*"
                      name=" idBack"
                      onChange={(e) => setIdBack(e.target.files[0])}
                    />
                  </Box>
                  <Box mb="20px">
                    <Text fontSize="sm" color="gray.500" mb="2">
                      Criminal Record
                    </Text>
                    <Input
                      mb="0px"
                      id="sejilAdel"
                      type="file"
                      accept="image/*"
                      name="sejilAdel"
                      onChange={(e) => setSejilAdel(e.target.files[0])}
                    />
                  </Box>
                  <Box mb="20px">
                    <Text fontSize="sm" color="gray.500" mb="2">
                      Passport
                    </Text>
                    <Input
                      mb="0px"
                      id="passport"
                      type="file"
                      accept="image/*"
                      name=""
                      onChange={(e) => setPassport(e.target.files[0])}
                    />
                  </Box>
                  <Box mb="20px">
                    <Text fontSize="sm" color="gray.500" mb="2">
                      Education
                    </Text>
                    {/* <Input
                      mb="0px"
                      type="text"
                      id="Education"
                      name="Education"
                      value={Education}
                      onChange={(e) => seteducation(e.target.value)}
                    /> */}
                    <Select
                      placeholder="Select"
                      id="Education"
                      name="Education"
                      value={Education}
                      onChange={(e) => seteducation(e.target.value)}
                    >
                      {educationOption.map((option) => (
                        <option key={option.idCode} value={option.idCode}>
                          {option.Description}
                        </option>
                      ))}
                    </Select>
                  </Box>
                  <Box mb="20px">
                    <Text fontSize="sm" color="gray.500" mb="2">
                      Classification
                    </Text>

                    <Select
                      placeholder="Select"
                      id="Classification"
                      name="Classification"
                      value={Classification}
                      onChange={(e) => setClassification(e.target.value)}
                    >
                      {ClassificationOptions.map((option) => (
                        <option key={option.idCode} value={option.idCode}>
                          {option.Description}
                        </option>
                      ))}
                    </Select>
                  </Box>
                  <Box mb="20px">
                    <Text fontSize="sm" color="gray.500" mb="2">
                      Type
                    </Text>

                    <Select
                      placeholder="Select"
                      id="Type"
                      name="Type"
                      value={Type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      {typeOption.map((option) => (
                        <option
                          key={option.Description}
                          value={option.Description}
                        >
                          {option.Description}
                        </option>
                      ))}
                    </Select>
                  </Box>
                  <Box mb="10px">
                    <Text fontSize="sm" color="gray.500" mb="2">
                      Indexs
                    </Text>
                    <Input
                      mb="0px"
                      type="number"
                      id="Indexs"
                      name="Indexs"
                      value={Indexs}
                      onChange={(e) => setIndexs(e.target.value)}
                    />
                  </Box>
                  <Box mb="10px">
                    <Text fontSize="sm" color="gray.500" mb="2">
                      Keyword
                    </Text>
                    <Input
                      mb="0px"
                      type="text"
                      id="Keyword"
                      name="Keyword"
                      value={Keyword}
                      onChange={(e) => setkeyword(e.target.value)}
                    />
                  </Box>
                  <Button
                    colorScheme="blue"
                    fontSize="m"
                    borderRadius="16px"
                    w={{ base: "128px", md: "148px" }}
                    h="46px"
                    onClick={async (event) => {
                      try {
                        await handleSubmit(event);

                        if (success) {
                          // setTimeout(() => {
                          //   window.location.reload();
                          // }, 1000);
                          //  setTimeout(() => servicesTab.current.click(), 1100);
                        }
                      } catch (error) {
                        console.error(
                          "Error occurred while submitting form:",
                          error
                        );
                      }
                    }}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <Spinner size="sm" /> : "Submit"}
                  </Button>
                </Flex>
              </Card>
            </TabPanel>
            <TabPanel
              w={{ sm: "330px", md: "700px", lg: "850px" }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text
                  color={textColor}
                  fontSize="2xl"
                  fontWeight="700"
                  mt={"0"}
                  mb="20px"
                >
                  Provider Services
                </Text>
                <Select
                  placeholder="Select a provider"
                  value={providerID}
                  onChange={(e) => setProviderID(e.target.value)}
                  mb="4"
                >
                  {providers.map((provider) => (
                    <option key={provider.provID} value={provider.provID}>
                      {provider.providerUsername}
                    </option>
                  ))}
                </Select>
                <Select
                  placeholder="Select"
                  onChange={(e) => setServiceId(e.target.value)}
                  value={serviceId}
                >
                  {services.map((service) => (
                    <option key={service.serviceId} value={service.serviceId}>
                      {service.serviceNameEng}
                    </option>
                  ))}
                </Select>
                <Flex direction="row">
                  <Button
                    colorScheme="blue"
                    fontSize="m"
                    borderRadius="16px"
                    w={{ base: "128px", md: "148px" }}
                    h="46px"
                    ms="0"
                    mt={"4"}
                    onClick={() => zoneTab.current.click()}
                  >
                    Next
                  </Button>
                  <Button
                    colorScheme="blue"
                    fontSize="m"
                    w={{ base: "128px", md: "148px" }}
                    h="46px"
                    ms="auto"
                    mt={"4"}
                    onClick={handleProviderService}
                  >
                    Submit
                  </Button>
                </Flex>
                {serviceError && <Text color="red">{serviceError}</Text>}
                {serviceSuccess && <Text color="green">{serviceSuccess}</Text>}
              </Card>
            </TabPanel>
            <TabPanel
              w={{ sm: "330px", md: "700px", lg: "850px" }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text
                  color={textColor}
                  fontSize="2xl"
                  fontWeight="700"
                  mb="20px"
                >
                  Assign Provider to Zone
                </Text>
                <Select
                  placeholder="Select a provider"
                  value={providerID}
                  onChange={(e) => setProviderID(e.target.value)}
                  mb="4"
                >
                  {providers.map((provider) => (
                    <option key={provider.provID} value={provider.provID}>
                      {provider.providerUsername}
                    </option>
                  ))}
                </Select>
                <Select
                  placeholder="Select zone"
                  value={zoneId}
                  onChange={(e) => setZoneId(e.target.value)}
                  mb="4"
                >
                  {zones.map((zone) => (
                    <option key={zone.zoneId} value={zone.zoneId}>
                      {zone.nameEng}
                    </option>
                  ))}
                </Select>
                <Button
                  colorScheme="blue"
                  onClick={handleAssignZone}
                  isLoading={isSubmittingZone}
                >
                  Assign Zone
                </Button>
                {zoneError && <Text color="red">{zoneError}</Text>}
                {zoneSuccess && <Text color="green">{zoneSuccess}</Text>}
              </Card>
            </TabPanel>

            <TabPanel
              w={{ sm: "330px", md: "700px", lg: "850px" }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text
                  color={textColor}
                  fontSize="2xl"
                  fontWeight="700"
                  mb="20px"
                >
                  Add Working Hours
                </Text>
                <Select
                  placeholder="Select a provider"
                  value={providerID}
                  onChange={(e) => setProviderID(e.target.value)}
                  mb="4"
                >
                  {providers.map((provider) => (
                    <option key={provider.provID} value={provider.provID}>
                      {provider.providerUsername}{" "}
                      {/* Assuming 'username' is the field you want to display */}
                    </option>
                  ))}
                </Select>
                <Select
                  placeholder="Select day"
                  value={day}
                  onChange={(e) => setDay(e.target.value)}
                  mb="4"
                >
                  {[
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                  ].map((d) => (
                    <option key={d} value={d}>
                      {d}
                    </option>
                  ))}
                </Select>
                <Input
                  placeholder="Start Time"
                  type="time"
                  value={start}
                  onChange={(e) => setStart(e.target.value)}
                  mb="4"
                />
                <Input
                  placeholder="End Time"
                  type="time"
                  value={end}
                  onChange={(e) => setEnd(e.target.value)}
                  mb="4"
                />
                <Button
                  colorScheme="blue"
                  onClick={handleAddWorkingHours}
                  isLoading={isSubmittingHours}
                >
                  Add Working Hours
                </Button>
                {hoursError && <Text color="red">{hoursError}</Text>}
                {hoursSuccess && <Text color="green">{hoursSuccess}</Text>}
              </Card>
            </TabPanel>

            <TabPanel
              w={{ sm: "330px", md: "700px", lg: "850px" }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text
                  color={textColor}
                  fontSize="2xl"
                  fontWeight="700"
                  mb="20px"
                >
                  Payment Details
                </Text>
                {isLoadingProviders ? (
                  <Text>Loading providers...</Text>
                ) : fetchError ? (
                  <Text color="red">{fetchError}</Text>
                ) : (
                  <Select
                    placeholder="Select a provider"
                    value={providerID}
                    onChange={(e) => setProviderID(e.target.value)}
                  >
                    {providers.map((provider) => (
                      <option key={provider.provID} value={provider.provID}>
                        {provider.providerUsername}
                      </option>
                    ))}
                  </Select>
                )}
                <Input
                  placeholder="Amount"
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  mt="20px"
                />
                <Button
                  colorScheme="blue"
                  mt="20px"
                  onClick={handlePaymentSubmit}
                  isLoading={isPaymentSubmitting}
                  isDisabled={!providerID || !amount}
                >
                  Submit Payment
                </Button>
                {paymentError && <Text color="red">{paymentError}</Text>}
                {paymentSuccess && <Text color="green">{paymentSuccess}</Text>}
              </Card>
            </TabPanel>
            <TabPanel
              w={{ sm: "330px", md: "700px", lg: "850px" }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text
                  color={textColor}
                  fontSize="2xl"
                  fontWeight="700"
                  mb="20px"
                >
                  Project Details
                </Text>
                {isLoadingProviders ? (
                  <Text>Loading providers...</Text>
                ) : fetchError ? (
                  <Text color="red">{fetchError}</Text>
                ) : (
                  <Select
                    placeholder="Select a provider"
                    value={providerID}
                    onChange={(e) => setProviderID(e.target.value)}
                  >
                    {providers.map((provider) => (
                      <option key={provider.provID} value={provider.provID}>
                        {provider.providerUsername}
                      </option>
                    ))}
                  </Select>
                )}

                <Textarea
                  mb="0px"
                  id="projectdesc"
                  name="projectdesc"
                  placeholder="Enter description"
                  value={projectdesc}
                  onChange={(e) => setprojectdesc(e.target.value)}
                  required
                />
                <Box mb="20px">
                  <Text fontSize="sm" color="gray.500" mb="2">
                    Image
                  </Text>
                  <Input
                    mb="0px"
                    id="image"
                    type="file"
                    accept="image/*"
                    name="image"
                    onChange={(e) => setimage(e.target.files[0])}
                  />
                </Box>
                <Box mb="20px">
                  <Text fontSize="sm" color="gray.500" mb="2">
                    Image2
                  </Text>
                  <Input
                    mb="0px"
                    id="image2"
                    type="file"
                    accept="image/*"
                    name="image2"
                    onChange={(e) => setimage2(e.target.files[0])}
                  />
                </Box>
                <Box mb="20px">
                  <Text fontSize="sm" color="gray.500" mb="2">
                    Image3
                  </Text>
                  <Input
                    mb="0px"
                    id="image3"
                    type="file"
                    accept="image/*"
                    name="image3"
                    onChange={(e) => setimage3(e.target.files[0])}
                  />
                </Box>
                <Box mb="20px">
                  <Text fontSize="sm" color="gray.500" mb="2">
                    Image4
                  </Text>
                  <Input
                    mb="0px"
                    id="image"
                    type="file"
                    accept="image/*"
                    name="image4"
                    onChange={(e) => setimage4(e.target.files[0])}
                  />
                </Box>
                <Box mb="20px">
                  <Text fontSize="sm" color="gray.500" mb="2">
                    Image5
                  </Text>
                  <Input
                    mb="0px"
                    id="image5"
                    type="file"
                    accept="image/*"
                    name="image5"
                    onChange={(e) => setimage5(e.target.files[0])}
                  />
                </Box>
                <Button
                  colorScheme="blue"
                  mt="20px"
                  onClick={handleAddProject}
                  isLoading={isProjectSubmitting}
                  isDisabled={!providerID || !projectdesc}
                >
                  Submit project
                </Button>
                {projectError && <Text color="red">{projectError}</Text>}
                {projectSuccess && <Text color="green">{projectSuccess}</Text>}
              </Card>
            </TabPanel>
            <TabPanel
              w={{ sm: "330px", md: "700px", lg: "850px" }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text
                  color={textColor}
                  fontSize="2xl"
                  fontWeight="700"
                  mb="20px"
                >
                  Certification Details
                </Text>
                {isLoadingProviders ? (
                  <Text>Loading providers...</Text>
                ) : fetchError ? (
                  <Text color="red">{fetchError}</Text>
                ) : (
                  <Select
                    placeholder="Select a provider"
                    value={providerID}
                    onChange={(e) => setProviderID(e.target.value)}
                  >
                    {providers.map((provider) => (
                      <option key={provider.provID} value={provider.provID}>
                        {provider.providerUsername}
                      </option>
                    ))}
                  </Select>
                )}
                <Input
                  placeholder="certificationName"
                  type="text"
                  value={certificationName}
                  onChange={(e) => setcertificationName(e.target.value)}
                  mt="20px"
                />
                <Input
                  placeholder="certificationDate"
                  type="date"
                  value={certificationDate}
                  onChange={(e) => setcertificationDate(e.target.value)}
                  mt="20px"
                />
                <Button
                  colorScheme="blue"
                  mt="20px"
                  onClick={handleCertSubmit}
                  isLoading={isCertSubmitting}
                  isDisabled={
                    !providerID || !certificationName || !certificationDate
                  }
                >
                  Submit certification
                </Button>
                {certError && <Text color="red">{certError}</Text>}
                {certSuccess && <Text color="green">{certSuccess}</Text>}
              </Card>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </motion.div>
  );
};

export default CreateProvider;
