/* eslint-disable */
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { RiUserSettingsLine } from "react-icons/ri";
// chakra imports
import {
  Box,
  Flex,
  HStack,
  Text,
  useColorModeValue,
  Collapse,
  Icon,
} from "@chakra-ui/react";

import Users from "views/admin/Users";
import Role from "views/admin/Role";
import RolePermission from "views/admin/RolePermission";
import Permission from "views/admin/Permission";

import { BsPersonFillGear } from "react-icons/bs";
import {
  MdSettings,
  MdPermContactCalendar,
  MdWorkspacePremium,
  MdArrowDropDown,
  MdArrowDropUp,
  MdPerson,
  MdHomeRepairService,
  MdCategory,
  MdDesignServices,
  MdOutlinePayments,
  MdAccountTree,
} from "react-icons/md";
import { TbClockHour4 } from "react-icons/tb";
import { RiUserLocationFill } from "react-icons/ri";
import { GrServices } from "react-icons/gr";

import Provider from "views/Provider";
import Providerservices from "views/ProviderServices";
import Providerzone from "views/ProviderZone";
import ProviderWorkingHours from "views/ProviderWorkingHour";
import ListProviderPayments from "views/ProviderPayment/index";
import ListProviderProject from "views/ProviderProject/index";
import ListProviderCertification from "views/ProviderCertification/index";
import Services from "views/admin/Services";
import Category from "views/Category";

export function SidebarLinks(props) {
  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("blue.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("blue.500", "blue.400");

  const { routes } = props;

  const [clickedLinkIndex, setClickedLinkIndex] = useState(null);

  // State to manage the dropdown's open/close state
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isCatAndSerDropdownOpen, setCatAndSerDropdownOpen] = useState(false);
  const [isProviderDropdownOpen, setProviderDropdownOpen] = useState(false);
  // Function to toggle the dropdown state
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const toggleCatAndSerDropdown = () => {
    setCatAndSerDropdownOpen(!isCatAndSerDropdownOpen);
  };

  const toggleProviderDropdown = () => {
    setProviderDropdownOpen(!isProviderDropdownOpen);
  };

  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  // Function to render links within the dropdown
  const renderDropdownLinks = () => {
    // You can customize the links and paths based on your requirements
    const dropdownLinks = [
      {
        name: "Users",
        layout: "/admin",
        path: "/users",
        icon: (
          <Icon
            as={MdPermContactCalendar}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        component: Users,
      },
      {
        name: "Role",
        layout: "/admin",
        path: "/role",
        icon: (
          <Icon as={MdSettings} width="20px" height="20px" color="inherit" />
        ),
        component: Role,
      },
      {
        name: "Role Permission",
        layout: "/admin",
        path: "/permrole",
        icon: (
          <Icon
            as={RiUserSettingsLine}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        component: RolePermission,
      },
      {
        name: "Permission",
        layout: "/admin",
        path: "/permission",
        icon: (
          <Icon
            as={MdWorkspacePremium}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        component: Permission,
      },
    ];

    return dropdownLinks.map((link, index) => (
      <NavLink
        key={index}
        to={link.layout + link.path}
        onClick={() => setClickedLinkIndex(index)}
      >
        {link.icon ? (
          <Box>
            <HStack
              spacing={activeRoute(link.path.toLowerCase()) ? "22px" : "26px"}
              py="5px"
              ps="10px"
            >
              <Flex w="100%" alignItems="center" justifyContent="center">
                <Box
                  color={
                    activeRoute(link.path.toLowerCase())
                      ? activeIcon
                      : textColor
                  }
                  me="18px"
                >
                  {link.icon}
                </Box>
                <Text
                  me="auto"
                  color={
                    activeRoute(link.path.toLowerCase())
                      ? activeColor
                      : textColor
                  }
                  fontWeight={
                    activeRoute(link.path.toLowerCase()) ? "bold" : "normal"
                  }
                >
                  {link.name}
                </Text>
              </Flex>
              <Box
                h="36px"
                w="4px"
                bg={
                  activeRoute(link.path.toLowerCase())
                    ? brandColor
                    : "transparent"
                }
                borderRadius="5px"
              />
            </HStack>
          </Box>
        ) : (
          <Box>
            <HStack
              spacing={activeRoute(link.path.toLowerCase()) ? "22px" : "26px"}
              py="5px"
              ps="10px"
            >
              <Text
                me="auto"
                color={
                  activeRoute(link.path.toLowerCase())
                    ? activeColor
                    : inactiveColor
                }
                fontWeight={
                  activeRoute(link.path.toLowerCase()) ? "bold" : "normal"
                }
              >
                {link.name}
              </Text>
              <Box h="36px" w="4px" bg="blue.400" borderRadius="5px" />
            </HStack>
          </Box>
        )}
      </NavLink>
    ));
  };

  const renderProviderDropdownLinks = () => {
    const providerDropdownLinks = [
      {
        name: "Provider",
        layout: "/admin",
        path: "/provider",
        icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
        component: Provider,
      },
      {
        name: "Service",
        layout: "/admin",
        path: "/serviceprov",
        icon: (
          <Icon as={GrServices} width="20px" height="20px" color="inherit" />
        ),
        component: Providerservices,
      },
      {
        name: "zone",
        layout: "/admin",
        path: "/zonprov",
        icon: (
          <Icon
            as={RiUserLocationFill}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        component: Providerzone,
      },
      {
        name: "Working Hours",
        layout: "/admin",
        path: "/workinghours",
        icon: (
          <Icon as={TbClockHour4} width="20px" height="20px" color="inherit" />
        ),
        component: ProviderWorkingHours,
      },
      {
        name: "Payment",
        layout: "/admin",
        path: "/payment",
        icon: (
          <Icon
            as={MdOutlinePayments}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        component: ListProviderPayments,
      },
      {
        name: "Project",
        layout: "/admin",
        path: "/Project",
        icon: (
          <Icon as={MdAccountTree} width="20px" height="20px" color="inherit" />
        ),
        component: ListProviderProject,
      },
      {
        name: "Certification",
        layout: "/admin",
        path: "/Certification",
        icon: (
          <Icon
            as={MdOutlinePayments}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        component: ListProviderCertification,
      },
    ];

    return providerDropdownLinks.map((link, index) => (
      <NavLink
        key={index}
        to={link.layout + link.path}
        onClick={() => setClickedLinkIndex(index)}
      >
        {link.icon ? (
          <Box>
            <HStack
              spacing={activeRoute(link.path.toLowerCase()) ? "22px" : "26px"}
              py="5px"
              ps="10px"
            >
              <Flex w="100%" alignItems="center" justifyContent="center">
                <Box
                  color={
                    activeRoute(link.path.toLowerCase())
                      ? activeIcon
                      : textColor
                  }
                  me="18px"
                >
                  {link.icon}
                </Box>
                <Text
                  me="auto"
                  color={
                    activeRoute(link.path.toLowerCase())
                      ? activeColor
                      : textColor
                  }
                  fontWeight={
                    activeRoute(link.path.toLowerCase()) ? "bold" : "normal"
                  }
                >
                  {link.name}
                </Text>
              </Flex>
              <Box
                h="36px"
                w="4px"
                bg={
                  activeRoute(link.path.toLowerCase())
                    ? brandColor
                    : "transparent"
                }
                borderRadius="5px"
              />
            </HStack>
          </Box>
        ) : (
          <Box>
            <HStack
              spacing={activeRoute(link.path.toLowerCase()) ? "22px" : "26px"}
              py="5px"
              ps="10px"
            >
              <Text
                me="auto"
                color={
                  activeRoute(link.path.toLowerCase())
                    ? activeColor
                    : inactiveColor
                }
                fontWeight={
                  activeRoute(link.path.toLowerCase()) ? "bold" : "normal"
                }
              >
                {link.name}
              </Text>
              <Box h="36px" w="4px" bg="blue.400" borderRadius="5px" />
            </HStack>
          </Box>
        )}
      </NavLink>
    ));
  };

  const renderCatAndSerDropdownLinks = () => {
    const CatAndSerDropdownLinks = [
      {
        name: "Services",
        layout: "/admin",
        path: "/services",
        icon: (
          <Icon
            as={MdHomeRepairService}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        component: Services,
      },
      {
        name: "Category",
        layout: "/admin",
        path: "/category",
        icon: (
          <Icon as={MdCategory} width="20px" height="20px" color="inherit" />
        ),
        component: Category,
      },
    ];

    return CatAndSerDropdownLinks.map((link, index) => (
      <NavLink
        key={index}
        to={link.layout + link.path}
        onClick={() => setClickedLinkIndex(index)}
      >
        {link.icon ? (
          <Box>
            <HStack
              spacing={activeRoute(link.path.toLowerCase()) ? "22px" : "26px"}
              py="5px"
              ps="10px"
            >
              <Flex w="100%" alignItems="center" justifyContent="center">
                <Box
                  color={
                    activeRoute(link.path.toLowerCase())
                      ? activeIcon
                      : textColor
                  }
                  me="18px"
                >
                  {link.icon}
                </Box>
                <Text
                  me="auto"
                  color={
                    activeRoute(link.path.toLowerCase())
                      ? activeColor
                      : textColor
                  }
                  fontWeight={
                    activeRoute(link.path.toLowerCase()) ? "bold" : "normal"
                  }
                >
                  {link.name}
                </Text>
              </Flex>
              <Box
                h="36px"
                w="4px"
                bg={
                  activeRoute(link.path.toLowerCase())
                    ? brandColor
                    : "transparent"
                }
                borderRadius="5px"
              />
            </HStack>
          </Box>
        ) : (
          <Box>
            <HStack
              spacing={activeRoute(link.path.toLowerCase()) ? "22px" : "26px"}
              py="5px"
              ps="10px"
            >
              <Text
                me="auto"
                color={
                  activeRoute(link.path.toLowerCase())
                    ? activeColor
                    : inactiveColor
                }
                fontWeight={
                  activeRoute(link.path.toLowerCase()) ? "bold" : "normal"
                }
              >
                {link.name}
              </Text>
              <Box h="36px" w="4px" bg="blue.400" borderRadius="5px" />
            </HStack>
          </Box>
        )}
      </NavLink>
    ));
  };

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (
        route.name !== "Users" &&
        route.name !== "Role" &&
        route.name !== "Role Permission" &&
        route.name !== "Permission" &&
        route.name !== "Service" &&
        route.name !== "zone" &&
        route.name !== "Working Hours" &&
        route.name !== "Payment" &&
        route.name !== "Sign In" &&
        route.name !== "Category" &&
        route.name !== "Project"
      ) {
        if (route.layout === "/admin" || route.layout === "/auth") {
          if (route.name === "Provider") {
            return (
              <>
                <Box>
                  <HStack
                    spacing={"26px"}
                    cursor="pointer"
                    onClick={toggleProviderDropdown}
                    py="5px"
                    ps="10px"
                  >
                    <Flex w="100%" alignItems="center" justifyContent="center">
                      <Box color={textColor} me="18px">
                        <Icon as={BsPersonFillGear} boxSize={5} />
                      </Box>
                      <Text me="auto" color={textColor} fontWeight={"normal"}>
                        Provider
                      </Text>
                      <Box
                        color={isProviderDropdownOpen ? activeIcon : textColor}
                        me="0px"
                        pt={"1"}
                      >
                        <Icon
                          as={
                            isProviderDropdownOpen
                              ? MdArrowDropUp
                              : MdArrowDropDown
                          }
                          boxSize={8}
                          pt="1"
                        />
                      </Box>
                    </Flex>
                  </HStack>
                </Box>

                {/* Dropdown content */}
                <Collapse in={isProviderDropdownOpen}>
                  <Box ml="30px">{renderProviderDropdownLinks()}</Box>
                </Collapse>
              </>
            );
          }
          if (route.name === "Services") {
            return (
              <>
                <Box>
                  <HStack
                    spacing={"26px"}
                    cursor="pointer"
                    onClick={toggleCatAndSerDropdown}
                    py="5px"
                    ps="10px"
                  >
                    <Flex w="100%" alignItems="center" justifyContent="center">
                      <Box color={textColor} me="18px">
                        <Icon as={MdDesignServices} boxSize={5} />
                      </Box>
                      <Text me="auto" color={textColor} fontWeight={"normal"}>
                        Category and Services
                      </Text>
                      <Box
                        color={isCatAndSerDropdownOpen ? activeIcon : textColor}
                        me="0px"
                        pt={"1"}
                      >
                        <Icon
                          as={
                            isCatAndSerDropdownOpen
                              ? MdArrowDropUp
                              : MdArrowDropDown
                          }
                          boxSize={8}
                          pt="1"
                        />
                      </Box>
                    </Flex>
                  </HStack>
                </Box>

                {/* Dropdown content */}
                <Collapse in={isCatAndSerDropdownOpen}>
                  <Box ml="30px">{renderCatAndSerDropdownLinks()}</Box>
                </Collapse>
              </>
            );
          } else {
            return (
              <NavLink key={index} to={route.layout + route.path}>
                {route.icon ? (
                  <Box>
                    <HStack
                      spacing={
                        activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                      }
                      py="5px"
                      ps="10px"
                    >
                      <Flex
                        w="100%"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Box
                          color={
                            activeRoute(route.path.toLowerCase())
                              ? activeIcon
                              : textColor
                          }
                          me="18px"
                        >
                          {route.icon}
                        </Box>
                        <Text
                          me="auto"
                          color={
                            activeRoute(route.path.toLowerCase())
                              ? activeColor
                              : textColor
                          }
                          fontWeight={
                            activeRoute(route.path.toLowerCase())
                              ? "bold"
                              : "normal"
                          }
                        >
                          {route.name}
                        </Text>
                      </Flex>
                      <Box
                        h="36px"
                        w="4px"
                        bg={
                          activeRoute(route.path.toLowerCase())
                            ? brandColor
                            : "transparent"
                        }
                        borderRadius="5px"
                      />
                    </HStack>
                  </Box>
                ) : (
                  <Box>
                    <HStack
                      spacing={
                        activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                      }
                      py="5px"
                      ps="10px"
                    >
                      <Text
                        me="auto"
                        color={
                          activeRoute(route.path.toLowerCase())
                            ? activeColor
                            : inactiveColor
                        }
                        fontWeight={
                          activeRoute(route.path.toLowerCase())
                            ? "bold"
                            : "normal"
                        }
                      >
                        {route.name}
                      </Text>
                      <Box h="36px" w="4px" bg="blue.400" borderRadius="5px" />
                    </HStack>
                  </Box>
                )}
              </NavLink>
            );
          }
        }
      }
    });
  };
  //  BRAND
  return (
    <>
      {createLinks(routes)}

      {/* Button to toggle the dropdown */}
      <Box>
        <HStack
          spacing={"26px"}
          cursor="pointer"
          onClick={toggleDropdown}
          py="5px"
          ps="10px"
        >
          <Flex w="100%" alignItems="center" justifyContent="center">
            <Box color={textColor} me="18px">
              <Icon as={MdSettings} boxSize={5} />
            </Box>
            <Text me="auto" color={textColor} fontWeight={"normal"}>
              Settings
            </Text>
            <Box
              color={isDropdownOpen ? activeIcon : textColor}
              me="0px"
              pt={"1"}
            >
              <Icon
                as={isDropdownOpen ? MdArrowDropUp : MdArrowDropDown}
                boxSize={8}
                pt="1"
              />
            </Box>
          </Flex>
        </HStack>
      </Box>

      {/* Dropdown content */}
      <Collapse in={isDropdownOpen}>
        <Box ml="30px">{renderDropdownLinks()}</Box>
      </Collapse>
    </>
  );
}

export default SidebarLinks;
