import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Box,
  Input,
  Select,
  VStack,
  HStack,
  List,
  ListItem,
} from "@chakra-ui/react";
import { MdDelete, MdEdit, MdDone, MdCancel } from "react-icons/md";
import Card from "components/card/Card";
import fetchWithToken from "views/auth/signIn/axiosInstance"; // Adjust the path

const ListProject = () => {
  const [workingHours, setWorkingHours] = useState([]);
  const [editableHour, setEditableHour] = useState(null);
  const [error, setError] = useState("");
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [updateUser, setUpdateUser] = useState("");
  const [isProjectSubmitting, setIsProjectSubmitting] = useState(false);
  const [projectError, setProjectError] = useState("");
  const [projectSuccess, setProjectSuccess] = useState("");
  const [image, setImage] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [image4, setImage4] = useState(null);
  const [image5, setImage5] = useState(null);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      const [header, payload, signature] = accessToken.split(".");
      const decodedPayload = JSON.parse(atob(payload));
      const entryUser = decodedPayload.clientId;
      setUpdateUser(entryUser);
    } else {
      console.error("Access token not found.");
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [hoursResponse, providersResponse] = await Promise.all([
          fetchWithToken(`${process.env.REACT_APP_API_URL}/api/ProvProject`),
          fetchWithToken(`${process.env.REACT_APP_API_URL}/api/provider`),
        ]);
        setWorkingHours(hoursResponse);
        setProviders(providersResponse);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, []);

  const handleEditClick = (hourId) => {
    const hourToEdit = workingHours.find((hour) => hour.ppID === hourId);
    setEditableHour({ ...hourToEdit });
  };

  const handleCancel = () => {
    setEditableHour(null);
  };

  const handleFileChange = (file, setterFunction) => {
    setterFunction(file);
  };

  const handleSave = async () => {
    if (!editableHour || !editableHour.ppID) {
      console.error("Editable hour or ppID is missing");
      return;
    }

    try {
      const updatedParams = new FormData();

      if (image) updatedParams.append("image", image);
      if (image2) updatedParams.append("image2", image2);
      if (image3) updatedParams.append("image3", image3);
      if (image4) updatedParams.append("image4", image4);
      if (image5) updatedParams.append("image5", image5);
      updatedParams.append("ppID", editableHour.ppID);
      updatedParams.append("provID", editableHour.provID);
      updatedParams.append("projectDesc", editableHour.projectDesc);
      updatedParams.append("UpdateUser", updateUser);

      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/ProvProject`,
        "PUT",
        updatedParams
      );

      const updatedHour = response;
      setWorkingHours(
        workingHours.map((hour) =>
          hour.ppID === editableHour.ppID ? { ...hour, ...updatedHour } : hour
        )
      );
      setEditableHour(null);
      window.location.reload();
    } catch (error) {
      console.error("Error updating provider working hour:", error);
      setError(error.message);
    }
  };

  const handleChange = (e, field) => {
    setEditableHour({ ...editableHour, [field]: e.target.value });
  };

  const handleDelete = async (ppID) => {
    if (!window.confirm("Are you sure you want to delete this working hour?")) {
      return;
    }

    try {
      await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/ProvProject`,
        "DELETE",
        { ppID }
      );

      setWorkingHours(workingHours.filter((hour) => hour.ppID !== ppID));
      console.log("Working hour deleted successfully");
    } catch (error) {
      console.error("Error deleting working hour:", error);
      setError("Failed to delete working hour");
    }
  };

  const handleProviderClick = (providerId) => {
    setSelectedProvider(providerId);
  };

  const filteredProviders = providers.filter((provider) =>
    provider.providerUsername.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const displayedWorkingHours = workingHours.filter(
    (hour) => hour.provID === selectedProvider
  );

  const displayIconView = (imageUrl) => {
    if (imageUrl) {
      return (
        <img
          src={imageUrl}
          alt="ImageSlider"
          style={{ maxWidth: "100px", maxHeight: "100px" }}
        />
      );
    }
    return null;
  };

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
      boxShadow="lg"
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text color="black" fontSize="22px" fontWeight="700" lineHeight="100%">
          Provider Project List
        </Text>
        {error && <Text color="red.500">{error}</Text>}
      </Flex>
      <Box>
        <Input
          placeholder="Search provider"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          mb="20px"
        />
        <HStack align="start">
          <VStack align="start" spacing="10px" w="30%">
            <List spacing={3} w="100%">
              {filteredProviders.map((provider) => (
                <ListItem
                  key={provider.provID}
                  w="100%"
                  bg={
                    selectedProvider === provider.provID
                      ? "blue.200"
                      : "gray.100"
                  }
                  p="10px"
                  borderRadius="md"
                  cursor="pointer"
                  onClick={() => handleProviderClick(provider.provID)}
                >
                  {provider.providerUsername}
                </ListItem>
              ))}
            </List>
          </VStack>
          <Box w="70%">
            {selectedProvider && (
              <Box overflowY="auto" maxHeight="400px">
                <Table variant="simple" color="gray.500">
                  <Thead>
                    <Tr bg="blue.500" textColor="white">
                      <Th textColor="white">Index</Th>
                      <Th textColor="white">Description</Th>
                      <Th textColor="white">Image1</Th>
                      <Th textColor="white">Image2</Th>
                      <Th textColor="white">Image3</Th>
                      <Th textColor="white">Image4</Th>
                      <Th textColor="white">Image5</Th>
                      <Th textColor="white">Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {displayedWorkingHours.map((hour, index) => (
                      <Tr key={hour.ppID}>
                        <Td>{index + 1}</Td>
                        <Td>
                          {editableHour && editableHour.ppID === hour.ppID ? (
                            <Input
                              value={editableHour.projectDesc}
                              onChange={(e) => handleChange(e, "projectDesc")}
                            />
                          ) : (
                            hour.projectDesc
                          )}
                        </Td>
                        <Td>
                          <Flex>
                            {editableHour && editableHour.ppID === hour.ppID ? (
                              <Input
                                type="file"
                                accept="image/*"
                                onChange={(e) =>
                                  handleFileChange(e.target.files[0], setImage)
                                }
                              />
                            ) : (
                              hour.image && displayIconView(hour.image)
                            )}
                          </Flex>
                        </Td>
                        <Td>
                          <Flex>
                            {editableHour && editableHour.ppID === hour.ppID ? (
                              <Input
                                type="file"
                                accept="image/*"
                                onChange={(e) =>
                                  handleFileChange(e.target.files[0], setImage2)
                                }
                              />
                            ) : (
                              hour.image2 && displayIconView(hour.image2)
                            )}
                          </Flex>
                        </Td>
                        <Td>
                          <Flex>
                            {editableHour && editableHour.ppID === hour.ppID ? (
                              <Input
                                type="file"
                                accept="image/*"
                                onChange={(e) =>
                                  handleFileChange(e.target.files[0], setImage3)
                                }
                              />
                            ) : (
                              hour.image3 && displayIconView(hour.image3)
                            )}
                          </Flex>
                        </Td>
                        <Td>
                          <Flex>
                            {editableHour && editableHour.ppID === hour.ppID ? (
                              <Input
                                type="file"
                                accept="image/*"
                                onChange={(e) =>
                                  handleFileChange(e.target.files[0], setImage4)
                                }
                              />
                            ) : (
                              hour.image4 && displayIconView(hour.image4)
                            )}
                          </Flex>
                        </Td>
                        <Td>
                          <Flex>
                            {editableHour && editableHour.ppID === hour.ppID ? (
                              <Input
                                type="file"
                                accept="image/*"
                                onChange={(e) =>
                                  handleFileChange(e.target.files[0], setImage5)
                                }
                              />
                            ) : (
                              hour.image5 && displayIconView(hour.image5)
                            )}
                          </Flex>
                        </Td>
                        <Td>
                          {editableHour && editableHour.ppID === hour.ppID ? (
                            <Flex>
                              <Button
                                leftIcon={<MdDone />}
                                colorScheme="green"
                                size="sm"
                                onClick={handleSave}
                              >
                                Save
                              </Button>
                              <Button
                                leftIcon={<MdCancel />}
                                colorScheme="red"
                                size="sm"
                                ml={2}
                                onClick={handleCancel}
                              >
                                Cancel
                              </Button>
                            </Flex>
                          ) : (
                            <Flex>
                              <Button
                                leftIcon={<MdEdit />}
                                colorScheme="blue"
                                size="sm"
                                onClick={() => handleEditClick(hour.ppID)}
                              >
                                Edit
                              </Button>
                              <Button
                                leftIcon={<MdDelete />}
                                colorScheme="red"
                                size="sm"
                                onClick={() => handleDelete(hour.ppID)}
                                ml={2}
                              >
                                Delete
                              </Button>
                            </Flex>
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            )}
          </Box>
        </HStack>
      </Box>
    </Card>
  );
};

export default ListProject;
