import React, { useState } from 'react';
import { Button, Container, Center } from "@chakra-ui/react";
import GoogleMapComponent from './componants/CreateZone';
import { ZoneList } from './componants/Listzone';
import { MdAdd, MdRemove } from 'react-icons/md';



const ParentPage = () => {
  const [showZoneForm, setZoneForm] = useState(false);

  const toggleDefaultsForm = () => {
    setZoneForm(!showZoneForm);
  };

  return (


<Container maxW="xxl" mt={100}>
<Center>
  <Button
    colorScheme={showZoneForm ? "red" : "blue"} 
    leftIcon={showZoneForm ? <MdRemove/> : <MdAdd />}
    onClick={toggleDefaultsForm}
    marginBottom="20px"
  >
    {showZoneForm ? "Hide zone Form" : "Add  zone"}
  </Button>
</Center>

{showZoneForm && (
  <div style={{ marginBottom: '20px' }}>
    <GoogleMapComponent /> 
  </div>
)}

<ZoneList/>
</Container>
);
  
}

export default ParentPage;
