import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Box,
  Input,
} from "@chakra-ui/react";
import {
  MdDelete,
  MdEdit,
  MdDone,
  MdCancel,
  MdChevronLeft,
  MdChevronRight,
} from "react-icons/md";
import Card from "components/card/Card";
import fetchWithToken from "views/auth/signIn/axiosInstance";
export const CityList = () => {
  const [City, setCity] = useState([]);
  const [filteredCitys, setFilteredCitys] = useState([]);
  const [error, setError] = useState("");
  const [editableCity, setEditableCity] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [updateUser, setUpdateUser] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    console.log("Access Token:", accessToken);

    if (accessToken) {
      const [header, payload, signature] = accessToken.split(".");
      const decodedPayload = JSON.parse(atob(payload));
      const entryUser = decodedPayload.clientId;
      setUpdateUser(entryUser);
    } else {
      console.error("Access token not found.");
    }
  }, []);

  useEffect(() => {
    const fetchCity = async () => {
      try {
        const response = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/city`
        );
        // if (!response) {
        //   throw new Error("Failed to fetch defaults");
        // }
        const data = response; // await response.json();
        setCity(data);
        setFilteredCitys(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchCity();
  }, []);

  const handleDeleteCity = async (idcity) => {
    try {
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/city`,
        "DELETE",
        idcity
        // {
        //   method: "",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify({ }),
        // }
      );

      //   if (!response) {
      //     throw new Error("Failed to delete city");
      //   }

      setCity(City.filter((cityItem) => cityItem.idcity !== idcity));
      setFilteredCitys(
        filteredCitys.filter((cityItem) => cityItem.idcity !== idcity)
      );
    } catch (error) {
      console.error("Error deleting city:", error);
      setError("Failed to delete city");
    }
  };

  const handleEditCity = (defaultItem) => {
    setEditableCity(defaultItem);
  };

  const handleSaveCity = async () => {
    try {
      if (!editableCity) {
        return;
      }

      const data = {
        idcity: editableCity.idcity,
        EName: editableCity.EName,
        AName: editableCity.AName,

        updateUser: updateUser,
      };

      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/city`,
        "PUT",
        data
        // {
        //   method: "PUT",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify(data),
        // }
      );

      //   if (!response) {
      //     const errorData = await response.json();
      //     throw new Error(`Failed to update city: ${JSON.stringify(errorData)}`);
      //   }

      setCity(
        City.map((cityItem) => {
          if (cityItem.idcity === editableCity.idcity) {
            return editableCity;
          }
          return cityItem;
        })
      );

      setFilteredCitys(
        filteredCitys.map((cityItem) => {
          if (cityItem.idcity === editableCity.idcity) {
            return editableCity;
          }
          return cityItem;
        })
      );

      setEditableCity(null);
    } catch (error) {
      console.error("Error updating city:", error);
      setError(error.message);
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = City.filter((cityItem) =>
      cityItem.EName.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredCitys(filtered);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  // Calculate pagination indexes
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredCitys.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <Card px="0px" boxShadow="xl" overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        {error && <Text color="red.500">{error}</Text>}
        <Text
          color="gray.800"
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          City List
        </Text>
        <Input
          placeholder="Search Defaults..."
          value={searchQuery}
          onChange={handleSearch}
          size="sm"
          w="200px"
        />
      </Flex>

      <Box overflowY="auto" maxHeight="600px">
        <Table color="gray.500" mb="24px">
          <Thead>
            <Tr bg="blue.500" textColor="white">
              <Th textColor="white"> city</Th>

              <Th textColor="white">English Name</Th>
              <Th textColor="white">Arabic Name</Th>

              <Th textColor="white">Entry User</Th>
              <Th textColor="white">Entry Date</Th>
              <Th textColor="white">Update User</Th>
              <Th textColor="white">Update Date</Th>
              <Th textColor="white">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {currentItems.map((cityItem, index) => (
              <Tr key={cityItem.idcity}>
                <Td>{index + 1}</Td>
                <Td>
                  <Flex>
                    {editableCity && editableCity.idcity === cityItem.idcity ? (
                      <Input
                        value={editableCity.EName}
                        onChange={(e) =>
                          setEditableCity({
                            ...editableCity,
                            EName: e.target.value,
                          })
                        }
                      />
                    ) : (
                      cityItem.EName
                    )}
                  </Flex>
                </Td>
                <Td>
                  <Flex>
                    {editableCity && editableCity.idcity === cityItem.idcity ? (
                      <Input
                        value={editableCity.AName}
                        onChange={(e) =>
                          setEditableCity({
                            ...editableCity,
                            AName: e.target.value,
                          })
                        }
                      />
                    ) : (
                      cityItem.AName
                    )}
                  </Flex>
                </Td>

                <Td>{cityItem.entryUser}</Td>
                <Td>{cityItem.entryDate}</Td>
                <Td>{cityItem.updateUser}</Td>
                <Td>{cityItem.updateDate}</Td>
                <Td>
                  {editableCity && editableCity.idcity === cityItem.idcity ? (
                    <Flex>
                      <Button
                        leftIcon={<MdDone />}
                        colorScheme="green"
                        size="sm"
                        onClick={handleSaveCity}
                      >
                        Save
                      </Button>
                      <Button
                        leftIcon={<MdCancel />}
                        colorScheme="red"
                        size="sm"
                        ml={2}
                        onClick={() => setEditableCity(null)}
                        mt={"1px"}
                      >
                        Cancel
                      </Button>
                    </Flex>
                  ) : (
                    <Flex>
                      <Button
                        leftIcon={<MdEdit />}
                        colorScheme="blue"
                        size="sm"
                        onClick={() => handleEditCity(cityItem)}
                      >
                        Edit
                      </Button>
                      <Button
                        leftIcon={<MdDelete />}
                        colorScheme="red"
                        size="sm"
                        ml={2}
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to delete this default?"
                            )
                          ) {
                            handleDeleteCity(cityItem.idcity);
                          }
                        }}
                      >
                        Delete
                      </Button>
                    </Flex>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <Flex justify="space-between" alignItems="center" px="25px" mt="20px">
        <Text color="gray.800">
          Showing {indexOfFirstItem + 1} -{" "}
          {Math.min(indexOfLastItem, filteredCitys.length)} of{" "}
          {filteredCitys.length}
        </Text>
        <Flex>
          <Button
            leftIcon={<MdChevronLeft />}
            colorScheme="blue"
            size="sm"
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <Button
            leftIcon={<MdChevronRight />}
            colorScheme="blue"
            size="sm"
            onClick={handleNextPage}
            ml="2"
            disabled={indexOfLastItem >= filteredCitys.length}
          >
            Next
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
};
