import React, { useState, useEffect } from 'react';
import { Flex, Text, Table, Thead, Tbody, Tr, Th, Td, Button, Box, Input } from '@chakra-ui/react';
import { MdDelete, MdEdit, MdDone,MdCancel } from 'react-icons/md';
import Card from "components/card/Card";
import fetchWithToken from 'views/auth/signIn/axiosInstance';
export const PermissionList = () => {
    const [permissions, setPermissions] = useState([]);
    const [filteredPermissions, setFilteredPermissions] = useState([]);
    const [error, setError] = useState('');
    const [editablePermission, setEditablePermission] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchPermissions = async () => {
            try {
                const response = await fetchWithToken(`${process.env.REACT_APP_API_URL}/api/permission`);
                if (!response) {
                    throw new Error('Failed to fetch permissions');
                }
                const data = response//await .json();
                setPermissions(data);
                setFilteredPermissions(data);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchPermissions();
    }, []);

    const handleDeletePermission = async (permissionId) => {
        try {
            const response = await fetchWithToken(`${process.env.REACT_APP_API_URL}/api/permission`,
            'DELETE',{ permissionId }//      {
            //     method: 
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify(),
            // }
        );
            if (!response) {
                throw new Error('Failed to delete permission');
            }
            // Remove the deleted permission from the permissions state
            setPermissions(permissions.filter(permission => permission.permissionId !== permissionId));
            setFilteredPermissions(filteredPermissions.filter(permission => permission.permissionId !== permissionId));
        } catch (error) {
            console.error('Error deleting permission:', error);
            setError('Failed to delete permission');
        }
    };

    const handleEditPermission = (permission) => {
        setEditablePermission(permission);
    };

    const handleSavePermission = async () => {
        try {
            const response = await fetchWithToken(`${process.env.REACT_APP_API_URL}/api/permission`,
               "PUT", editablePermission
            //     {
            //     method: 
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify(),
            // }
            );
            if (!response) {
                throw new Error('Failed to update permission');
            }
            // Update permissions in the local state
            setPermissions(permissions.map(permission => {
                if (permission.permissionId === editablePermission.permissionId) {
                    return editablePermission;
                }
                return permission;
            }));
            setFilteredPermissions(filteredPermissions.map(permission => {
                if (permission.permissionId === editablePermission.permissionId) {
                    return editablePermission;
                }
                return permission;
            }));
            setEditablePermission(null); 
            
            // Clear the editable permission after saving
        } catch (error) {
            console.error('Error updating permission:', error);
            setError('Failed to update permission');
        }
    };

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
        const filtered = permissions.filter(permission =>
            permission.description.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredPermissions(filtered);
    };

    return (
        <Card direction='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
            <Flex px='25px' justify='space-between' mb='20px' align='center'>
                <Text color='gray.800' fontSize='22px' fontWeight='700' lineHeight='100%'>
                    Permission List
                </Text>
                {error && <Text color="red.500">{error}</Text>}

                <Input
                    placeholder="Search permission..."
                    value={searchQuery}
                    onChange={handleSearch}
                    size="sm"
                    w="200px"
                />
            </Flex>
            <Box overflowY="auto" maxHeight="400px">
                <Table variant='simple' color='gray.500' mb='24px'>
                    <Thead>
                        <Tr bg='blue.500' textColor='white'>
                            <Th textColor='white'>Permission</Th>
                            <Th textColor='white'>Description</Th>
                            <Th textColor='white'>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {filteredPermissions.map((permission, index) => (
                            <Tr key={permission.permissionId}>
                                <Td>{index + 1}</Td>
                                <Td>
                                    <Flex>
                                    {editablePermission && editablePermission.permissionId === permission.permissionId ? (
                                        <input
                                            type="text"
                                            value={editablePermission.description}
                                            onChange={(e) => setEditablePermission({ ...editablePermission, description: e.target.value })}
                                        />
                                    ) : (
                                        permission.description
                                    )}
                                    </Flex>
                                </Td>
                                <Td>
                                    
                                    {editablePermission && editablePermission.permissionId === permission.permissionId ? (
                                        <Flex>
                                        
                                            <Button
                                                leftIcon={<MdDone />}
                                                colorScheme="green"
                                                size="sm"
                                                onClick={handleSavePermission}
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                leftIcon={<MdCancel />}
                                                colorScheme="red"
                                                ml={2} 

                                                size="sm"
                                                onClick={() => setEditablePermission(null)}
                                            >
                                                Cancel
                                            </Button>
                                        </Flex>
                                    ) : (
                                        <Flex>
                                            <Button
                                                leftIcon={<MdEdit />}
                                                colorScheme="blue"
                                                size="sm"
                                                onClick={() => handleEditPermission(permission)}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                leftIcon={<MdDelete />}
                                                colorScheme="red"
                                                size="sm"
                                                ml={2}

                                                onClick={() => {
                                                    if (window.confirm('Are you sure you want to delete this permission?')) 
                                                    {
                                                        handleDeletePermission(permission.permissionId);
                                                    }
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        </Flex>
                                    )}
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
        </Card>
    );
};


