import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import fetchWithToken from "views/auth/signIn/axiosInstance";
const AddCategoryForm = () => {
  const [catNameEng, setCatNameEng] = useState("");
  const [description, setDescription] = useState("");
  const [catNameArb, setCatNameArb] = useState("");
  const [ordernumber, setordernumber] = useState("");
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [entryUser, setEntryUser] = useState("");
  const [updateUser, setUpdateUser] = useState("");
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      const [header, payload, signature] = accessToken.split(".");
      const decodedPayload = JSON.parse(atob(payload));
      const user = decodedPayload.clientId;
      setEntryUser(user);
      setUpdateUser(user);
    } else {
      console.error("Access token not found.");
    }
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("catNameEng", catNameEng);
    formData.append("description", description);
    formData.append("catNameArb", catNameArb);
    formData.append("icon", file);
    formData.append("ordernumber", ordernumber);
    try {
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/category`,
        //{
        //method:
        "POST",
        // body:
        formData
        //}
      );

      if (response) {
        setCatNameArb("");
        setCatNameEng("");
        setDescription("");
        setordernumber("");
        setFile("");
        setSuccessMessage("Category added successfully");
      } else {
        setSuccessMessage("");
        const errorData = await response.json();
        setError(errorData.error || "Failed to add category");
      }
      window.location.reload();
    } catch (error) {
      console.error("Error adding category:", error);
      setError("Failed to add category");
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 0.5 }}
      style={{
        textAlign: "center",
        maxWidth: "400px",
        margin: "auto",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0px 0px 10px rgba(0, 0, 255, 0.3)", // Blue shadow
        //  backgroundColor: 'blue', // Blue background color
      }}
    >
      <h2 style={{ marginBottom: "20px", fontSize: "24px" }}>Add Category</h2>
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <FormControl style={{ width: "100%" }}>
          <FormLabel>
            Category Name (English):
            <Input
              type="text"
              value={catNameEng}
              onChange={(e) => setCatNameEng(e.target.value)}
              placeholder="Enter English Name"
              style={{ marginBottom: "10px" }}
            />
          </FormLabel>

          <FormLabel>
            Description:
            <Textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter Description"
              style={{ marginBottom: "10px" }}
            />
          </FormLabel>

          <FormLabel>
            Category Name (Arabic):
            <Input
              type="text"
              value={catNameArb}
              onChange={(e) => setCatNameArb(e.target.value)}
              placeholder="Enter Arabic Name"
              style={{ marginBottom: "10px" }}
            />
          </FormLabel>
          <FormLabel>
            Order:
            <Input
              type="number"
              value={ordernumber}
              onChange={(e) => setordernumber(e.target.value)}
              placeholder="Enter order"
              style={{ marginBottom: "10px" }}
            />
          </FormLabel>
          <FormLabel>
            Category Icon:
            <Input
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
              style={{ marginBottom: "10px" }}
            />
          </FormLabel>

          <Button colorScheme="blue" type="submit">
            Add Category
          </Button>
        </FormControl>
      </form>
      {error && <p style={{ color: "red", marginTop: "10px" }}>{error}</p>}
      {successMessage && (
        <p style={{ color: "green", marginTop: "10px" }}>{successMessage}</p>
      )}
    </motion.div>
  );
};

export default AddCategoryForm;
