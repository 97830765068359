import React, { useState } from 'react';
import { Button, Container, Center } from "@chakra-ui/react";
import { MdAdd, MdRemove } from "react-icons/md";
import RolePermissionForm from './components/CreateRolePermission';
import RolePermissionList from './components/ListRolePermission';

const ParentPage = () => {
  const [showRolePermissionForm, setShowRolePermissionForm] = useState(false);

  const toggleRolePermissionForm = () => {
    setShowRolePermissionForm(!showRolePermissionForm);
  };

  return (
    <Container maxW="xxl" mt={100}>
      <Center>
        <Button 
           colorScheme={showRolePermissionForm ? "red" : "blue"} 
           leftIcon={showRolePermissionForm ? <MdRemove /> : <MdAdd />}
          onClick={toggleRolePermissionForm}
          marginBottom="20px"
        >
          {showRolePermissionForm ? "Hide Role Permission Form" : "Add Role Permission"}
        </Button>
      </Center>

      {showRolePermissionForm && (
        <div style={{ marginBottom: '20px' }}>
          <RolePermissionForm />
        </div>
      )}

      <RolePermissionList />
    </Container>
  );
};

export default ParentPage;
