import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Text,
  Select,
} from "@chakra-ui/react";
import fetchWithToken from "views/auth/signIn/axiosInstance";
const CreateServiceForm = () => {
  const [catId, setCatId] = useState("");
  const [serviceNameEng, setServiceNameEng] = useState("");
  const [description, setDescription] = useState("");
  const [entryUser, setEntryUser] = useState("");
  const [serviceNameArb, setServiceNameArb] = useState("");
  const [serviceIcon, setServiceIcon] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [ordernumber, setordernumber] = useState("");
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/category`
        );
        if (response) {
          const categoriesData = response; //await response.json();
          setCategories(categoriesData);
        } else {
          console.error("Error fetching categories");
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      // Split the token into header, payload, and signature
      // eslint-disable-next-line no-unused-vars
      const [header, payload, signature] = accessToken.split(".");
      // Decode the payload (Base64 decoded)
      const decodedPayload = JSON.parse(atob(payload));
      // Extract the username
      const entryUser = decodedPayload.clientId;
      // Set the entryUser in the state
      setEntryUser(entryUser);
    } else {
      console.error("Access token not found.");
    }
  }, []);

  const MAX_FILE_SIZE_KB = 65; // Set your maximum file size limit in megabytes

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check if the file size is less than the maximum allowed size
      if (file.size > MAX_FILE_SIZE_KB * 1024) {
        alert(
          `File size exceeds the maximum allowed size of ${MAX_FILE_SIZE_KB} KB.`
        );
        // You can choose to reset the file input or handle the error accordingly
        // For example: setFile(null);
      } else {
        setServiceIcon(file);
      }
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      // const formData = new FormData();
      //  console.log(serviceNameArb);
      const formData = {
        catId: catId,
        serviceNameEng: serviceNameEng,
        description: description,
        entryUser: entryUser,
        updateUser: entryUser,
        serviceNameArb: serviceNameArb,
        serviceIcon: "null",
        ordernumber: ordernumber,
      };
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/service`,
        "POST",
        formData
        // {
        //   method: "POST",
        //   body: formData,
        // }
      );

      if (response) {
        console.log("Service created successfully");
        window.location.reload();
        //window.location.href = "/#/admin/services";
        //setError("");
      } else {
        const errorData = response; // await response.json();
        setError(errorData.error || "Failed to create service");
      }
    } catch (error) {
      console.error("Error creating service:", error);
      setError("Failed to create service");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      mt={8}
      w={"sm"}
      boxShadow="0px 0px 10px rgba(0, 0, 255, 0.3)"
      p="8"
      borderRadius="md"
      bg="white"
    >
      <Heading fontSize="32px" mb="4">
        Create Service
      </Heading>
      <FormControl>
        <FormLabel>Category</FormLabel>
        <Select
          variant="auth"
          fontSize="sm"
          ms={{ base: "0px", md: "0px" }}
          placeholder="Select Category"
          mb="24px"
          fontWeight="500"
          size="lg"
          value={catId}
          onChange={(e) => setCatId(e.target.value)}
        >
          {categories.map((category) => (
            <option key={category.catId} value={category.catId}>
              {category.catNameEng}
            </option>
          ))}
        </Select>
        <FormLabel>Service Name (English)</FormLabel>
        <Input
          type="text"
          placeholder="Service Name (English)"
          mb="4"
          value={serviceNameEng}
          onChange={(e) => setServiceNameEng(e.target.value)}
        />
        <FormLabel>Description</FormLabel>
        <Textarea
          placeholder="Description"
          mb="4"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <FormLabel>Service Name (Arabic)</FormLabel>
        <Input
          type="text"
          placeholder="Service Name (Arabic)"
          mb="4"
          value={serviceNameArb}
          onChange={(e) => setServiceNameArb(e.target.value)}
        />
        <FormLabel>
          Order:
          <Input
            type="number"
            value={ordernumber}
            onChange={(e) => setordernumber(e.target.value)}
            placeholder="Enter order"
            style={{ marginBottom: "10px" }}
          />
        </FormLabel>
        {/* <FormLabel>Service Icon</FormLabel>
        <Input
          type="file"
          accept="image/*"
          mb="4"
          onChange={handleFileChange}
        /> */}
        <Button colorScheme="blue" isLoading={loading} onClick={handleSubmit}>
          Create Service
        </Button>
        {error && <Text color="red">{error}</Text>}
      </FormControl>
    </Box>
  );
};

export default CreateServiceForm;
