import React from "react";
import { Container, } from "@chakra-ui/react";

import  Listproviderservices  from "./components/listproviderServices";

export default function Providerservices() {
    return (
        <Container maxW="xxl" mt={100}>
            <Listproviderservices />
        </Container>
    );
}
