import { MdDelete, MdEdit, MdDone, MdCancel } from "react-icons/md";
import { useState, useEffect } from "react";
import {
  Flex,
  Box,
  Input,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  Button,
  Select,
  Icon,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import fetchWithToken from "views/auth/signIn/axiosInstance";
const RolePermissionList = () => {
  const [rolePermissions, setRolePermissions] = useState([]);
  const [filteredRolePermissions, setFilteredRolePermissions] = useState([]);
  const [error, setError] = useState("");
  const [editableRolePermission, setEditableRolePermission] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [updateUser, setUpdateUser] = useState("");

  const [roles, setRoles] = useState([]);
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    //   console.log("Access Token:", accessToken);

    if (accessToken) {
      // Split the token into header, payload, and signature
      // eslint-disable-next-line no-unused-vars
      const [header, payload, signature] = accessToken.split(".");
      // Decode the payload (Base64 decoded)
      const decodedPayload = JSON.parse(atob(payload));
      // Extract the username
      const entryUser = decodedPayload.clientId;
      // Set the entryUser in the state
      setUpdateUser(entryUser);
    } else {
      console.error("Access token not found.");
    }
  }, []); // Run this effect only once, similar to componentDidMount
  useEffect(() => {
    const fetchRolePermissions = async () => {
      try {
        const response = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/role_permission`
        );
        if (!response) {
          throw new Error("Failed to fetch role permissions");
        }
        const data = response; //await .json();
        const updatedRolePermissions = data.map(async (rolePermission) => {
          const roleDescription = await fetchRoleDescription(
            rolePermission.roleId
          );
          const permissionDescription = await fetchPermissionDescription(
            rolePermission.permissionId
          );
          return { ...rolePermission, roleDescription, permissionDescription };
        });
        const resolvedRolePermissions = await Promise.all(
          updatedRolePermissions
        );
        setRolePermissions(resolvedRolePermissions);
        setFilteredRolePermissions(resolvedRolePermissions);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchRolePermissions();
  }, []);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/permission`
        );
        if (!response) {
          throw new Error("Failed to fetch permissions");
        }
        const data = response; // await .json();
        setPermissions(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchPermissions();
  }, []);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/role`
        );
        if (!response) {
          throw new Error("Failed to fetch roles");
        }
        const data = response; //await .json();
        setRoles(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchRoles();
  }, []);
  const handleDeleteRolePermission = async (rolePermissionId) => {
    try {
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/role_permission`,
        "DELETE",
        { rolePermissionId }
        // {
        //   method:
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify(),
        // }
      );
      if (!response) {
        throw new Error("Failed to delete role permission");
      }
      // Remove the deleted role permission from the state
      setRolePermissions(
        rolePermissions.filter(
          (rolePermission) =>
            rolePermission.rolePermissionId !== rolePermissionId
        )
      );
      setFilteredRolePermissions(
        filteredRolePermissions.filter(
          (rolePermission) =>
            rolePermission.rolePermissionId !== rolePermissionId
        )
      );
    } catch (error) {
      console.error("Error deleting role permission:", error);
      setError("Failed to delete role permission");
    }
  };
  const fetchPermissionDescription = async (permissionId) => {
    try {
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/permission/${permissionId}`
      );
      if (!response) {
        throw new Error("Failed to fetch permission description");
      }
      const data = response; //await .json();
      if (Array.isArray(data) && data.length > 0) {
        return data[0].description;
      } else {
        throw new Error("Permission description not found");
      }
    } catch (error) {
      setError(error.message);
      return "";
    }
  };

  const fetchRoleDescription = async (roleId) => {
    try {
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/role/${roleId}`
      );
      if (!response) {
        throw new Error("Failed to fetch role description");
      }
      const data = response; //await .json();
      if (Array.isArray(data) && data.length > 0) {
        return data[0].description;
      } else {
        throw new Error("Role description not found");
      }
    } catch (error) {
      setError(error.message);
      return "";
    }
  };

  const handleEditRolePermission = (rolePermission) => {
    setEditableRolePermission(rolePermission);
  };

  const handleSaveRolePermission = async () => {
    try {
      const data = {
        rolePermissionId: editableRolePermission.rolePermissionId,
        roleId: editableRolePermission.roleId,
        permissionId: editableRolePermission.permissionId,
        UpdateUser: updateUser,
      };
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/role_permission`,
        "PUT",
        data
        // {
        //   method:
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify(),
        // }
      );
      if (!response) {
        const errorData = response; //await .json(); // Parse the response JSON
        throw new Error(
          `Failed to update role permission: ${JSON.stringify(errorData)}`
        );
      }

      const updatedData = await response.json();
      setRolePermissions((prevRolePermissions) =>
        prevRolePermissions.map((rolePermission) => {
          if (
            rolePermission.rolePermissionId ===
            editableRolePermission.rolePermissionId
          ) {
            return { ...editableRolePermission, ...updatedData };
          }
          return rolePermission;
        })
      );

      setFilteredRolePermissions((prevFilteredRolePermissions) =>
        prevFilteredRolePermissions.map((rolePermission) => {
          if (
            rolePermission.rolePermissionId ===
            editableRolePermission.rolePermissionId
          ) {
            return { ...editableRolePermission, ...updatedData };
          }
          return rolePermission;
        })
      );

      setEditableRolePermission(null);
      window.location.reload();
    } catch (error) {
      console.error("Error updating role permission:", error);
      setError(error.message);
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = rolePermissions.filter(
      (rolePermission) =>
        rolePermission.roleDescription
          .toLowerCase()
          .includes(query.toLowerCase()) ||
        rolePermission.permissionDescription
          .toLowerCase()
          .includes(query.toLowerCase())
    );
    setFilteredRolePermissions(filtered);
  };

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        {error && <Text color="red.500">{error}</Text>}

        <Text
          color="gray.800"
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Role Permission List
        </Text>
        <Input
          placeholder="Search role permission..."
          value={searchQuery}
          onChange={handleSearch}
          size="sm"
          w="200px"
        />
      </Flex>
      <Box overflowY="auto" maxHeight="400px">
        <Table variant="simple" color="gray.500" mb="24px">
          <Thead>
            <Tr bg="blue.500">
              <Th textColor="white">Index</Th>
              <Th textColor="white">Role</Th>
              <Th textColor="white">Permission</Th>
              <Th textColor="white">Entry User</Th>
              <Th textColor="white">Entry Date</Th>
              <Th textColor="white">Update User</Th>
              <Th textColor="white">Update Date</Th>
              <Th textColor="white">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredRolePermissions.map((rolePermission, index) => (
              <Tr key={rolePermission.rolePermissionId}>
                <Td>{index + 1}</Td>

                <Td>
                  {editableRolePermission &&
                  editableRolePermission.rolePermissionId ===
                    rolePermission.rolePermissionId ? (
                    <Select
                      value={editableRolePermission.roleId}
                      onChange={(e) =>
                        setEditableRolePermission({
                          ...editableRolePermission,
                          roleId: e.target.value,
                        })
                      }
                    >
                      {roles.map((role) => (
                        <option key={role.roleId} value={role.roleId}>
                          {role.description}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    rolePermission.roleDescription
                  )}
                </Td>
                <Td>
                  {editableRolePermission &&
                  editableRolePermission.rolePermissionId ===
                    rolePermission.rolePermissionId ? (
                    <Select
                      value={editableRolePermission.permissionId}
                      onChange={(e) =>
                        setEditableRolePermission({
                          ...editableRolePermission,
                          permissionId: e.target.value,
                        })
                      }
                    >
                      {permissions.map((permission) => (
                        <option
                          key={permission.permissionId}
                          value={permission.permissionId}
                        >
                          {permission.description}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    rolePermission.permissionDescription
                  )}
                </Td>
                <Td>{rolePermission.EntryUser}</Td>
                <Td>{rolePermission.EntryDate}</Td>

                <Td>{rolePermission.UpdateUser}</Td>

                <Td> {rolePermission.UpdateDate}</Td>

                <Td>
                  {editableRolePermission &&
                  editableRolePermission.rolePermissionId ===
                    rolePermission.rolePermissionId ? (
                    <Flex>
                      <Button
                        leftIcon={<MdDone />}
                        colorScheme="green"
                        size="sm"
                        onClick={handleSaveRolePermission}
                      >
                        Save
                      </Button>
                      <Button
                        leftIcon={<MdCancel />}
                        colorScheme="red"
                        ml={2}
                        size="sm"
                        onClick={() => setEditableRolePermission(null)}
                      >
                        Cancel
                      </Button>
                    </Flex>
                  ) : (
                    <Flex>
                      <Button
                        colorScheme="blue"
                        width={10}
                        size="sm"
                        onClick={() => handleEditRolePermission(rolePermission)}
                      >
                        <Icon as={MdEdit} boxSize={5} />
                      </Button>

                      <Button
                        colorScheme="red"
                        size="sm"
                        width={10}
                        ml={2}
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to delete this kind?"
                            )
                          ) {
                            handleDeleteRolePermission(
                              rolePermission.rolePermissionId
                            );
                          }
                        }}
                      >
                        <Icon as={MdDelete} boxSize={5} />
                      </Button>
                    </Flex>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
};

export default RolePermissionList;
