import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Box,
  Input,
} from "@chakra-ui/react";
import { MdDelete, MdEdit, MdDone } from "react-icons/md";
import Card from "components/card/Card";
import moment from "moment";
import fetchWithToken from "views/auth/signIn/axiosInstance";
export const ClientList = () => {
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [error, setError] = useState("");
  const [editableClient, setEditableClient] = useState(null);
  const [editedProperty, setEditedProperty] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [entryUser, setEntryUser] = useState("");
  const [updateUser, setUpdateUser] = useState("");
  const totalClients = clients.length;
  const SEX_UUIDS = {
    Male: "956cd7f0-4c87-4ac0-a7b7-6afbda52f4f8",
    Female: "c6d7c072-da59-4af4-aceb-beb6b7973908",
  };

  const totalMales = clients.filter(
    (client) => client.Sex === SEX_UUIDS.Male
  ).length;
  const totalFemales = clients.filter(
    (client) => client.Sex === SEX_UUIDS.Female
  ).length;
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      const [header, payload, signature] = accessToken.split(".");
      const decodedPayload = JSON.parse(atob(payload));
      const user = decodedPayload.clientId;
      setEntryUser(user);
      setUpdateUser(user);
    } else {
      console.error("Access token not found.");
    }
  }, []);
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/client`
        );
        // if (!response) {
        //   throw new Error("Failed to fetch clients");
        // }
        const data = response; //await response.json();
        setClients(data);
        setFilteredClients(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchClients();
  }, []);

  const handleDeleteClient = async (clientId, clientUsername) => {
    try {
      const data = {
        clientId,
        clientUsername,
      };
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/client`,
        "DELETE",
        data
        // {
        //   method:
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify({
        //     clientId,
        //     clientUsername,
        //   }),
        // }
      );
      if (!response) {
        setError("Failed to delete client"); //await response.text());
        console.log(error);
        throw new Error("Failed to delete client");
      }
      // Remove the deleted client from the clients state
      setClients(clients.filter((client) => client.clientId !== clientId));
      setFilteredClients(
        filteredClients.filter((client) => client.clientId !== clientId)
      );
    } catch (error) {
      console.error("Error deleting client:", error);
      setError("Failed to delete client");
    }
  };
  const mapSex = (sexId) => {
    const sexMap = {
      "956cd7f0-4c87-4ac0-a7b7-6afbda52f4f8": "Male",
      "c6d7c072-da59-4af4-aceb-beb6b7973908": "Female",
    };
    return sexMap[sexId] || "Unknown"; // Return "Unknown" if the ID doesn't match
  };

  const handleEditClient = (client, property) => {
    setEditableClient(client);
    setEditedProperty(property);
  };

  const handleSaveClient = async () => {
    try {
      const { clientId } = editableClient;

      let dataToSend = { clientId };
      const originalClient = clients.find(
        (client) => client.clientId === clientId
      );

      // Check for changes in each editable field, not just the editedProperty
      const fieldsToCheck = ["clientUsername", "Sex"]; // Extend this list with other fields as necessary
      fieldsToCheck.forEach((field) => {
        if (editableClient[field] !== originalClient[field]) {
          dataToSend[field] = editableClient[field];
        }
      });

      if (Object.keys(dataToSend).length > 1) {
        const response = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/client`,
          "PUT",
          dataToSend
          //   {
          //     method:
          //     headers: {
          //       "Content-Type": "application/json",
          //     },
          //     body: JSON.stringify(),
          //   }
        );

        if (!response) {
          throw new Error("Failed to update client");
        }

        // Update clients and filteredClients in the state
        setClients((prevClients) =>
          prevClients.map((client) =>
            client.clientId === clientId ? { ...client, ...dataToSend } : client
          )
        );
        setFilteredClients((prevFilteredClients) =>
          prevFilteredClients.map((client) =>
            client.clientId === clientId ? { ...client, ...dataToSend } : client
          )
        );

        setEditableClient(null);
        setEditedProperty("");
      } else {
        console.log("No changes to save for this client.");
        setEditableClient(null);
        setEditedProperty("");
      }
    } catch (error) {
      console.error("Error updating client:", error);
      setError("Failed to update client");
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = clients.filter((client) =>
      client.clientUsername.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredClients(filtered);
  };

  const calculateDayWithMostClients = (clients) => {
    if (clients.length === 0) {
      return null; // or any default value based on your requirements
    }

    const dayCountMap = {};

    clients.forEach((client) => {
      const creationDay = moment(client.CreationDate).format("YYYY-MM-DD");
      dayCountMap[creationDay] = (dayCountMap[creationDay] || 0) + 1;
    });

    const maxDate = Object.keys(dayCountMap).reduce((a, b) =>
      dayCountMap[a] > dayCountMap[b] ? a : b
    );

    return maxDate;
  };

  const dayWithMostClients = calculateDayWithMostClients(clients);

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color="gray.800"
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Client List
        </Text>
        <Input
          placeholder="Search client..."
          value={searchQuery}
          onChange={handleSearch}
          size="sm"
          w="200px"
        />
      </Flex>
      <Box overflowY="auto" maxHeight="400px">
        <Table variant="simple" color="gray.500" mb="24px">
          <Thead>
            <Tr bg="blue.500">
              <Th textColor="white">Client</Th>
              <Th textColor="white">Username</Th>
              <Th textColor="white">Sex</Th>
              <Th textColor="white">Phone Number</Th>
              <Th textColor="white">Creation Date</Th>
              <Th textColor="white">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredClients.map((client, index) => (
              <Tr key={client.clientId}>
                <Td>{index + 1}</Td>
                <Td>
                  {editableClient &&
                  editableClient.clientId === client.clientId ? (
                    <input
                      type="text"
                      value={editableClient.clientUsername}
                      onChange={(e) =>
                        setEditableClient({
                          ...editableClient,
                          clientUsername: e.target.value,
                        })
                      }
                    />
                  ) : (
                    client.clientUsername
                  )}
                </Td>
                <Td>
                  {editableClient &&
                  editableClient.clientId === client.clientId ? (
                    <select
                      value={editableClient.Sex}
                      onChange={(e) =>
                        setEditableClient({
                          ...editableClient,
                          Sex: e.target.value,
                        })
                      }
                    >
                      <option value="956cd7f0-4c87-4ac0-a7b7-6afbda52f4f8">
                        Male
                      </option>
                      <option value="c6d7c072-da59-4af4-aceb-beb6b7973908">
                        Female
                      </option>
                    </select>
                  ) : (
                    mapSex(client.Sex)
                  )}
                </Td>

                <Td>
                  {editableClient &&
                  editableClient.clientId === client.clientId ? (
                    <input
                      type="text"
                      value={editableClient.clientPhone}
                      onChange={(e) =>
                        setEditableClient({
                          ...editableClient,
                          clientPhone: e.target.value,
                        })
                      }
                    />
                  ) : (
                    client.clientPhone
                  )}
                </Td>
                <Td>{client.CreationDate}</Td>
                <Td>
                  {editableClient &&
                  editableClient.clientId === client.clientId ? (
                    <>
                      <Button
                        leftIcon={<MdDone />}
                        colorScheme="green"
                        size="sm"
                        onClick={handleSaveClient}
                      >
                        Save
                      </Button>
                      <Button
                        leftIcon={<MdDelete />}
                        colorScheme="red"
                        ml={2}
                        size="sm"
                        onClick={() => setEditableClient(null)}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        leftIcon={<MdEdit />}
                        colorScheme="blue"
                        size="sm"
                        onClick={() =>
                          handleEditClient(client, "clientUsername")
                        }
                      >
                        Edit
                      </Button>
                      <Button
                        leftIcon={<MdDelete />}
                        colorScheme="red"
                        size="sm"
                        ml={2}
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to delete this client?"
                            )
                          ) {
                            handleDeleteClient(
                              client.clientId,
                              client.clientUsername
                            );
                          }
                        }}
                      >
                        Delete
                      </Button>
                    </>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <Box mt="4">
        <Text fontSize="lg" fontWeight="bold" color="blue.500">
          Totals:
        </Text>
        <Flex align="center" mt="2">
          <Text mr="4">Total Clients: {totalClients}</Text>
          <Text mr="4">Total Males: {totalMales}</Text>
          <Text mr="4">Total Females: {totalFemales}</Text>
          <Text>Day with most registers: {dayWithMostClients}</Text>
        </Flex>
      </Box>
    </Card>
  );
};
