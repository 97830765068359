import React from "react";
import { Container} from "@chakra-ui/react";
import  Listproviderzone  from "./components/listProviderZone";


export default function Providerzone() {
    return (
        <Container maxW="xxl" mt={100}>
            <Listproviderzone />
        </Container>
    );
}
