import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Box,
  Input,
} from "@chakra-ui/react";
import {
  MdDelete,
  MdEdit,
  MdDone,
  MdCancel,
  MdChevronLeft,
  MdChevronRight,
  MdMyLocation,
} from "react-icons/md";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  Polyline,
  Polygon,
  Circle,
  Rectangle,
  DrawingManager,
  StandaloneSearchBox,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { motion } from "framer-motion";
import Card from "components/card/Card";
//import { MdDelete, MdLocationCity, MdMyLocation } from "react-icons/md";
import fetchWithToken from "views/auth/signIn/axiosInstance";
const containerStyle = {
  width: "100%",
  height: "600px",
};
const center = { lat: 33.88251415887743, lng: 35.499557542115774 };
const MapsAPI = "AIzaSyCEllG77hecIdXvlZpaevITNS36FiPtasA";
const libraries = ["places", "drawing"];
export const ZoneList = () => {
  const [Zone, setZone] = useState([]);
  const [filteredZones, setFilteredZones] = useState([]);
  const [error, setError] = useState("");
  const [editableCity, setEditableCity] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [updateUser, setUpdateUser] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [Coordinates, setCoordinates] = useState("");

  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [selected, setSelected] = useState(null);
  const [drawings, setDrawings] = useState([]);
  const [origin, setOrigin] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [polygonPaths, setPolygonPaths] = useState([]);

  const [directions, setDirections] = useState(null);
  const searchBoxRef = useRef(null);
  const [drawingManagerOptions, setDrawingManagerOptions] = useState(null);
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    console.log("Access Token:", accessToken);

    if (accessToken) {
      const [header, payload, signature] = accessToken.split(".");
      const decodedPayload = JSON.parse(atob(payload));
      const entryUser = decodedPayload.clientId;
      setUpdateUser(entryUser);
    } else {
      console.error("Access token not found.");
    }
  }, []);

  useEffect(() => {
    const fetchZone = async () => {
      try {
        const response = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/zone`
        );
        // if (!response) {
        //   throw new Error("Failed to fetch defaults");
        // }
        const data = response; //await response.json();
        setZone(data);
        setFilteredZones(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchZone();
  }, []);

  const handleDeleteCity = async (zoneId) => {
    try {
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/zone`,

        "DELETE",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        { zoneId: zoneId }
      );

      //   if (!response) {
      //     throw new Error("Failed to delete zone");
      //   }

      setZone(Zone.filter((zoneItem) => zoneItem.zoneId !== zoneId));
      setFilteredZones(
        filteredZones.filter((zoneItem) => zoneItem.zoneId !== zoneId)
      );
    } catch (error) {
      console.error("Error deleting zone:", error);
      setError("Failed to delete zone");
    }
  };

  const handleEditCity = (defaultItem) => {
    setEditableCity(defaultItem);
  };

  const handleSaveCity = async () => {
    try {
      if (!editableCity) {
        return;
      }

      const data = {
        zoneId: editableCity.zoneId,
        nameEng: editableCity.nameEng,
        nameArb: editableCity.nameArb,
        Coordinates: polygonCoordinatesString,
      };

      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/zone`,

        "PUT",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        data
      );

      //   if (!response) {
      //     const errorData = await response.json();
      //     throw new Error(`Failed to update zone: ${JSON.stringify(errorData)}`);
      //   }

      setZone(
        Zone.map((cityItem) => {
          if (cityItem.zoneId === editableCity.zoneId) {
            return editableCity;
          }
          return cityItem;
        })
      );

      setFilteredZones(
        filteredZones.map((cityItem) => {
          if (cityItem.zoneId === editableCity.zoneId) {
            return editableCity;
          }
          return cityItem;
        })
      );

      setEditableCity(null);
      setCoordinates("");
    } catch (error) {
      console.error("Error updating city:", error);
      setError(error.message);
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = Zone.filter((cityItem) =>
      cityItem.nameEng.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredZones(filtered);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };
  const initializeDrawingManager = useCallback(() => {
    if (window.google && window.google.maps && window.google.maps.drawing) {
      setDrawingManagerOptions({
        drawingControl: true,
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.LEFT_CENTER,
          drawingModes: [
            window.google.maps.drawing.OverlayType.POLYGON,
            window.google.maps.drawing.OverlayType.POLYLINE,
            window.google.maps.drawing.OverlayType.RECTANGLE,
            window.google.maps.drawing.OverlayType.MARKER,
          ],
        },
      });
    } else {
      setTimeout(initializeDrawingManager, 1000); // Retry after 1 second
    }
  }, []);

  useEffect(() => {
    initializeDrawingManager();
  }, [initializeDrawingManager]);

  const onMapLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onMapClick = useCallback((e) => {
    setOrigin({ lat: e.latLng.lat(), lng: e.latLng.lng() });
  }, []);

  const onSearchBoxLoad = useCallback((ref) => {
    searchBoxRef.current = ref;
  }, []);

  const onPlacesChanged = useCallback(() => {
    const places = searchBoxRef.current.getPlaces();
    console.log(places); // Check what places look like in the console
    if (places.length) {
      const place = places[0];
      console.log(place); // Check the details of the selected place
      setSelected(place);
      map.fitBounds(place.geometry.viewport);
    }
  }, [map]);

  const onMarkerDragEnd = useCallback((e) => {
    const newPos = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    setOrigin(newPos);
    setMarkerPosition(newPos);
  }, []);

  const onOverlayComplete = (e) => {
    const newDrawing = e.overlay;
    newDrawing.type = e.type;
    if (newDrawing.type === "polygon") {
      // Get array, ensure the polygon is closed by adding the first point to the end if necessary
      let path = newDrawing
        .getPath()
        .getArray()
        .map((p) => ({ lat: p.lat(), lng: p.lng() }));
      if (
        path.length > 0 &&
        (path[0].lat !== path[path.length - 1].lat ||
          path[0].lng !== path[path.length - 1].lng)
      ) {
        path.push(path[0]); // Append the first point at the end to close the polygon
      }
      setPolygonPaths(path);
    }
  };
  const clearDrawings = () => {
    drawings.forEach((drawing) => {
      if (drawing.setMap) {
        drawing.setMap(null);
      }
    });
    setDrawings([]);
  };
  const polygonCoordinatesString =
    polygonPaths.length > 0
      ? polygonPaths
          .map((pos) => `${pos.lat.toFixed(10)} ${pos.lng.toFixed(10)}`)
          .join(",")
      : "";

  const styles = {
    container: {
      position: "absolute",
      top: "10px",
      left: "10px",
      zIndex: "1001",
      backgroundColor: "rgba(255, 255, 255, 0.9)",
      padding: "10px",
      borderRadius: "8px",
      boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
      maxWidth: "300px",
    },
    input: {
      width: "100%",
      padding: "5px",
      marginTop: "5px",
      marginBottom: "5px",
      fontSize: "14px",
      border: "1px solid #ddd",
      borderRadius: "4px",
    },
    label: {
      fontWeight: "bold",
      color: "#555",
    },
  };

  const handleLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          // Update markers to include the new position with details if needed
          setMarkers((current) => [
            ...current,
            { ...pos, info: "You are here!" },
          ]);
          // Pan the map to the new position
          map.panTo(pos);
          // Set the zoom level closer to ground level for better view
          map.setZoom(15); // Adjust zoom level as needed (e.g., 15 is fairly close)
        },
        () => {
          alert("Failed to get your location");
        },
        { enableHighAccuracy: true } // This provides a more accurate position if available
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  // Calculate pagination indexes
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredZones.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <Card px="0px" boxShadow="xl" overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        {error && <Text color="red.500">{error}</Text>}
        <Text
          color="gray.800"
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Zone List
        </Text>
        <Input
          placeholder="Search Defaults..."
          value={searchQuery}
          onChange={handleSearch}
          size="sm"
          w="200px"
        />
      </Flex>

      <Box overflowY="auto" maxHeight="600px">
        <Table color="gray.500" mb="24px">
          <Thead>
            <Tr bg="blue.500" textColor="white">
              <Th textColor="white"> zone</Th>

              <Th textColor="white">English Name</Th>
              <Th textColor="white">Arabic Name</Th>
              <Th textColor="white">Coordinates</Th>
              <Th textColor="white">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {currentItems.map((cityItem, index) => (
              <Tr key={cityItem.zoneId}>
                <Td>{index + 1}</Td>
                <Td>
                  <Flex>
                    {editableCity && editableCity.zoneId === cityItem.zoneId ? (
                      <Input
                        value={editableCity.nameEng}
                        onChange={(e) =>
                          setEditableCity({
                            ...editableCity,
                            nameEng: e.target.value,
                          })
                        }
                      />
                    ) : (
                      cityItem.nameEng
                    )}
                  </Flex>
                </Td>
                <Td>
                  <Flex>
                    {editableCity && editableCity.zoneId === cityItem.zoneId ? (
                      <Input
                        value={editableCity.nameArb}
                        onChange={(e) =>
                          setEditableCity({
                            ...editableCity,
                            nameArb: e.target.value,
                          })
                        }
                      />
                    ) : (
                      cityItem.nameArb
                    )}
                  </Flex>
                </Td>
                <Td>
                  <Flex>
                    {editableCity && editableCity.zoneId === cityItem.zoneId ? (
                      <Input
                        variant="filled"
                        placeholder=" Coordinates"
                        value={polygonCoordinatesString} //{polygonCoordinatesString}
                        onChange={(e) => setCoordinates(e.target.value)}
                        required
                        borderColor={"blue.500"}
                        mb={4}
                      />
                    ) : (
                      <>
                        {" "}
                        {cityItem.Coordinates ? "available" : "not available"}
                      </>
                    )}
                  </Flex>
                </Td>
                <Td>
                  {editableCity && editableCity.zoneId === cityItem.zoneId ? (
                    <Flex>
                      <Button
                        leftIcon={<MdDone />}
                        colorScheme="green"
                        size="sm"
                        onClick={handleSaveCity}
                      >
                        Save
                      </Button>
                      <Button
                        leftIcon={<MdCancel />}
                        colorScheme="red"
                        size="sm"
                        ml={2}
                        onClick={() => setEditableCity(null)}
                        mt={"1px"}
                      >
                        Cancel
                      </Button>
                    </Flex>
                  ) : (
                    <Flex>
                      <Button
                        leftIcon={<MdEdit />}
                        colorScheme="blue"
                        size="sm"
                        onClick={() => handleEditCity(cityItem)}
                      >
                        Edit
                      </Button>
                      <Button
                        leftIcon={<MdDelete />}
                        colorScheme="red"
                        size="sm"
                        ml={2}
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to delete this default?"
                            )
                          ) {
                            handleDeleteCity(cityItem.zoneId);
                          }
                        }}
                      >
                        Delete
                      </Button>
                    </Flex>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <Flex justify="space-between" alignItems="center" px="25px" mt="20px">
        <Text color="gray.800">
          Showing {indexOfFirstItem + 1} -{" "}
          {Math.min(indexOfLastItem, filteredZones.length)} of{" "}
          {filteredZones.length}
        </Text>
        <Flex>
          <Button
            leftIcon={<MdChevronLeft />}
            colorScheme="blue"
            size="sm"
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <Button
            leftIcon={<MdChevronRight />}
            colorScheme="blue"
            size="sm"
            onClick={handleNextPage}
            ml="2"
            disabled={indexOfLastItem >= filteredZones.length}
          >
            Next
          </Button>
        </Flex>
      </Flex>
      {editableCity && (
        <LoadScript googleMapsApiKey={MapsAPI} libraries={libraries}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
            onLoad={onMapLoad}
            onClick={onMapClick}
          >
            {origin && (
              <Marker
                position={origin}
                draggable={true}
                onDragEnd={onMarkerDragEnd}
              />
            )}

            {selected && (
              <InfoWindow
                position={{
                  lat: selected.geometry.location.lat(),
                  lng: selected.geometry.location.lng(),
                }}
                onCloseClick={() => setSelected(null)}
              >
                <div>
                  <h2>{selected.name}</h2>
                  <p>{selected.formatted_address}</p>
                </div>
              </InfoWindow>
            )}

            {markers.map((marker, idx) => (
              <Marker key={idx} position={marker} />
            ))}

            <StandaloneSearchBox
              onLoad={onSearchBoxLoad}
              onPlacesChanged={onPlacesChanged}
            >
              <input
                type="text"
                placeholder="Search places"
                style={{
                  boxSizing: `border-box`,
                  border: `1px solid transparent`,
                  width: `240px`,
                  height: `32px`,
                  padding: `0 12px`,
                  borderRadius: `3px`,
                  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                  fontSize: `14px`,
                  outline: `none`,
                  textOverflow: `ellipses`,
                  position: "absolute",
                  left: "50%",
                  marginLeft: "-120px",
                }}
              />
            </StandaloneSearchBox>
            {drawingManagerOptions && (
              <DrawingManager
                options={drawingManagerOptions}
                onOverlayComplete={onOverlayComplete}
              />
            )}
            <Button
              leftIcon={<MdDelete />}
              onClick={clearDrawings}
              style={{
                position: "absolute",
                top: "180px", // Distance from the top
                left: "8px", // Distance from the left
                zIndex: 1000, // Ensure it's visible over the map
              }}
            ></Button>

            {/* {directions && <DirectionsRenderer directions={directions} />}
        <Button 
  style={{
    position: 'absolute', // Position it over the map
    top: '180px',          // Distance from the top
    left: '8px',           // Distance from the left
    zIndex: 1000           // Ensure it's visible over the map
  }}
  onClick={fetchDirections}>Get Directions</Button> */}

            <Button
              leftIcon={<MdMyLocation />}
              onClick={handleLocationClick}
              style={{
                position: "absolute", // Position it over the map
                top: "230px", // Distance from the top
                left: "8px", // Distance from the left
                zIndex: 1000, // Ensure it's visible over the map
              }}
            ></Button>
          </GoogleMap>

          <div style={styles.container}>
            {markerPosition && (
              <>
                <label>Marker Position:</label>
                <input
                  readOnly
                  value={`Lat: ${markerPosition.lat.toFixed(
                    6
                  )}, Lng: ${markerPosition.lng.toFixed(6)}`}
                />
              </>
            )}
            {polygonPaths.length > 0 && (
              <Input
                variant="filled"
                placeholder="Polygon Coordinates"
                value={polygonCoordinatesString}
                borderColor="blue.500"
                mb={4}
                size="xsm"
                hidden="true" // Optional, for larger input size
              />
            )}
          </div>
        </LoadScript>
      )}
    </Card>
  );
};
