import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Button,
  Input,
  Flex,
  Select,
} from "@chakra-ui/react";
import { MdEdit, MdDelete, MdDone, MdCancel } from "react-icons/md";
import Card from "components/card/Card";
import fetchWithToken from "views/auth/signIn/axiosInstance";
const UsersList = () => {
  const [users, setUsers] = useState([]);
  const [Username, setUsername] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [editableUser, setEditableUser] = useState(null);
  const [roles, setRoles] = useState([]);
  const [updateUser, setupdateUser] = useState(null);
  const handleEditUser = (user) => {
    setUsername(user.username);
    const u = user;
    u.username = null;
    setEditableUser(u);
  };

  // const handleSaveUser = async () => {
  //   try {
  //     let data = {};
  //     if (editableUser.username === null) {
  //       data = {
  //         userpass: editableUser.password,
  //         updateUser: updateUser,
  //         roleId: editableUser.roleId,
  //         userId: editableUser.userId,
  //       };
  //     } else {
  //       data = {
  //         username: editableUser.username,
  //         userpass: editableUser.password,
  //         updateUser: updateUser,
  //         roleId: editableUser.roleId,
  //         userId: editableUser.userId,
  //       };
  //     }
  //     // console.log(data);
  //     const response = await fetchWithToken(
  //       `${process.env.REACT_APP_API_URL}/api/editUser`,
  //       "PUT",
  //       data
  //       // {
  //       //   method: "PUT",
  //       //   headers: {
  //       //     "Content-Type": "application/json",
  //       //   },
  //       //   body: JSON.stringify({ data }),
  //       // }
  //     );
  //     if (!response) {
  //       throw new Error("Failed to update user");
  //     }
  //     setEditableUser(null);
  //     window.location.reload();
  //   } catch (error) {
  //     console.error("Error updating user:", error);
  //   }
  // };
  const handleSaveUser = async () => {
    try {
      const data = {
        userpass: editableUser.password,
        updateUser: updateUser,
        roleId: editableUser.roleId,
        userId: editableUser.userId,
      };

      // Only add `username` if it's not null or undefined
      if (
        editableUser.username !== null &&
        editableUser.username !== undefined
      ) {
        data.username = editableUser.username;
      }

      console.log("Sending data:", data);

      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/editUser`,
        "PUT",
        data
      );

      if (!response || !response.success) {
        throw new Error(response.message || "Failed to update user");
      }

      setEditableUser(null);
      window.location.reload();
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };
  const handleEditFormClose = () => {
    // Redirect to another URL
    window.location.reload();
  };

  const handleDeleteUser = async (userId) => {
    try {
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/users/${userId}`,
        "DELETE"
        // {
        //   method: "DELETE",
        // }
      );

      if (!response) {
        throw new Error("Failed to delete user");
      }

      setUsers((prevUsers) =>
        prevUsers.filter((user) => user.userId !== userId)
      );
      setFilteredUsers((prevFilteredUsers) =>
        prevFilteredUsers.filter((user) => user.userId !== userId)
      );
    } catch (error) {
      console.error("Error deleting user:", error);
      // Handle the error
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchTerm(query);
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      // Split the token into header, payload, and signature
      // eslint-disable-next-line no-unused-vars
      const [header, payload, signature] = accessToken.split(".");
      // Decode the payload (Base64 decoded)
      const decodedPayload = JSON.parse(atob(payload));
      // Extract the username
      const updateUser = decodedPayload.clientId;
      // Set the entryUser in the state
      setupdateUser(updateUser);
    } else {
      console.error("Access token not found.");
    }
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const responseUsers = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/users`
        );
        const responseRoles = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/role`
        );

        if (responseUsers && responseRoles) {
          const usersData = responseUsers; // await responseUsers.json();
          const rolesData = responseRoles; //await responseRoles.json();

          // Update the users array with role names
          const usersWithRoleNames = usersData.map((user) => {
            const role = rolesData.find((role) => role.roleId === user.roleId);
            return { ...user, roleName: role ? role.description : "" };
          });

          setUsers(usersWithRoleNames);
          setRoles(rolesData);
        } else {
          console.error("Error fetching users or roles");
        }
      } catch (error) {
        console.error("Error fetching users or roles:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    // Filter users based on the search term
    const filteredResults = users.filter((user) =>
      user.username.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(filteredResults);
  }, [searchTerm, users]);

  return (
    <Card
      mt={90}
      boxShadow="lg"
      p="6"
      borderRadius="md"
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Box
        px="25px"
        mb="20px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Heading mb={1}>Users List</Heading>
        <Input
          placeholder="Search by username..."
          value={searchTerm}
          onChange={handleSearch}
          size="sm"
          w="200px"
        />
      </Box>
      <Box overflowY="auto" maxHeight="400px">
        {loading ? (
          <Spinner size="xl" />
        ) : (
          <Table variant="simple" color="gray.500" mb="24px">
            <Thead>
              <Tr bg="blue.500" textColor="white">
                <Th textColor="white">Index</Th>
                <Th textColor="white">Username</Th>
                <Th textColor="white">Role</Th>
                <Th textColor="white">Password</Th>
                <Th textColor="white">Entry User</Th>
                <Th textColor="white">Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredUsers.map((user, index) => (
                <Tr key={user.userId}>
                  <Td>{index + 1}</Td>
                  <Td>
                    {editableUser && editableUser.userId === user.userId ? (
                      <Input
                        value={editableUser.username}
                        onChange={(e) =>
                          setEditableUser({
                            ...editableUser,
                            username: e.target.value,
                          })
                        }
                        size="sm"
                      />
                    ) : (
                      user.username
                    )}
                  </Td>
                  <Td>
                    {editableUser && editableUser.userId === user.userId ? (
                      <Select
                        value={editableUser.roleId}
                        onChange={(e) =>
                          setEditableUser({
                            ...editableUser,
                            roleId: e.target.value,
                          })
                        }
                        size="sm"
                      >
                        {roles.map((role) => (
                          <option key={role.roleId} value={role.roleId}>
                            {role.description}
                          </option>
                        ))}
                      </Select>
                    ) : (
                      user.roleName
                    )}
                  </Td>
                  <Td>
                    {editableUser && editableUser.userId === user.userId ? (
                      <Input
                        type="password"
                        value={editableUser.password}
                        onChange={(e) =>
                          setEditableUser({
                            ...editableUser,
                            password: e.target.value,
                          })
                        }
                        size="sm"
                      />
                    ) : (
                      "********"
                    )}
                  </Td>
                  <Td>{user.entryUser}</Td>
                  <Td>
                    {editableUser && editableUser.userId === user.userId ? (
                      <Flex>
                        <Button
                          leftIcon={<MdDone />}
                          colorScheme="green"
                          size="sm"
                          onClick={handleSaveUser}
                        >
                          Save
                        </Button>
                        <Button
                          leftIcon={<MdCancel />}
                          colorScheme="red"
                          ml={2}
                          size="sm"
                          onClick={handleEditFormClose}
                        >
                          Cancel
                        </Button>
                      </Flex>
                    ) : (
                      <Flex>
                        <Button
                          leftIcon={<MdEdit />}
                          colorScheme="blue"
                          size="sm"
                          onClick={() => handleEditUser(user)}
                        >
                          Edit
                        </Button>
                        <Button
                          leftIcon={<MdDelete />}
                          colorScheme="red"
                          size="sm"
                          ml={2}
                          onClick={() => {
                            if (
                              window.confirm(
                                "Are you sure you want to delete this user?"
                              )
                            ) {
                              handleDeleteUser(user.userId);
                            }
                          }}
                        >
                          Delete
                        </Button>
                      </Flex>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Box>
    </Card>
  );
};

export default UsersList;
