import React from "react";
import { Container, } from "@chakra-ui/react";

import ListProviderPayments from "./component/ListProviderPayments";

export default function ProviderWorkingHours() {
    return (
        <Container maxW="xxl" mt={100}>
            <ListProviderPayments />
        </Container>
    );
}
