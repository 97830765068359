// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Button,
//   FormControl,
//   FormLabel,
//   Heading,
//   Input,
//   Select,
//   Text,
// } from "@chakra-ui/react";
// import fetchWithToken from "views/auth/signIn/axiosInstance";
// function CreateUser() {
//   const [username, setUsername] = useState("");
//   const [userpass, setPassword] = useState("");
//   const [roleId, setRoleId] = useState("");
//   const [error, setError] = useState(null);
//   const [entryUser, setEntryUser] = useState(null); // State to store the entryUser
//   const [updateUser, setUpdateUser] = useState("");
//   const [roleName, setRoleName] = useState("");

//   const [roles, setRoles] = useState([]);

//   useEffect(() => {
//     const accessToken = localStorage.getItem("accessToken");
//     if (accessToken) {
//       // Split the token into header, payload, and signature
//       const [header, payload, signature] = accessToken.split(".");
//       // Decode the payload (Base64 decoded)
//       const decodedPayload = JSON.parse(atob(payload));
//       // Extract the username
//       const entryUser = decodedPayload.clientId;
//       // Set the entryUser in the state
//       setEntryUser(entryUser);
//       setUpdateUser(entryUser);
//     } else {
//       console.error("Access token not found.");
//     }
//   }, []);

//   //api request for fetching roles
//   useEffect(() => {
//     const fetchRoles = async () => {
//       try {
//         const response = await fetchWithToken(
//           `${process.env.REACT_APP_API_URL}/api/role`
//         );
//         if (response) {
//           const rolesData = response; //await response.json();
//           setRoles(rolesData);
//         } else {
//           console.error("Error fetching roles");
//         }
//       } catch (error) {
//         console.error("Error fetching roles:", error);
//       }
//     };

//     fetchRoles();
//   }, []);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     console.log(entryUser);
//     // Check if entryUser is available
//     if (!entryUser) {
//       console.error("Entry user not available.");
//       return;
//     }

//     //  API request for creating user
//     try {
//       const data = {
//         username,
//         userpass,
//         entryUser: entryUser,
//         updateUser: entryUser,
//         roleId,
//       };
//       const response = await fetchWithToken(
//         `${process.env.REACT_APP_API_URL}/api/createUser`,
//         "POST",
//         data
//         // {
//         //   method: "POST",
//         //   headers: {
//         //     "Content-Type": "application/json",
//         //   },
//         //   body: JSON.stringify({
//         //     username,
//         //     userpass,
//         //     entryUser: entryUser,
//         //     updateUser: "created",
//         //     roleId,
//         //   }),
//         // }
//       );
//       if (response) {
//         console.log("User created successfully");
//         window.location.reload();
//       } else {
//         const errorData = response; //await response.json();
//         setError(errorData.error || "Failed to create user");
//       }
//     } catch (error) {
//       console.error("Error creating user:", error);
//       setError("Failed to create user");
//     }
//   };

//   return (
//     <Box
//       w={"sm"}
//       mt={10}
//       boxShadow="0px 0px 10px rgba(0, 0, 255, 0.3)"
//       p="6"
//       borderRadius="md"
//       bg="white"
//     >
//       <Heading fontSize="36px" mb="10px">
//         Create User
//       </Heading>
//       <FormControl>
//         <FormLabel
//           display="flex"
//           ms="4px"
//           fontSize="sm"
//           fontWeight="500"
//           mb="8px"
//         >
//           Username
//         </FormLabel>
//         <Input
//           isRequired={true}
//           variant="auth"
//           fontSize="sm"
//           ms={{ base: "0px", md: "0px" }}
//           type="text"
//           placeholder="Username"
//           mb="24px"
//           fontWeight="500"
//           size="lg"
//           value={username}
//           onChange={(e) => setUsername(e.target.value)}
//         />
//         <FormLabel ms="4px" fontSize="sm" fontWeight="500" display="flex">
//           Password
//         </FormLabel>
//         <Input
//           isRequired={true}
//           fontSize="sm"
//           placeholder="Password"
//           mb="24px"
//           size="lg"
//           type="password"
//           variant="auth"
//           value={userpass}
//           onChange={(e) => setPassword(e.target.value)}
//         />
//         <FormLabel
//           display="flex"
//           ms="4px"
//           fontSize="sm"
//           fontWeight="500"
//           mb="8px"
//         >
//           Role
//         </FormLabel>
//         <Select
//           variant="auth"
//           fontSize="sm"
//           ms={{ base: "0px", md: "0px" }}
//           placeholder="Select Role"
//           mb="24px"
//           fontWeight="500"
//           size="lg"
//           value={roleName}
//           onChange={(e) => setRoleId(e.target.value)}
//         >
//           {roles.map((role) => (
//             <option key={role.roleId} value={role.roleId}>
//               {role.description}
//             </option>
//           ))}
//         </Select>

//         <Button
//           fontSize="sm"
//           colorScheme="blue"
//           fontWeight="500"
//           w="100%"
//           h="50"
//           mb="24px"
//           onClick={handleSubmit}
//         >
//           Create User
//         </Button>
//         {error && <Text color="red">{error}</Text>}
//       </FormControl>
//     </Box>
//   );
// }

// export default CreateUser;
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";
import fetchWithToken from "views/auth/signIn/axiosInstance";

function CreateUser() {
  const [username, setUsername] = useState("");
  const [userpass, setPassword] = useState("");
  const [roleId, setRoleId] = useState("");
  const [error, setError] = useState(null);
  const [entryUser, setEntryUser] = useState(null);
  const [updateUser, setUpdateUser] = useState("");
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      console.log("Access Token:", accessToken);
      try {
        const [header, payload, signature] = accessToken.split(".");
        // console.log("Token Payload (Base64):", payload);
        const decodedPayload = JSON.parse(atob(payload));
        // console.log("Decoded Payload:", decodedPayload);
        const entryUser = decodedPayload.clientId;
        if (entryUser) {
          setEntryUser(entryUser);
          setUpdateUser(entryUser);
          //  console.log("Entry User set to:", entryUser);
        } else {
          console.error("Username not found in token payload.");
        }
      } catch (error) {
        console.error("Error decoding access token:", error);
      }
    } else {
      console.error("Access token not found.");
    }
  }, []);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/role`
        );
        if (response) {
          const rolesData = response;
          setRoles(rolesData);
        } else {
          console.error("Error fetching roles");
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchRoles();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!entryUser) {
      console.error("Entry user not available.");
      setError("Entry user not available.");
      return;
    }

    try {
      const data = {
        username,
        userpass,
        entryUser,
        updateUser: entryUser,
        roleId,
      };
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/createUser`,
        "POST",
        data
      );
      if (response) {
        console.log("User created successfully");
        window.location.reload();
      } else {
        const errorData = response;
        setError(errorData.error || "Failed to create user");
      }
    } catch (error) {
      console.error("Error creating user:", error);
      setError("Failed to create user");
    }
  };

  return (
    <Box
      w={"sm"}
      mt={10}
      boxShadow="0px 0px 10px rgba(0, 0, 255, 0.3)"
      p="6"
      borderRadius="md"
      bg="white"
    >
      <Heading fontSize="36px" mb="10px">
        Create User
      </Heading>
      <FormControl>
        <FormLabel
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          mb="8px"
        >
          Username
        </FormLabel>
        <Input
          isRequired={true}
          variant="auth"
          fontSize="sm"
          ms={{ base: "0px", md: "0px" }}
          type="text"
          placeholder="Username"
          mb="24px"
          fontWeight="500"
          size="lg"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <FormLabel ms="4px" fontSize="sm" fontWeight="500" display="flex">
          Password
        </FormLabel>
        <Input
          isRequired={true}
          fontSize="sm"
          placeholder="Password"
          mb="24px"
          size="lg"
          type="password"
          variant="auth"
          value={userpass}
          onChange={(e) => setPassword(e.target.value)}
        />
        <FormLabel
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          mb="8px"
        >
          Role
        </FormLabel>
        <Select
          variant="auth"
          fontSize="sm"
          ms={{ base: "0px", md: "0px" }}
          placeholder="Select Role"
          mb="24px"
          fontWeight="500"
          size="lg"
          value={roleId}
          onChange={(e) => setRoleId(e.target.value)}
        >
          {roles.map((role) => (
            <option key={role.roleId} value={role.roleId}>
              {role.description}
            </option>
          ))}
        </Select>

        <Button
          fontSize="sm"
          colorScheme="blue"
          fontWeight="500"
          w="100%"
          h="50"
          mb="24px"
          onClick={handleSubmit}
        >
          Create User
        </Button>
        {error && <Text color="red">{error}</Text>}
      </FormControl>
    </Box>
  );
}

export default CreateUser;
