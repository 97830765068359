import React, { useState } from 'react';
import { Button, Container, Center } from "@chakra-ui/react";

import CreateCategoryForm from "./components/CreateCategory"; // Adjusted component name
import { MdAdd, MdRemove } from 'react-icons/md';
import ListOfCategory from './components/ListCategory';

const Category = () => {
  const [showRolePermissionForm, setShowRolePermissionForm] = useState(false);

  const toggleRolePermissionForm = () => {
    setShowRolePermissionForm(!showRolePermissionForm);
  };

  return (
    <Container maxW="xxl" mt={100}>
      <Center>
        <Button 
          colorScheme={showRolePermissionForm ? "red" : "blue"} 
          leftIcon={showRolePermissionForm ? <MdRemove/> : <MdAdd />}
          onClick={toggleRolePermissionForm}
          marginBottom="20px"
        >
          {showRolePermissionForm ? "Hide Category Form" : "Add  Category"}
        </Button>
      </Center>

      {showRolePermissionForm && (
        <div style={{ marginBottom: '20px' }}>
          <CreateCategoryForm /> 
        </div>
      )}

      <ListOfCategory />
    </Container>
  );
};

export default Category;
