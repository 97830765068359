import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Button,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Input,
  Flex,
  Icon,
  Select,
} from "@chakra-ui/react";
import { MdEdit, MdDelete, MdDone, MdCancel } from "react-icons/md";
import Card from "components/card/Card";
import moment from "moment";
import fetchWithToken from "views/auth/signIn/axiosInstance";
const CodeList = () => {
  const [codes, setCodes] = useState([]);
  const [filteredCodes, setFilteredCodes] = useState([]);
  const [kinds, setKinds] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState("");
  const [editableCode, setEditableCode] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [entryUser, setEntryUser] = useState("");
  const [updateUser, setUpdateUser] = useState("");
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      const [header, payload, signature] = accessToken.split(".");
      const decodedPayload = JSON.parse(atob(payload));
      const user = decodedPayload.clientId;
      setEntryUser(user);
      setUpdateUser(user);
    } else {
      console.error("Access token not found.");
    }
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const codeResponse = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/code`
        );
        const kindResponse = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/kind`
        );

        if (!codeResponse || !kindResponse) {
          throw new Error("Failed to fetch data");
        }

        const codeData = codeResponse; //await .json();
        const kindData = kindResponse; //await .json();

        const codeWithKindDescriptions = codeData.map((code) => {
          const kind = kindData.find(
            (kind) => kind.idCodeKind === code.idCodeKind
          );
          return {
            ...code,
            kindDescription: kind ? kind.Description : "",
          };
        });

        setCodes(codeWithKindDescriptions);
        setFilteredCodes(codeWithKindDescriptions);
        setKinds(
          kindData.reduce(
            (acc, kind) => ({ ...acc, [kind.idCodeKind]: kind }),
            {}
          )
        );
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, []);

  const handleEditCode = (code) => {
    setEditableCode(code);
  };

  const handleSaveCode = async () => {
    try {
      const Data = {
        Description: editableCode.Description,
        idCodeKind: editableCode.idCodeKind,
        idCode: editableCode.idCode,
        UpdateUser: editableCode.UpdateUser,
      };
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/code`,
        "PUT",
        Data
        // {
        //   method:
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify(),
        // }
      );

      if (!response) {
        throw new Error("Failed to update code");
      }

      const updatedCode = response; // await .json();
      setCodes((prevCodes) =>
        prevCodes.map((c) =>
          c.idCode === updatedCode.idCode ? updatedCode : c
        )
      );
      setFilteredCodes((prevFilteredCodes) =>
        prevFilteredCodes.map((c) =>
          c.idCode === updatedCode.idCode ? updatedCode : c
        )
      );
      window.location.reload();
      setEditableCode(null);
    } catch (error) {
      console.error("Error updating code:", error);
      setError("Failed to update code");
    }
  };

  const handleDeleteCode = async (idCode) => {
    try {
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/code`,
        "DELETE",
        idCode
        // {
        //   method:
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify({
        //     ,
        //   }),
        // }
      );

      if (!response) {
        throw new Error("Failed to delete code");
      }

      setCodes((prevCodes) =>
        prevCodes.filter((code) => code.idCode !== idCode)
      );
      setFilteredCodes((prevFilteredCodes) =>
        prevFilteredCodes.filter((code) => code.idCode !== idCode)
      );
    } catch (error) {
      console.error("Error deleting code:", error);
      setError("Failed to delete code");
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = codes.filter(
      (code) =>
        code.Description.toLowerCase().includes(query.toLowerCase()) ||
        code.kindDescription.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredCodes(filtered);
  };

  const handleKindChange = (selectedKindId) => {
    setEditableCode((prevEditableCode) => ({
      ...prevEditableCode,
      idCodeKind: selectedKindId,
    }));
  };

  return (
    <Card
      mt="50px"
      boxShadow="xl"
      p="6"
      borderRadius="md"
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Box
        px="25px"
        mb="20px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Heading mb={1}>Codes List</Heading>
        <Input
          placeholder="Search by description or kind..."
          value={searchQuery}
          onChange={handleSearch}
          size="sm"
          w="200px"
        />
      </Box>
      <Box overflowY="auto" maxHeight="400px">
        <Table variant="simple" color="black" mb="24px">
          <Thead>
            <Tr bg="blue.500" textColor="white">
              <Th></Th>
              <Th textColor="white">Index</Th>
              <Th textColor="white">Description</Th>
              <Th textColor="white">Kind Description</Th>
              <Th textColor="white">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredCodes.map((code, index) => (
              <Tr key={code.idCode}>
                <Td></Td>
                <Td>{index + 1}</Td>
                <Td>
                  {editableCode && editableCode.idCode === code.idCode ? (
                    <Input
                      value={editableCode.Description}
                      onChange={(e) =>
                        setEditableCode({
                          ...editableCode,
                          Description: e.target.value,
                        })
                      }
                      size="sm"
                    />
                  ) : (
                    code.Description
                  )}
                </Td>
                <Td>
                  {editableCode && editableCode.idCode === code.idCode ? (
                    <Select
                      value={editableCode.idCodeKind}
                      onChange={(e) => handleKindChange(e.target.value)}
                      size="sm"
                    >
                      {Object.values(kinds).map((kind) => (
                        <option key={kind.idCodeKind} value={kind.idCodeKind}>
                          {kind.Description}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    code.kindDescription
                  )}
                </Td>
                <Td>
                  {editableCode && editableCode.idCode === code.idCode ? (
                    <Flex>
                      <Button
                        leftIcon={<MdDone />}
                        colorScheme="green"
                        size="sm"
                        onClick={handleSaveCode}
                      >
                        Save
                      </Button>
                      <Button
                        leftIcon={<MdCancel />}
                        colorScheme="red"
                        ml={2}
                        size="sm"
                        onClick={() => setEditableCode(null)}
                      >
                        Cancel
                      </Button>
                    </Flex>
                  ) : (
                    <Flex>
                      <Button
                        colorScheme="blue"
                        width={10}
                        size="sm"
                        onClick={() => handleEditCode(code)}
                      >
                        <Icon as={MdEdit} boxSize={5} />
                      </Button>

                      <Button
                        colorScheme="red"
                        size="sm"
                        width={10}
                        ml={2}
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to delete this code?"
                            )
                          ) {
                            handleDeleteCode(code.idCode);
                          }
                        }}
                      >
                        <Icon as={MdDelete} boxSize={5} />
                      </Button>
                    </Flex>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
};

export default CodeList;
