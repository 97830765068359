import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Box,
  Input,
} from "@chakra-ui/react";
import { MdDelete, MdEdit, MdDone, MdCancel } from "react-icons/md";
import Card from "components/card/Card";
import fetchWithToken from "views/auth/signIn/axiosInstance";
const ListProviderPayments = () => {
  const [payments, setPayments] = useState([]);
  const [editablePayment, setEditablePayment] = useState(null);
  const [error, setError] = useState("");
  const [providers, setProviders] = useState([]);
  const [updateUser, setUpdateUser] = useState("");

  useEffect(() => {
    // Set the update user from a token stored in localStorage
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      const [header, payload, signature] = accessToken.split(".");
      const decodedPayload = JSON.parse(atob(payload));
      const entryUser = decodedPayload.clientId;
      setUpdateUser(entryUser);
    } else {
      console.error("Access token not found.");
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [paymentsResponse, providersResponse] = await Promise.all([
          fetchWithToken(`${process.env.REACT_APP_API_URL}/api/providerPay`),
          fetchWithToken(`${process.env.REACT_APP_API_URL}/api/provider`),
        ]);
        // if (!paymentsResponse.ok || !providersResponse.ok) {
        //   throw new Error("Failed to fetch data");
        // }
        // const [paymentsData, providersData] = await Promise.all([
        //   paymentsResponse.json(),
        //   providersResponse.json(),
        // ]);
        setPayments(paymentsResponse);
        setProviders(providersResponse);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, []);

  const handleEditClick = (paymentId) => {
    const paymentToEdit = payments.find(
      (payment) => payment.paymentID === paymentId
    );
    const { paymentDate, entryUser, entryDate, updateDate, ...editableFields } =
      paymentToEdit; // Destructure to exclude dates
    setEditablePayment({ ...editableFields });
  };

  const handleCancel = () => {
    setEditablePayment(null);
  };

  const handleSave = async () => {
    if (!editablePayment || !editablePayment.paymentID) {
      console.error("Editable payment or paymentID is missing");
      return;
    }
    try {
      const data = {
        paymentID: editablePayment.paymentID,
        provID: editablePayment.providerID,
        Amount: parseFloat(editablePayment.Amount),
        updateUser: updateUser,
      };

      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/providerPay`,
        "PUT",
        data
        // {
        //   method: "PUT",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify({
        //     paymentID: editablePayment.paymentID,
        //     provID: editablePayment.providerID,
        //     Amount: parseFloat(editablePayment.Amount),
        //     updateUser: updateUser,
        //   }),
        // }
      );

      // if (!response) {
      //   throw new Error("Failed to update payment");
      // }

      const updatedPayment = response; // await response.json();
      setPayments(
        payments.map((payment) =>
          payment.paymentID === editablePayment.paymentID
            ? { ...payment, ...updatedPayment }
            : payment
        )
      );
      setEditablePayment(null);
      window.location.reload();
    } catch (error) {
      console.error("Error updating payment:", error);
      setError(error.message);
    }
  };

  const handleDelete = async (paymentId) => {
    if (!window.confirm("Are you sure you want to delete this payment?")) {
      return;
    }
    try {
      console.log(paymentId);
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/providerPay`,
        "DELETE",
        { paymentID: paymentId }
        // {
        //   method: "DELETE",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify({ paymentID: paymentId }),
        // }
      );

      // if (!response) {
      //   throw new Error("Failed to delete payment");
      // }

      setPayments(
        payments.filter((payment) => payment.paymentID !== paymentId)
      );
    } catch (error) {
      console.error("Error deleting payment:", error);
      setError("Failed to delete payment");
    }
  };

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
      boxShadow="lg"
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text color="black" fontSize="22px" fontWeight="700" lineHeight="100%">
          Provider Payment List
        </Text>
        {error && <Text color="red.500">{error}</Text>}
      </Flex>
      <Box overflowY="auto" maxHeight="400px">
        <Table variant="simple" color="gray.500">
          <Thead>
            <Tr bg="blue.500" textColor="white">
              <Th textColor={"white"}>Index</Th>
              <Th textColor="white">Provider</Th>
              <Th textColor="white">Amount</Th>
              <Th textColor="white">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {payments.map((payment, index) => (
              <Tr key={payment.paymentID}>
                <Td>{index + 1}</Td>
                <Td>
                  {providers.find((p) => p.provID === payment.provID)
                    ?.providerUsername || "Unknown"}
                </Td>
                <Td>
                  {editablePayment &&
                  editablePayment.paymentID === payment.paymentID ? (
                    <Input
                      type="number"
                      value={editablePayment.Amount || ""}
                      onChange={(e) =>
                        setEditablePayment({
                          ...editablePayment,
                          Amount: e.target.value,
                        })
                      }
                    />
                  ) : (
                    payment.Amount
                  )}
                </Td>
                <Td>
                  {editablePayment &&
                  editablePayment.paymentID === payment.paymentID ? (
                    <Flex>
                      <Button
                        leftIcon={<MdDone />}
                        colorScheme="green"
                        size="sm"
                        onClick={handleSave}
                      >
                        Save
                      </Button>
                      <Button
                        leftIcon={<MdCancel />}
                        colorScheme="red"
                        size="sm"
                        ml={2}
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                    </Flex>
                  ) : (
                    <Flex>
                      <Button
                        leftIcon={<MdEdit />}
                        colorScheme="blue"
                        size="sm"
                        onClick={() => handleEditClick(payment.paymentID)}
                      >
                        Edit
                      </Button>
                      <Button
                        leftIcon={<MdDelete />}
                        colorScheme="red"
                        size="sm"
                        onClick={() => handleDelete(payment.paymentID)}
                        ml={2}
                      >
                        Delete
                      </Button>
                    </Flex>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
};

export default ListProviderPayments;
