import React, { useState, useEffect } from "react";
import { Box, Button, Input, Heading } from "@chakra-ui/react";
import { motion } from "framer-motion";
import fetchWithToken from "views/auth/signIn/axiosInstance";

export const InsertRole = () => {
  // State variables to store form data and status
  const [description, setDescription] = useState("");
  const [entryUser, setEntryUser] = useState(null);
  const [updateUser, setUpdateUser] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      // Split the token into header, payload, and signature
      // eslint-disable-next-line no-unused-vars
      const [header, payload, signature] = accessToken.split(".");
      // Decode the payload (Base64 decoded)
      const decodedPayload = JSON.parse(atob(payload));
      // Extract the username
      const entryUser = decodedPayload.clientId;
      // Set the entryUser in the state
      setEntryUser(entryUser);
    } else {
      console.error("Access token not found.");
    }
  }, []);

  // Function to handle changes in input fields
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = {
        description,
        entryUser,
        updateUser: entryUser,
      };
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/Role`,
        "POST",
        data
        // {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify({
        //     description,
        //     entryUser,
        //     updateUser: entryUser,
        //   }),
        // }
      );

      const responseBody = response; //await response.text();
      // console.log(responseBody);

      // Reset form fields after successful submission
      setDescription("");
      setEntryUser("");
      setUpdateUser("");
      setError("");
      setSuccess(true);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      // Handle error if POST request fails
      setError(error.message || "An error occurred. Please try again later.");
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 0.5 }}
    >
      <Box boxShadow="lg">
        <h2 style={{ color: "#333", textAlign: "center" }}>Add New Role</h2>
        {error && <p style={{ color: "red", textAlign: "center" }}>{error}</p>}
        {success && (
          <p style={{ color: "green", textAlign: "center" }}>
            Role added successfully!
          </p>
        )}
        <form onSubmit={handleSubmit}>
          <Heading fontSize="24px" ml={"20px"} mb="10px">
            Create User
          </Heading>
          <Input
            variant="filled"
            placeholder="Role Description"
            value={description}
            onChange={handleDescriptionChange}
            required
          />
          <Button colorScheme="blue" type="submit" w="100%">
            Submit
          </Button>
        </form>
      </Box>
    </motion.div>
  );
};
export default InsertRole;
