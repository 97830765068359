import React, { useState } from 'react';
import { Button, Container, Center } from "@chakra-ui/react";

import { MdAdd, MdRemove } from 'react-icons/md';
import AddImageSliderForm from './components/CreateImageSlider';
import ListOfImageSlider from './components/ListImageSlider';

const Category = () => {
const [showImageSliderForm, setImageSliderForm] = useState(false);

  const toggleDefaultsForm = () => {
    setImageSliderForm(!showImageSliderForm);
  };

  return (
    <Container maxW="xxl" mt={100}>
      <Center>
        <Button 
          colorScheme={showImageSliderForm ? "red" : "blue"} 
          leftIcon={showImageSliderForm ? <MdRemove/> : <MdAdd />}
          onClick={toggleDefaultsForm}
          marginBottom="20px"
        >
          {showImageSliderForm ? "Hide ImageSlider Form" : "Add  ImageSlider"}
        </Button>
      </Center>

      {showImageSliderForm && (
        <div style={{ marginBottom: '20px' }}>
          <AddImageSliderForm /> 
        </div>
      )}

      <ListOfImageSlider/>
    </Container>
  );
};

export default Category;
