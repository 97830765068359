import React, { useState } from "react";
import { Button, Container, Center } from "@chakra-ui/react";
import { MdAdd, MdRemove } from "react-icons/md";
import { InsertPermission } from "./insertPermission";
import { PermissionList } from "./ListPermission";

export default function Permission() {
    const [showInsertPermission, setShowInsertPermission] = useState(false);

    const toggleInsertPermission = () => {
        setShowInsertPermission(!showInsertPermission);
    };

    return (
        <Container maxW="xxl" mt={100}>
            <Center>
                <Button 
                    colorScheme={showInsertPermission ? "red" : "blue"} // Change color scheme dynamically
                    leftIcon={showInsertPermission ? <MdRemove /> : <MdAdd />} // Change icon dynamically
                    onClick={toggleInsertPermission}
                    marginBottom={"20px"}
                >
                    {showInsertPermission ? "Hide Add Permission" : "Add Permission"}
                </Button>
            </Center>
            <div style={{ marginBottom: showInsertPermission ? '20px' : '0' } }>
                {showInsertPermission && <InsertPermission />}
            </div>
            <PermissionList />
        </Container>
    );
}
