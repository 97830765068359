import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Box,
  Input,
  Select,
  VStack,
  HStack,
  List,
  ListItem,
} from "@chakra-ui/react";
import { MdDelete, MdEdit, MdDone, MdCancel } from "react-icons/md";
import Card from "components/card/Card";
import fetchWithToken from "views/auth/signIn/axiosInstance"; // Adjust the path
const Listproviderzone = () => {
  const [providerzone, setproviderzone] = useState([]);
  const [filteredproviderzone, setFilteredproviderzone] = useState([]);
  const [error, setError] = useState("");
  const [editableproviderzone, setEditableproviderzone] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [updateUser, setupdateUser] = useState(null);
  const [providers, setProviders] = useState([]);
  const [Zones, setZones] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoadingProviders, setIsLoadingProviders] = useState(false);
  const [fetchError, setFetchError] = useState("");
  const [selectedProvider, setSelectedProvider] = useState(null);
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      const [header, payload, signature] = accessToken.split(".");
      const decodedPayload = JSON.parse(atob(payload));
      const updateUser = decodedPayload.clientId;
      setupdateUser(updateUser);
    } else {
      console.error("Access token not found.");
    }
  }, []);

  useEffect(() => {
    const fetchListproviderzone = async () => {
      try {
        const data = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/Providerzone`
        );
        // if (!response) {
        //   throw new Error("Failed to fetch providerzone");
        // }
        //const data = await response.json();
        const updatedRolePermissions = data.map(async (provZone) => {
          //const serviceNameEng = await fetchZoneName(provService.serviceId);
          const ProvName = await fetchProvName(provZone.provID);
          const ZoneName = await fetchZoneName(provZone.zoneId);

          return { ...provZone, ProvName, ZoneName };
        });
        const resolvedRolePermissions = await Promise.all(
          updatedRolePermissions
        );
        setproviderzone(resolvedRolePermissions);
        setFilteredproviderzone(resolvedRolePermissions);
        // setproviderzone(data);
        // setFilteredproviderzone(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchListproviderzone();
  }, []);

  const fetchProvName = async (provID) => {
    try {
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/provider/${provID}`
      );
      // if (!response) {
      //   throw new Error("Failed to fetch provider");
      // }
      // const data = await response.json();
      if (Array.isArray(response) && response.length > 0) {
        return response[0].providerUsername;
      } else {
        throw new Error("provider  not found");
      }
    } catch (error) {
      setError(error.message);
      return "";
    }
  };

  const fetchZoneName = async (zoneId) => {
    try {
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/zone/${zoneId}`
      );
      // if (!response) {
      //   throw new Error("Failed to fetch zone");
      // }
      // const data = await response.json();
      if (Array.isArray(response) && response.length > 0) {
        return response[0].nameEng;
      } else {
        throw new Error("zone  not found");
      }
    } catch (error) {
      setError(error.message);
      return "";
    }
  };

  useEffect(() => {
    const fetchZone = async () => {
      try {
        const response = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/zone`
        );
        // if (!response) {
        //   throw new Error("Failed to fetch service");
        // }
        // const data = await response.json();
        setZones(response);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchZone();
  }, []);

  useEffect(() => {
    const fetchProviders = async () => {
      setIsLoadingProviders(true);
      setFetchError("");
      try {
        const response = await fetchWithToken(
          `${process.env.REACT_APP_API_URL}/api/provider`
        );
        // const data = await response.json();
        setProviders(response);
        // if (response.ok) {
        //   setProviders(data);
        // } else {
        //   throw new Error("Failed to retrieve providers");
        // }
      } catch (error) {
        console.error("Error fetching providers:", error);
        setFetchError(error.message || "Failed to load providers");
      } finally {
        setIsLoadingProviders(false);
      }
    };

    fetchProviders();
  }, []);

  const handleDeleteproviderzone = async (providerzoneId) => {
    try {
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/providerzone`,

        "DELETE",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        { providerzoneId }
      );
      // if (!response) {
      //   throw new Error("Failed to delete providerzone");
      // }
      setproviderzone(
        providerzone.filter(
          (providerzone) => providerzone.providerzoneId !== providerzoneId
        )
      );
      setFilteredproviderzone(
        filteredproviderzone.filter(
          (providerzone) => providerzone.providerzoneId !== providerzoneId
        )
      );
    } catch (error) {
      console.error("Error deleting providerzone:", error);
      setError("Failed to delete providerzone");
    }
  };

  const handleEditproviderzone = (providerzone) => {
    setEditableproviderzone(providerzone);
  };
  const handleProviderClick = (providerId) => {
    setSelectedProvider(providerId);
  };

  const filteredProviders = providers.filter((provider) =>
    provider.providerUsername.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const displayedZoneHours = providerzone.filter(
    (providerzone) => providerzone.provID === selectedProvider
  );
  const handleSaveproviderzone = async () => {
    try {
      const data = {
        providerzoneId: editableproviderzone.providerzoneId,
        zoneId: editableproviderzone.zoneId,
        provId: editableproviderzone.provID,
        UpdateUser: updateUser,
      };
      const response = await fetchWithToken(
        `${process.env.REACT_APP_API_URL}/api/providerzone`,

        "PUT",
        data
        // headers: {
        //   "Content-Type": "application/json",
        // },
      );
      // console.log(editableproviderzone);
      // if (!response) {
      //   throw new Error("Failed to update providerzone");
      // }
      setproviderzone(
        providerzone.map((providerzone) => {
          if (
            providerzone.providerzoneId === editableproviderzone.providerzoneId
          ) {
            return editableproviderzone;
          }
          return providerzone;
        })
      );
      setEditableproviderzone(null);
      window.location.reload();
    } catch (error) {
      console.error("Error updating providerzone:", error);
      setError("Failed to update providerzone");
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = providerzone.filter((providerzone) =>
      providerzone.ProvName.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredproviderzone(filtered);
  };

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
      boxShadow="lg"
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text color="black" fontSize="22px" fontWeight="700" lineHeight="100%">
          Provider Zone List
        </Text>
        {error && <Text color="red.500">{error}</Text>}

        {/* <Input
          placeholder="Search providerzone..."
          value={searchQuery}
          onChange={handleSearch}
          size="sm"
          w="200px"
        /> */}
      </Flex>
      <Box>
        <Input
          placeholder="Search provider"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          mb="20px"
        />
        <HStack align="start">
          <VStack align="start" spacing="10px" w="30%">
            <List spacing={3} w="100%">
              {filteredProviders.map((provider) => (
                <ListItem
                  key={provider.provID}
                  w="100%"
                  bg={
                    selectedProvider === provider.provID
                      ? "blue.200"
                      : "gray.100"
                  }
                  p="10px"
                  borderRadius="md"
                  cursor="pointer"
                  onClick={() => handleProviderClick(provider.provID)}
                >
                  {provider.providerUsername}
                </ListItem>
              ))}
            </List>
          </VStack>
          <Box w="70%">
            {selectedProvider && (
              <Box overflowY="auto" maxHeight="400px">
                <Table variant="simple" color="gray.500" mb="24px">
                  <Thead>
                    <Tr bg="blue.500" textColor="white">
                      <Th textColor="white"></Th>
                      <Th textColor="white">Provider</Th>
                      <Th textColor="white">Zone</Th>
                      <Th textColor="white">Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {displayedZoneHours.map((providerzone, index) => (
                      <Tr key={providerzone.providerzoneId}>
                        <Td>{index + 1}</Td>
                        <Td>
                          {editableproviderzone &&
                          editableproviderzone.providerzoneId ===
                            providerzone.providerzoneId ? (
                            <Select
                              value={editableproviderzone.provID}
                              onChange={(e) => {
                                console.log("Selected Value:", e.target.value);
                                setEditableproviderzone({
                                  ...editableproviderzone,
                                  provID: e.target.value,
                                });
                              }}
                            >
                              {providers.map((provider) => (
                                <option
                                  key={provider.provID}
                                  value={provider.provID}
                                >
                                  {provider.providerUsername}
                                </option>
                              ))}
                            </Select>
                          ) : (
                            providerzone.ProvName
                          )}
                        </Td>
                        <Td>
                          {editableproviderzone &&
                          editableproviderzone.providerzoneId ===
                            providerzone.providerzoneId ? (
                            <Select
                              value={editableproviderzone.zoneId}
                              onChange={(e) => {
                                console.log("Selected Value:", e.target.value);
                                setEditableproviderzone({
                                  ...editableproviderzone,
                                  zoneId: e.target.value,
                                });
                              }}
                            >
                              {Zones.map((zone) => (
                                <option key={zone.zoneId} value={zone.zoneId}>
                                  {zone.nameEng}
                                </option>
                              ))}
                            </Select>
                          ) : (
                            providerzone.ZoneName
                          )}
                        </Td>
                        <Td>
                          {editableproviderzone &&
                          editableproviderzone.providerzoneId ===
                            providerzone.providerzoneId ? (
                            <Flex>
                              <Button
                                leftIcon={<MdDone />}
                                colorScheme="green"
                                size="sm"
                                onClick={handleSaveproviderzone}
                              >
                                Save
                              </Button>
                              <Button
                                leftIcon={<MdCancel />}
                                colorScheme="red"
                                ml={2}
                                size="sm"
                                onClick={() => setEditableproviderzone(null)}
                              >
                                Cancel
                              </Button>
                            </Flex>
                          ) : (
                            <Flex>
                              <Button
                                leftIcon={<MdEdit />}
                                colorScheme="blue"
                                size="sm"
                                onClick={() =>
                                  handleEditproviderzone(providerzone)
                                }
                              >
                                Edit
                              </Button>
                              <Button
                                leftIcon={<MdDelete />}
                                colorScheme="red"
                                size="sm"
                                ml={2}
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you sure you want to delete this providerzone?"
                                    )
                                  ) {
                                    handleDeleteproviderzone(
                                      providerzone.providerzoneId
                                    );
                                  }
                                }}
                              >
                                Delete
                              </Button>
                            </Flex>
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            )}
          </Box>
        </HStack>
      </Box>
    </Card>
  );
};

export default Listproviderzone;
