// Chakra imports
import {
  Box,
  Icon,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

// Assets
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import {
  MdBarChart,
  MdOutlineNaturePeople,
  MdPermContactCalendar,
  MdPerson2,
} from "react-icons/md";
// import CheckTable from "views/admin/default/components/CheckTable";
// import ComplexTable from "views/admin/default/components/ComplexTable";
// import DailyTraffic from "views/admin/default/components/DailyTraffic";
import PieCard from "views/admin/default/components/PieCard";
// import Tasks from "views/admin/default/components/Tasks";
// import TotalSpent from "views/admin/default/components/TotalSpent";
// import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
// import {
//   columnsDataCheck,
//   columnsDataComplex,
// } from "views/admin/default/variables/columnsData";
// import tableDataCheck from "views/admin/default/variables/tableDataCheck.json";
// import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";
import TopProviderPayments from "views/admin/default/components/List";

import fetchWithToken from "views/auth/signIn/axiosInstance";

export default function UserReports() {
  // Chakra Color Mode
  const [providerData, setProviderData] = useState([]);
  const [paymentData, setpaymentData] = useState([]);
  const [clientData, setclientData] = useState([]);
  const [userData, setuserData] = useState([]);





  const brandColor = useColorModeValue("blue.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  useEffect(() => {
    const fetchProviderData = async () => {
      try {
        const response = await fetchWithToken(`${process.env.REACT_APP_API_URL}/api/provider`);
        if (!response) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = response//await .json();
        setProviderData(data);
      } catch (error) {
        console.error("Error fetching provider data:", error);
      }
    };

    fetchProviderData();
  }, []);
  const fetchProvName = async (provID) => {
    try {
      const response = await fetchWithToken(`${process.env.REACT_APP_API_URL}/api/provider/${provID}`);
      if (!response) {
        throw new Error('Failed to fetch provider');
      }
      const data = response//await .json();
      if (Array.isArray(data) && data.length > 0) {
        return data[0].providerUsername;
      } else {
        throw new Error('provider  not found');
      }
    } catch (error) {
      return '';
    }
  };
  useEffect(() => {
    const fetchPayment = async () => {
      try {
        const response = await fetchWithToken(`${process.env.REACT_APP_API_URL}/api/providerPay`);
        if (!response) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data =response// await .json();
        const updatedRolePermissions = data.map(async (providerPay) => {
          
          const ProvName = await fetchProvName(providerPay.provID);
          return { ...providerPay, ProvName };
        });
        const resolvedRolePermissions = await Promise.all(updatedRolePermissions);
        setpaymentData(resolvedRolePermissions);
      } catch (error) {
        console.error("Error fetching provider data:", error);
      }
    };

    fetchPayment();
  }, []);
  useEffect(() => {
    const fetchClient = async () => {
      try {
        const response = await fetchWithToken(`${process.env.REACT_APP_API_URL}/api/client`);
        if (!response) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data =response// await .json();
        setclientData(data);
      } catch (error) {
        console.error("Error fetching provider data:", error);
      }
    };

    fetchClient();
  }, []);
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetchWithToken(`${process.env.REACT_APP_API_URL}/api/users`);
        if (!response) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = response//await .json();
        setuserData(data);
      } catch (error) {
        console.error("Error fetching provider data:", error);
      }
    };

    fetchUser();
  }, []);

  const getTopProviderPayments = (count) => {
    const sortedData = [...paymentData].sort((a, b) => b.Amount - a.Amount);
    return sortedData.slice(0, count).map((payment) => ({
      providerName: payment.ProvName,
      amount: payment.Amount,
      DatePay:payment.paymentDate
    }));
  };

  const topPayments = getTopProviderPayments(5);
    
     
      const total = paymentData.reduce((acc, payment) => acc + payment.Amount, 0);
  const totalAmount=total;
    

    
 
  const totalProviders = providerData.length;
  const totalClient=clientData.length;
  const totalUser=userData.length;


  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Earnings'
          value= {`${totalAmount}$` }
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdPerson2} color={brandColor} />
              }
            />
          }
          name='Total Providers'
          value={`${totalProviders}` }
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdOutlineNaturePeople} color={brandColor} />
              }
            />
          }        
        // growth='+23%' 
        name='Total Clients' 
        value={`${totalClient}` } />
        {/* <MiniStatistics
          endContent={
            <Flex me='-16px' mt='10px'>
              <FormLabel htmlFor='balance'>
                <Avatar src={Usa} />
              </FormLabel>
              <Select
                id='balance'
                variant='mini'
                mt='5px'
                me='0px'
                defaultValue='usd'>
                <option value='usd'>USD</option>
                <option value='eur'>EUR</option>
                <option value='gba'>GBA</option>
              </Select>
            </Flex>
          }
          name='Your balance'
          value='$1,000'
        /> */}
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              
              icon={<Icon w='28px' h='28px' as={MdPermContactCalendar}  color={brandColor} />}
            />
          }
          name='Total Users'
          value={`${totalUser}` }
        />
        {/* <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdFileCopy} color={brandColor} />
              }
            />
          }
          name='Total Projects'
          value='2935'
        /> */}
      </SimpleGrid>

      {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'> */}
        {/* <TotalSpent /> */}
        {/* <WeeklyRevenue /> */}
      {/* </SimpleGrid> */}
      {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'> */}
        {/* <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} /> */}
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb={"10px"}>
        <TopProviderPayments topPayments={topPayments} />
          
          {/* <DailyTraffic /> */}
          <PieCard />
        </SimpleGrid>
      {/* </SimpleGrid> */}
      {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        /> */}
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          {/* <Tasks /> */}
          <MiniCalendar h='100%' W='100%' selectRange={false} />
        </SimpleGrid>
      {/* </SimpleGrid> */}
    </Box>
  );
}
